import { createTheme } from '@mui/material/styles';
import { typographyVariants, typographyVariantMappings } from './typography.theme';

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(90, 55, 188, 1)",
      
    },
    error: {
      main: "rgba(220, 48, 61, 1)"
    }
  },
  typography: {
    ...typographyVariants
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: typographyVariantMappings,
      },
    },
  },
});

export default theme;

