import { ApolloClient } from '@apollo/client';
import {
  InventoryItemsByBarcodeDocument,
  InventoryItemsByBarcodeQuery,
  InventoryItemsByBarcodeQueryVariables,
  VariantFragment
} from '@/api';

export async function getVariantsByBarcode(client: ApolloClient<any>, shopId: string, barcode: string) {
  const { data } = await client.query<InventoryItemsByBarcodeQuery, InventoryItemsByBarcodeQueryVariables>({
    query: InventoryItemsByBarcodeDocument,
    fetchPolicy: 'network-only',
    variables: {
      shopId,
      barcode
    }
  });

  return data?.inventoryItemsByBarcode?.nodes?.map((inventoryItem) => inventoryItem.variant) as VariantFragment[];
}