export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AcceptAffiliateInviteError: ['AffiliateInviteAlreadyAcceptedError', 'ShopNotFoundError', 'ShowNotFoundError'],
    AddLocationInventoryError: [
      'InventoryItemNotFoundError',
      'LocationInventoryAlreadyExistsError',
      'ShopLocationNotFoundError'
    ],
    AddOptionValueError: ['OptionNotFoundError'],
    AddVariantCardsToShowError: ['ShowNotFoundError', 'VariantNotFoundError'],
    AddVariantCardToShowError: ['ShowNotFoundError', 'VariantNotFoundError'],
    AddVariantToCartError: [
      'InvalidQuantityError',
      'ItemAlreadyInCartError',
      'OutOfStockError',
      'ShowNotFoundError',
      'VariantNotFoundError'
    ],
    AddVariantToCartPOSError: [
      'InvalidQuantityError',
      'ItemAlreadyInCartError',
      'OutOfStockError',
      'ShowNotFoundError',
      'VariantNotFoundError'
    ],
    ArchiveProductError: ['ProductAlreadyArchivedError'],
    CancelShowError: ['UnauthorizedError'],
    ConnectFbBusinessAccountError: ['FbApiError', 'FbBusinessAccountAlreadyConnectedError'],
    CreateCheckoutFromCartError: [
      'BillingCustomerAddressNotFoundError',
      'CartNotFoundError',
      'CustomerPaymentMethodNotFoundError',
      'OutOfStockError',
      'ShippingCustomerAddressNotFoundError'
    ],
    CreateCheckoutFromCartPosError: [
      'BillingCustomerAddressNotFoundError',
      'CartNotFoundError',
      'CustomerPaymentMethodNotFoundError',
      'OutOfStockError',
      'ShippingCustomerAddressNotFoundError'
    ],
    CreateCustomerAddressError: ['CustomerNotFoundError', 'InvalidAddressError'],
    CreateCustomerAddressPosError: ['CustomerNotFoundError', 'InvalidAddressError'],
    CreateCustomPackageError: ['InvalidAddressError', 'InvalidSlugError', 'ShopSlugAlreadyExistsError'],
    CreateExternalAccountError: ['PaymentAccountNotFoundError'],
    CreateExternalAccountWithDetailsError: ['PaymentAccountNotFoundError'],
    CreateInventoryItemError: [
      'InvalidMaxReservableQuantityError',
      'ShopLocationNotFoundError',
      'VariantNotFoundError'
    ],
    CreateOptionError: ['ProductNotFoundError'],
    CreateOrderFromCheckoutError: [
      'CheckoutNotFoundError',
      'CustomerEmailRequiredError',
      'LineItemsCountError',
      'NoItemsError',
      'OutOfStockError',
      'PaymentError'
    ],
    CreateOrderFromCheckoutPosError: [
      'CheckoutNotFoundError',
      'CustomerEmailRequiredError',
      'LineItemsCountError',
      'NoItemsError',
      'OutOfStockError',
      'PaymentError'
    ],
    CreatePackageError: ['CannotFulfillQuantityError'],
    CreatePaymentAccountError: ['PaymentAccountAlreadyExistsError', 'ShopNotFoundError'],
    CreatePaymentAccountOnboardingLinkError: ['PaymentAccountNotFoundError'],
    CreateProductError: [
      'CategoryNotFoundError',
      'InvalidMaxReservableQuantityError',
      'InvalidSlugError',
      'ProductSlugAlreadyExistsError',
      'ShopLocationNotFoundError',
      'ShopNotFoundError'
    ],
    CreateShopCustomizationError: ['InvalidAddressError'],
    CreateShopError: ['InvalidAddressError', 'InvalidSlugError', 'ShopSlugAlreadyExistsError'],
    CreateShowError: ['InvalidSlugError', 'ShopNotFoundError', 'ShowSlugAlreadyExistsError'],
    CreateStreamError: ['ShowNotFoundError'],
    CreateVariantError: [
      'DuplicateVariantOptionError',
      'ImageNotFoundError',
      'ShippingProfileNotFoundError',
      'ShopLocationNotFoundError',
      'TaxCategoryNotFoundError'
    ],
    CustomerPaymentMethod: ['CustomerCard'],
    DeclineAffiliateInviteError: ['ShopNotFoundError', 'ShowNotFoundError'],
    DeleteCheckoutError: ['CheckoutNotFoundError'],
    DeleteInventoryItemError: ['InventoryItemNotFoundError'],
    DeletePackageError: ['CannotFulfillQuantityError'],
    DeleteProductError: ['ProductNotArchivedError'],
    DisableFbCommentsError: ['FbCommentsSessionNotFoundError'],
    DisconnectFbBusinessAccountError: ['FbBusinessAccountNotFoundError'],
    EnableFbCommentsError: [
      'FbApiError',
      'FbBusinessAccountNotFoundError',
      'FbCommentsSessionAlreadyStartedError',
      'FbLiveNotFoundError',
      'FbPageNotFoundError',
      'ShowNotFoundError'
    ],
    Error: [
      'AffiliateAlreadyInvitedError',
      'AffiliateInviteAlreadyAcceptedError',
      'BillingCustomerAddressNotFoundError',
      'CannotFulfillQuantityError',
      'CannotUnfulfillQuantityError',
      'CartNotFoundError',
      'CategoryNotFoundError',
      'CheckoutNotFoundError',
      'CheckoutShipmentNotFoundError',
      'CheckoutShippingRateNotFoundError',
      'CustomerEmailRequiredError',
      'CustomerNotFoundError',
      'CustomerPaymentMethodNotFoundError',
      'DuplicateVariantOptionError',
      'FbApiError',
      'FbBusinessAccountAlreadyConnectedError',
      'FbBusinessAccountNotFoundError',
      'FbCommentsSessionAlreadyStartedError',
      'FbCommentsSessionNotFoundError',
      'FbLiveNotFoundError',
      'FbPageNotFoundError',
      'FbPageUserNotFoundError',
      'ImageNotFoundError',
      'InvalidAddressError',
      'InvalidMaxReservableQuantityError',
      'InvalidQuantityError',
      'InvalidReservationExpirationError',
      'InvalidReservationQuantityError',
      'InvalidSlugError',
      'InventoryItemNotFoundError',
      'ItemAlreadyInCartError',
      'LineItemsCountError',
      'LocationInventoryAlreadyExistsError',
      'LocationInventoryNotFoundError',
      'NoItemsError',
      'OptionNotFoundError',
      'OutOfStockError',
      'PaymentAccountAlreadyExistsError',
      'PaymentAccountNotFoundError',
      'PaymentError',
      'ProductAlreadyArchivedError',
      'ProductAlreadyPublishedError',
      'ProductArchivedError',
      'ProductNotArchivedError',
      'ProductNotFoundError',
      'ProductNotPublishedError',
      'ProductSlugAlreadyExistsError',
      'ReservationNotFoundError',
      'ShippingCustomerAddressNotFoundError',
      'ShippingProfileNotFoundError',
      'ShopLocationNotFoundError',
      'ShopNotFoundError',
      'ShopSlugAlreadyExistsError',
      'ShowNotFoundError',
      'ShowSlugAlreadyExistsError',
      'TaxCategoryNotFoundError',
      'UnableToUpdateQuantityError',
      'UnauthorizedError',
      'UserNotFoundError',
      'VariantNotFoundError'
    ],
    ExternalAccount: ['BankAccountExternalAccount', 'CardExternalAccount'],
    FulfillOrderShipmentLineItemError: ['CannotFulfillQuantityError'],
    HidePackageTemplateError: ['InvalidAddressError', 'InvalidSlugError', 'ShopSlugAlreadyExistsError'],
    InviteShowAffiliateError: ['AffiliateAlreadyInvitedError', 'ShopNotFoundError', 'ShowNotFoundError'],
    LinkFbPageUserError: ['FbApiError', 'FbPageUserNotFoundError', 'UserNotFoundError'],
    MarkShopOrderAsPickedError: ['CannotFulfillQuantityError'],
    Node: [
      'BankAccountExternalAccount',
      'CardExternalAccount',
      'CarrierShippingRate',
      'Cart',
      'CartItem',
      'CashPayment',
      'CashShopPayment',
      'Category',
      'Checkout',
      'CheckoutLineItem',
      'CheckoutShipment',
      'CheckoutShipmentLineItem',
      'CheckoutShippingRate',
      'ColorOptionValue',
      'Customer',
      'CustomerAddress',
      'CustomerCard',
      'CustomizationOption',
      'CustomizationOptionValue',
      'FbBusinessAccount',
      'FixedShippingRate',
      'Image',
      'InventoryItem',
      'LocationInventory',
      'Order',
      'OrderFulfilmentLineItem',
      'OrderLineItem',
      'OrderPackage',
      'OrderShipment',
      'OrderShipmentLineItem',
      'PaymentAccount',
      'PickupShippingRate',
      'Product',
      'ProductCollection',
      'ProductImage',
      'Reservation',
      'SelectOption',
      'ShippingCarrierAccount',
      'ShippingCarrierPackage',
      'ShippingCarrierService',
      'ShippingMethod',
      'ShippingProfile',
      'ShippingProfileGroup',
      'ShippingUserPackage',
      'ShippingZone',
      'Shop',
      'ShopLocation',
      'ShopOrder',
      'ShoppingCategory',
      'ShoppingSubcategory',
      'Show',
      'StripePayment',
      'StripeRefund',
      'StripeShopPayment',
      'StripeShopRefund',
      'StripeShopTransaction',
      'TaxCategory',
      'TextOptionValue',
      'User',
      'Variant',
      'VariantImage',
      'VariantShowCard'
    ],
    Option: ['CustomizationOption', 'SelectOption'],
    OptionValue: ['ColorOptionValue', 'CustomizationOptionValue', 'TextOptionValue'],
    PackItemsError: ['CannotFulfillQuantityError'],
    Payment: ['CashPayment', 'StripePayment'],
    PrintPackingSlipError: ['CannotFulfillQuantityError'],
    PublishProductError: ['ProductAlreadyPublishedError', 'ProductArchivedError'],
    PurchaseShippingLabelError: ['CannotFulfillQuantityError'],
    ReconnectFbBusinessAccountError: ['FbApiError', 'FbBusinessAccountNotFoundError'],
    Refund: ['StripeRefund'],
    ReleaseInventoryError: ['ReservationNotFoundError'],
    RemoveLocationInventoryError: ['LocationInventoryNotFoundError'],
    RemoveOptionValueError: ['OptionNotFoundError'],
    ReserveInventoryError: [
      'InvalidReservationExpirationError',
      'InvalidReservationQuantityError',
      'InventoryItemNotFoundError',
      'OutOfStockError'
    ],
    SelectCheckoutShipmentShippingRateError: [
      'CheckoutNotFoundError',
      'CheckoutShipmentNotFoundError',
      'CheckoutShippingRateNotFoundError'
    ],
    SendConfirmationEmailToCustomerPOSError: ['CustomerEmailRequiredError'],
    SetupShopError: ['InvalidAddressError'],
    ShipmentRatesError: ['CannotFulfillQuantityError'],
    ShippingRate: ['CarrierShippingRate', 'FixedShippingRate', 'PickupShippingRate'],
    ShopPayment: ['CashShopPayment', 'StripeShopPayment'],
    ShopRefund: ['StripeShopRefund'],
    ShopTransaction: ['StripeShopTransaction'],
    ShowCard: ['VariantShowCard'],
    UnarchiveProductError: ['ProductNotArchivedError'],
    UnfulfillOrderShipmentLineItemError: ['CannotUnfulfillQuantityError'],
    UnHidePackageTemplateError: ['InvalidAddressError', 'InvalidSlugError', 'ShopSlugAlreadyExistsError'],
    UnpublishProductError: ['ProductNotPublishedError'],
    UpdateCartItemError: ['InvalidQuantityError', 'OutOfStockError'],
    UpdateCartItemPosError: ['InvalidQuantityError', 'OutOfStockError'],
    UpdateCheckoutError: [
      'BillingCustomerAddressNotFoundError',
      'CheckoutNotFoundError',
      'CustomerPaymentMethodNotFoundError',
      'OutOfStockError',
      'ShippingCustomerAddressNotFoundError'
    ],
    UpdateCheckoutPosError: [
      'BillingCustomerAddressNotFoundError',
      'CheckoutNotFoundError',
      'CustomerPaymentMethodNotFoundError',
      'OutOfStockError',
      'ShippingCustomerAddressNotFoundError'
    ],
    UpdateCustomerAddressError: ['CustomerNotFoundError', 'InvalidAddressError'],
    UpdateInventoryItemError: ['InvalidMaxReservableQuantityError', 'InventoryItemNotFoundError'],
    UpdateLocationInventoryError: ['LocationInventoryNotFoundError', 'UnableToUpdateQuantityError'],
    UpdateOrderAddressError: [
      'CheckoutNotFoundError',
      'CustomerEmailRequiredError',
      'LineItemsCountError',
      'NoItemsError',
      'OutOfStockError',
      'PaymentError'
    ],
    UpdateOrderPaymentMethodError: [
      'CheckoutNotFoundError',
      'CustomerEmailRequiredError',
      'LineItemsCountError',
      'NoItemsError',
      'OutOfStockError',
      'PaymentError'
    ],
    UpdatePackageError: ['CannotFulfillQuantityError'],
    UpdatePaymentError: ['CustomerPaymentMethodNotFoundError'],
    UpdateProductError: ['CategoryNotFoundError', 'InvalidSlugError', 'ProductSlugAlreadyExistsError'],
    UpdateReservationError: ['InvalidReservationExpirationError', 'ReservationNotFoundError'],
    UpdateReservationQuantityError: ['InvalidReservationQuantityError', 'OutOfStockError', 'ReservationNotFoundError'],
    UpdateShopError: ['InvalidAddressError', 'InvalidSlugError', 'ShopSlugAlreadyExistsError'],
    UpdateStreamError: ['ShowNotFoundError'],
    UpdateVariantError: ['ImageNotFoundError', 'ShippingProfileNotFoundError', 'TaxCategoryNotFoundError']
  }
};
export default result;
