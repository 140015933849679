import { useMemo } from 'react';
import { ApolloClient, from, HttpLink, NormalizedCacheObject, ServerError, split } from '@apollo/client';
import { getMainDefinition, mergeDeep } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import isEqual from 'lodash/isEqual';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { createInMemoryCache } from './cache';
import { toast } from 'react-toastify';
import { NextApiRequest, NextApiResponse } from 'next';
import { getAccessToken } from '@auth0/nextjs-auth0';

if (process.env.NODE_ENV !== 'production') process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

if (process.env.NODE_ENV !== 'production') process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;
let wsClient: any;
// Store WebSocket client globally
function createIsomorphicLink(headers: any = {}) {
  const isSSR = typeof window === 'undefined';
  const httpLink = new HttpLink({
    uri: isSSR ? `${process.env.API_URL}/graphql` : '/api/graphql',
    credentials: 'same-origin',
    headers: headers
  });

  if (isSSR) return httpLink;
  wsClient = createClient({
    url: process.env.NEXT_PUBLIC_WS_URL!,
    connectionParams: () => ({
      apiKey: process.env.NEXT_PUBLIC_GUEST_API_KEY
    }),
    shouldRetry: () => true,
    retryAttempts: 5,
    on: {
      connected: () => console.debug("WebSocket Connected"),
      closed: (event) => console.warn(`WebSocket Closed: ${event}`, event),
      error: (error) => console.error("WebSocket Error", error),
    },
  });

  const wsLink = new GraphQLWsLink(wsClient);

  if (typeof window !== "undefined") {
    window.addEventListener("beforeunload", () => {
      if (wsClient) {
        console.log("Closing WebSocket Connection");
        wsClient.dispose(); // ✅ Ensures cleanup
      }
    });
  }

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
  );

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      for (const { message, locations, extensions, path } of graphQLErrors) {
        if (path && path.length > 0 && path[0] !== 'fbCommentsSessionByShow') {
          console.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
          if (extensions?.code === 'AUTH_NOT_AUTHORIZED' || extensions?.code === 'AUTH_NOT_AUTHENTICATED') {
            toast.error('You are not authorized to preform this action.');
            window.location.assign(`/api/auth/login?returnTo=${window.location.pathname}`);
          }
        }
      }
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
      // Redirect to the login page on an unauthorized exception
      if ((networkError as ServerError).statusCode === 401) {
        toast.error('Unauthorized.');
        window.location.assign(`/api/auth/login?returnTo=${window.location.pathname}`);
      }
    }
  });

  return from([errorLink, splitLink]);
}

function createApolloClient(headers?: any) {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: createIsomorphicLink(headers),
    cache: createInMemoryCache(),
    connectToDevTools: true
  });
}

export function mergeApolloState(initial: any, additional: any) {
  return mergeDeep(initial, additional, {
    // combine arrays using object equality (like in sets)
    arrayMerge: (destinationArray: any[], sourceArray: any[]) => [
      ...sourceArray,
      ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s)))
    ]
  });
}

export function initializeApollo(initialState: any = null, headers?: any) {
  const _apolloClient = apolloClient ?? createApolloClient(headers);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = mergeApolloState(initialState, existingCache);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  return useMemo(() => initializeApollo(state), [state]);
}

export async function getApolloClient(req: NextApiRequest, res: NextApiResponse) {
  const headers: Record<string, string> = {};
  const { accessToken } = await getAccessToken(req, res);
  headers['Authorization'] = `Bearer ${accessToken}`;
  return initializeApollo(null, headers);
}
