export async function uploadImage(url: string, file: File) {
  const formData = new FormData();

  formData.set('file', file);

  return fetch(url, {
    method: 'POST',
    body: formData
  }).then((res) => res.json());
}

export type UploadImageParams = {
  url: string;
  file: File;
};

export async function uploadImages(images: UploadImageParams[]) {
  await Promise.all(images.map((i) => uploadImage(i.url, i.file)));
}

const uploadBlobImage = async (blob: Blob, uploadUrl: string): Promise<boolean> => {
  try {
    const response = await fetch(uploadUrl, {
      method: 'PUT',
      body: blob,
      headers: {
        'Content-Type': blob.type
      }
    });
    return response.ok;
  } catch (error) {
    console.error('Error uploading image:', error);
    return false;
  }
};
export { uploadBlobImage };
