export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The Amount scalar type represents a currency amount. */
  Amount: string;
  /** The Barcode scalar type represents a valid barcode, it must be between 1-48 US-ASCII characters long. */
  Barcode: string;
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: string;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: string;
  /** The built-in `Decimal` scalar type. */
  Decimal: number;
  /** The EmailAddress scalar type constitutes a valid email address, represented as a UTF-8 character sequence. The scalar follows the specification defined by the HTML Spec https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: string;
  /** The HexColor scalar type represents a valid HexColor color code. */
  HexColor: string;
  /** The IPv4 scalar type represents a valid a IPv4 address as defined in RFC791 */
  IPv4: string;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: number;
  /** The NonNegativeInt scalar type represents a unsigned 32-bit numeric non-fractional value equal to or greater than 0. */
  NonNegativeInt: number;
  /** The PhoneNumber scalar type represents a value that conforms to the standard E.164 format. */
  PhoneNumber: string;
  /** The PostalCode scalar type represents a valid postal code. */
  PostalCode: string;
  /** The Sku scalar type represents a valid Stock-Keeping Unit (SKU). */
  Sku: string;
  URL: string;
  UUID: string;
};

export type AcceptAffiliateInviteError = AffiliateInviteAlreadyAcceptedError | ShopNotFoundError | ShowNotFoundError;

export type AcceptAffiliateInviteInput = {
  showId: Scalars['ID'];
};

export type AcceptAffiliateInvitePayload = {
  __typename?: 'AcceptAffiliateInvitePayload';
  errors?: Maybe<Array<AcceptAffiliateInviteError>>;
  show?: Maybe<Show>;
};

export type AccountDetailsInput = {
  accountHolderName: Scalars['String'];
  accountHolderType: Scalars['String'];
  accountNumber: Scalars['String'];
  country: Scalars['String'];
  currency: Scalars['String'];
  routingNumber: Scalars['String'];
};

export enum AccountLinkType {
  ONBOARDING = 'ONBOARDING',
  UPDATE = 'UPDATE'
}

export type AddCartItemCustomizationInput = {
  cartItemId: Scalars['ID'];
  customizationOptionId: Scalars['ID'];
  index?: Scalars['Int'];
  value: Scalars['String'];
};

export type AddCartItemCustomizationPayload = {
  __typename?: 'AddCartItemCustomizationPayload';
  cartItemCustomization?: Maybe<CartItemCustomization>;
};

export type AddLocationInventoryError =
  | InventoryItemNotFoundError
  | LocationInventoryAlreadyExistsError
  | ShopLocationNotFoundError;

export type AddLocationInventoryInput = {
  incoming?: Scalars['NonNegativeInt'];
  inventoryItemId: Scalars['ID'];
  quantity?: Scalars['NonNegativeInt'];
  shopLocationId: Scalars['ID'];
  stockLocation?: InputMaybe<StockLocationInput>;
};

export type AddLocationInventoryPayload = {
  __typename?: 'AddLocationInventoryPayload';
  errors?: Maybe<Array<AddLocationInventoryError>>;
  locationInventory?: Maybe<LocationInventory>;
};

export type AddOptionValueError = OptionNotFoundError;

export type AddOptionValueInput = {
  addOptionValue: OptionValueInput;
  optionId: Scalars['ID'];
};

export type AddOptionValuePayload = {
  __typename?: 'AddOptionValuePayload';
  errors?: Maybe<Array<AddOptionValueError>>;
  optionValue?: Maybe<OptionValue>;
};

export type AddOrUpdateUserShoppingCategoriesInput = {
  isSeller: Scalars['Boolean'];
  shoppingCategoryIds: Array<Scalars['ID']>;
};

export type AddOrUpdateUserShoppingCategoriesPayload = {
  __typename?: 'AddOrUpdateUserShoppingCategoriesPayload';
  userShoppingCategory?: Maybe<Array<Maybe<UserShoppingCategory>>>;
};

export type AddOrUpdateUserShoppingSubcategoriesInput = {
  shoppingSubcategoryIds: Array<Scalars['ID']>;
};

export type AddOrUpdateUserShoppingSubcategoriesPayload = {
  __typename?: 'AddOrUpdateUserShoppingSubcategoriesPayload';
  userShoppingSubcategory?: Maybe<Array<Maybe<UserShoppingSubcategory>>>;
};

export type AddProductImageInput = {
  imageId: Scalars['ID'];
  order?: InputMaybe<Scalars['Int']>;
};

export type AddProductImagesInput = {
  after?: InputMaybe<Scalars['Int']>;
  images: Array<AddProductImageInput>;
  productId: Scalars['ID'];
};

export type AddProductImagesPayload = {
  __typename?: 'AddProductImagesPayload';
  productImages?: Maybe<Array<ProductImage>>;
};

export type Address = {
  __typename?: 'Address';
  cityLocality?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<CountryCode>;
  formatted?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  line3?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['PhoneNumber']>;
  postalCode?: Maybe<Scalars['PostalCode']>;
  residential?: Maybe<Scalars['Boolean']>;
  stateProvince?: Maybe<StateProvinceCode>;
};

export enum AddressField {
  CITY_LOCALITY = 'CITY_LOCALITY',
  COMPANY_NAME = 'COMPANY_NAME',
  COUNTRY_CODE = 'COUNTRY_CODE',
  LINE1 = 'LINE1',
  LINE2 = 'LINE2',
  LINE3 = 'LINE3',
  NAME = 'NAME',
  PHONE = 'PHONE',
  POSTAL_CODE = 'POSTAL_CODE',
  STATE_PROVINCE = 'STATE_PROVINCE'
}

export type AddressFilterInput = {
  and?: InputMaybe<Array<AddressFilterInput>>;
  cityLocality?: InputMaybe<StringOperationFilterInput>;
  companyName?: InputMaybe<StringOperationFilterInput>;
  countryCode?: InputMaybe<NullableOfCountryCodeOperationFilterInput>;
  line1?: InputMaybe<StringOperationFilterInput>;
  line2?: InputMaybe<StringOperationFilterInput>;
  line3?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AddressFilterInput>>;
  phone?: InputMaybe<StringOperationFilterInput>;
  postalCode?: InputMaybe<StringOperationFilterInput>;
  residential?: InputMaybe<BooleanOperationFilterInput>;
  stateProvince?: InputMaybe<NullableOfStateProvinceCodeOperationFilterInput>;
};

export type AddressInput = {
  cityLocality?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  countryCode: CountryCode;
  email?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  line3?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['PhoneNumber']>;
  postalCode?: InputMaybe<Scalars['PostalCode']>;
  stateProvince?: InputMaybe<StateProvinceCode>;
};

export type AddressSortInput = {
  cityLocality?: InputMaybe<OrderDirection>;
  companyName?: InputMaybe<OrderDirection>;
  countryCode?: InputMaybe<OrderDirection>;
  line1?: InputMaybe<OrderDirection>;
  line2?: InputMaybe<OrderDirection>;
  line3?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
  phone?: InputMaybe<OrderDirection>;
  postalCode?: InputMaybe<OrderDirection>;
  residential?: InputMaybe<OrderDirection>;
  stateProvince?: InputMaybe<OrderDirection>;
};

export type AddressValidationError = {
  __typename?: 'AddressValidationError';
  code: AddressValidationErrorCode;
  field?: Maybe<AddressField>;
  message: Scalars['String'];
};

export enum AddressValidationErrorCode {
  ADDRESS_NOT_FOUND = 'ADDRESS_NOT_FOUND',
  ADMINISTRATIVE_AREA_CHANGE = 'ADMINISTRATIVE_AREA_CHANGE',
  ADMINISTRATIVE_AREA_FULL = 'ADMINISTRATIVE_AREA_FULL',
  ADMINISTRATIVE_AREA_PARTIAL = 'ADMINISTRATIVE_AREA_PARTIAL',
  AMBIGUOUS_ADDRESS = 'AMBIGUOUS_ADDRESS',
  BUILDING_PARTIAL = 'BUILDING_PARTIAL',
  CITY_FULL = 'CITY_FULL',
  CITY_LOCALITY_MAX_LENGTH_EXCEEDED = 'CITY_LOCALITY_MAX_LENGTH_EXCEEDED',
  CITY_PARTIAL = 'CITY_PARTIAL',
  COMPANY_NAME_MAX_LENGTH_EXCEEDED = 'COMPANY_NAME_MAX_LENGTH_EXCEEDED',
  COMPONENT_MISMATCH_ERROR = 'COMPONENT_MISMATCH_ERROR',
  DEPENDENT_LOCALITY_CHANGE = 'DEPENDENT_LOCALITY_CHANGE',
  DOUBLE_DEPENDENT_LOCALITY_CHANGE = 'DOUBLE_DEPENDENT_LOCALITY_CHANGE',
  EMPTY_REQUEST = 'EMPTY_REQUEST',
  EXTRANEOUS_INFORMATION = 'EXTRANEOUS_INFORMATION',
  GEOCODED_COMMUNITY = 'GEOCODED_COMMUNITY',
  GEOCODED_INTERPOLATED_ROOFTOP = 'GEOCODED_INTERPOLATED_ROOFTOP',
  GEOCODED_NEIGHBORHOOD = 'GEOCODED_NEIGHBORHOOD',
  GEOCODED_ROOFTOP = 'GEOCODED_ROOFTOP',
  GEOCODED_STATE = 'GEOCODED_STATE',
  GEOCODED_STREET = 'GEOCODED_STREET',
  HOUSE_NUMBER_CHANGE = 'HOUSE_NUMBER_CHANGE',
  INVALID_BOX_NUMBER = 'INVALID_BOX_NUMBER',
  INVALID_CITY = 'INVALID_CITY',
  INVALID_CITY_STATE_OR_POSTAL_CODE = 'INVALID_CITY_STATE_OR_POSTAL_CODE',
  INVALID_DUAL_ADDRESS = 'INVALID_DUAL_ADDRESS',
  INVALID_HOUSE_NUMBER = 'INVALID_HOUSE_NUMBER',
  INVALID_POSTAL_CODE = 'INVALID_POSTAL_CODE',
  INVALID_STATE = 'INVALID_STATE',
  LINE1_MIN_MAX_LENGTH = 'LINE1_MIN_MAX_LENGTH',
  LINE2_MAX_LENGTH_EXCEEDED = 'LINE2_MAX_LENGTH_EXCEEDED',
  LINE3_MAX_LENGTH_EXCEEDED = 'LINE3_MAX_LENGTH_EXCEEDED',
  LOCALITY_CHANGE = 'LOCALITY_CHANGE',
  MISSING_BOX_NUMBER = 'MISSING_BOX_NUMBER',
  MISSING_HOUSE_NUMBER = 'MISSING_HOUSE_NUMBER',
  MULTIPLE_DIRECTIONALS = 'MULTIPLE_DIRECTIONALS',
  MULTIPLE_MATCH = 'MULTIPLE_MATCH',
  MULTIPLE_RESPONSES = 'MULTIPLE_RESPONSES',
  NAME_MAX_LENGTH_EXCEEDED = 'NAME_MAX_LENGTH_EXCEEDED',
  NON_DELIVERABLE_ZIP4 = 'NON_DELIVERABLE_ZIP4',
  ORGANIZATION_CHANGE = 'ORGANIZATION_CHANGE',
  PHONE_MAX_LENGTH_EXCEEDED = 'PHONE_MAX_LENGTH_EXCEEDED',
  PO_BOX_CHANGE = 'PO_BOX_CHANGE',
  POSTAL_CODE_CHANGE = 'POSTAL_CODE_CHANGE',
  POSTAL_CODE_NOT_FOUND = 'POSTAL_CODE_NOT_FOUND',
  PREMISE_NUMBER_INVALID = 'PREMISE_NUMBER_INVALID',
  PREMISE_NUMBER_MISSING = 'PREMISE_NUMBER_MISSING',
  PREMISE_TYPE_CHANGE = 'PREMISE_TYPE_CHANGE',
  PREMISES_FULL = 'PREMISES_FULL',
  SERVICE_ERROR = 'SERVICE_ERROR',
  STATE_PROVINCE_MAX_LENGTH_EXCEEDED = 'STATE_PROVINCE_MAX_LENGTH_EXCEEDED',
  STREET_DETAIL_MISSING = 'STREET_DETAIL_MISSING',
  STREET_DIRECTIONAL_CHANGE = 'STREET_DIRECTIONAL_CHANGE',
  STREET_NAME_CHANGE = 'STREET_NAME_CHANGE',
  STREET_PARTIAL = 'STREET_PARTIAL',
  STREET_TYPE_CHANGE = 'STREET_TYPE_CHANGE',
  SUB_ADMINISTRATIVE_AREA_CHANGE = 'SUB_ADMINISTRATIVE_AREA_CHANGE',
  SUBNATIONAL_AREA_CHANGE = 'SUBNATIONAL_AREA_CHANGE',
  SUBPREMISE_FULL = 'SUBPREMISE_FULL',
  SUBPREMISE_NUMBER_CHANGE = 'SUBPREMISE_NUMBER_CHANGE',
  SUBPREMISE_NUMBER_INVALID = 'SUBPREMISE_NUMBER_INVALID',
  SUBPREMISE_NUMBER_MISSING = 'SUBPREMISE_NUMBER_MISSING',
  SUBPREMISE_PARTIAL = 'SUBPREMISE_PARTIAL',
  SUBPREMISE_TYPE_CHANGE = 'SUBPREMISE_TYPE_CHANGE',
  SUITE_MISSING = 'SUITE_MISSING',
  SUITE_NOT_FOUND_OR_INVALID = 'SUITE_NOT_FOUND_OR_INVALID',
  SUITE_NOT_VALID = 'SUITE_NOT_VALID',
  UNSUPPORTED_COUNTRY = 'UNSUPPORTED_COUNTRY',
  USPS_DOOR_INACCESSIBLE = 'USPS_DOOR_INACCESSIBLE',
  VERIFICATION_ERROR = 'VERIFICATION_ERROR'
}

export type AddressValidationResult = {
  __typename?: 'AddressValidationResult';
  address: Address;
  errors?: Maybe<Array<AddressValidationError>>;
  matchedAddress?: Maybe<Address>;
  modified: Array<AddressField>;
  status: AddressValidationStatus;
};

export enum AddressValidationStatus {
  ERROR = 'ERROR',
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
  WARNING = 'WARNING'
}

export type AddShopImageInput = {
  imageId: Scalars['ID'];
  isCoverPhoto: Scalars['Boolean'];
  isProfileImage: Scalars['Boolean'];
  order?: InputMaybe<Scalars['Int']>;
};

export type AddShopImagesInput = {
  images: Array<AddShopImageInput>;
  shopId: Scalars['ID'];
};

export type AddShopImagesPayload = {
  __typename?: 'AddShopImagesPayload';
  shopImages?: Maybe<Array<ShopImage>>;
};

export type AddShowImageInput = {
  imageId: Scalars['ID'];
  showId: Scalars['ID'];
};

export type AddShowImagePayload = {
  __typename?: 'AddShowImagePayload';
  showImage?: Maybe<Array<ShowImage>>;
};

export type AddUserImageInput = {
  imageType: UserImageType;
  profileImageId: Scalars['ID'];
};

export type AddUserImagesInput = {
  images: Array<AddUserImageInput>;
};

export type AddUserImagesPayload = {
  __typename?: 'AddUserImagesPayload';
  userImage?: Maybe<Array<UserImage>>;
};

export type AddVariantCardsToShowError = ShowNotFoundError | VariantNotFoundError;

export type AddVariantCardsToShowInput = {
  showId: Scalars['ID'];
  variants: Array<Scalars['ID']>;
};

export type AddVariantCardsToShowPayload = {
  __typename?: 'AddVariantCardsToShowPayload';
  errors?: Maybe<Array<AddVariantCardsToShowError>>;
  variantShowCard?: Maybe<Array<Maybe<VariantShowCard>>>;
};

export type AddVariantCardToShowError = ShowNotFoundError | VariantNotFoundError;

export type AddVariantCardToShowInput = {
  showId: Scalars['ID'];
  variantId: Scalars['ID'];
};

export type AddVariantCardToShowPayload = {
  __typename?: 'AddVariantCardToShowPayload';
  errors?: Maybe<Array<AddVariantCardToShowError>>;
  variantShowCard?: Maybe<VariantShowCard>;
};

export type AddVariantImageInput = {
  imageId: Scalars['ID'];
  order?: InputMaybe<Scalars['Int']>;
};

export type AddVariantImagesInput = {
  after?: InputMaybe<Scalars['Int']>;
  images: Array<AddVariantImageInput>;
  variantId: Scalars['ID'];
};

export type AddVariantImagesPayload = {
  __typename?: 'AddVariantImagesPayload';
  variantImages?: Maybe<Array<VariantImage>>;
};

export type AddVariantOptionValueInput = {
  optionValueId: Scalars['ID'];
  variantId: Scalars['ID'];
};

export type AddVariantOptionValuePayload = {
  __typename?: 'AddVariantOptionValuePayload';
  variant?: Maybe<Variant>;
};

export type AddVariantToCartError =
  | InvalidQuantityError
  | ItemAlreadyInCartError
  | OutOfStockError
  | ShowNotFoundError
  | VariantNotFoundError;

export type AddVariantToCartInput = {
  quantity: Scalars['Int'];
  showId?: InputMaybe<Scalars['ID']>;
  variantId: Scalars['ID'];
};

export type AddVariantToCartPayload = {
  __typename?: 'AddVariantToCartPayload';
  cartItem?: Maybe<CartItem>;
  errors?: Maybe<Array<AddVariantToCartError>>;
};

export type AddVariantToCartPosError =
  | InvalidQuantityError
  | ItemAlreadyInCartError
  | OutOfStockError
  | ShowNotFoundError
  | VariantNotFoundError;

export type AddVariantToCartPosInput = {
  customerId: Scalars['ID'];
  quantity: Scalars['Int'];
  showId?: InputMaybe<Scalars['ID']>;
  variantId: Scalars['ID'];
};

export type AddVariantToCartPosPayload = {
  __typename?: 'AddVariantToCartPOSPayload';
  cartItem?: Maybe<CartItem>;
  errors?: Maybe<Array<AddVariantToCartPosError>>;
};

export type AffiliateAlreadyInvitedError = Error & {
  __typename?: 'AffiliateAlreadyInvitedError';
  message: Scalars['String'];
};

export type AffiliateInviteAlreadyAcceptedError = Error & {
  __typename?: 'AffiliateInviteAlreadyAcceptedError';
  message: Scalars['String'];
};

/** A connection to a list of items. */
export type AllStreamsConnection = {
  __typename?: 'AllStreamsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AllStreamsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Stream>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AllStreamsEdge = {
  __typename?: 'AllStreamsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Stream;
};

export enum ApplyPolicy {
  AFTER_RESOLVER = 'AFTER_RESOLVER',
  BEFORE_RESOLVER = 'BEFORE_RESOLVER'
}

export type ApproveShopConnectionRequestInput = {
  shopSlug: Scalars['String'];
  userId: Scalars['UUID'];
};

export type ApproveShopConnectionRequestPayload = {
  __typename?: 'ApproveShopConnectionRequestPayload';
  shopConnectionRequest?: Maybe<ShopConnectionRequest>;
};

export type ArchiveProductError = ProductAlreadyArchivedError;

export type ArchiveProductInput = {
  productId: Scalars['ID'];
};

export type ArchiveProductPayload = {
  __typename?: 'ArchiveProductPayload';
  errors?: Maybe<Array<ArchiveProductError>>;
  product?: Maybe<Product>;
};

export type ArchiveProductVariantInput = {
  status: Scalars['Boolean'];
  variantId: Scalars['ID'];
};

export type ArchiveProductVariantPayload = {
  __typename?: 'ArchiveProductVariantPayload';
  variant?: Maybe<Variant>;
};

export enum Attributes {
  BESTVALUE = 'BESTVALUE',
  CHEAPEST = 'CHEAPEST',
  FASTEST = 'FASTEST'
}

export type BankAccountExternalAccount = ExternalAccount &
  Node & {
    __typename?: 'BankAccountExternalAccount';
    bankName?: Maybe<Scalars['String']>;
    country: CountryCode;
    createdAt: Scalars['DateTime'];
    currency: CurrencyCode;
    defaultForCurrency: Scalars['Boolean'];
    id: Scalars['ID'];
    last4: Scalars['String'];
    paymentAccount?: Maybe<PaymentAccount>;
    paymentAccountId: Scalars['ID'];
    paymentProvider: PaymentProvider;
    routingNumber?: Maybe<Scalars['String']>;
    status: BankAccountStatus;
  };

export enum BankAccountStatus {
  ERRORED = 'ERRORED',
  NEW = 'NEW',
  VALIDATED = 'VALIDATED',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  VERIFIED = 'VERIFIED'
}

export enum BarcodeType {
  CODE_128 = 'CODE_128',
  EAN_13 = 'EAN_13',
  JATANGO = 'JATANGO',
  UPC_A = 'UPC_A'
}

export type BarcodeValidationResult = {
  __typename?: 'BarcodeValidationResult';
  barcode: Scalars['String'];
  type?: Maybe<BarcodeType>;
  valid: Scalars['Boolean'];
};

export type BillingCustomerAddressNotFoundError = Error & {
  __typename?: 'BillingCustomerAddressNotFoundError';
  message: Scalars['String'];
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

export type BusinessAccountCreationInfoInput = {
  employerIdentificationNumber?: InputMaybe<Scalars['String']>;
  legalBusinessName?: InputMaybe<Scalars['String']>;
};

export type CancelCardReaderActionInput = {
  customerId: Scalars['ID'];
  paymentId: Scalars['ID'];
};

export type CancelCardReaderActionPayload = {
  __typename?: 'CancelCardReaderActionPayload';
  payment?: Maybe<Payment>;
};

export type CancelOrderInput = {
  orderId: Scalars['ID'];
};

export type CancelOrderLineItemsInput = {
  orderId: Scalars['ID'];
  orderLineItemQuantities: Array<OrderLineItemQuantityInput>;
};

export type CancelOrderLineItemsPayload = {
  __typename?: 'CancelOrderLineItemsPayload';
  order?: Maybe<Order>;
};

export type CancelOrderPayload = {
  __typename?: 'CancelOrderPayload';
  order?: Maybe<Order>;
};

export type CancelShowError = UnauthorizedError;

export type CancelShowInput = {
  showId: Scalars['ID'];
};

export type CancelShowPayload = {
  __typename?: 'CancelShowPayload';
  errors?: Maybe<Array<CancelShowError>>;
  show?: Maybe<Show>;
};

export type CannotFulfillQuantityError = Error & {
  __typename?: 'CannotFulfillQuantityError';
  message: Scalars['String'];
};

export type CannotUnfulfillQuantityError = Error & {
  __typename?: 'CannotUnfulfillQuantityError';
  message: Scalars['String'];
};

export type CaptureOrUpdateUserDemographicInput = {
  dateOfBirth: Scalars['DateTime'];
  gender: Scalars['String'];
  zipCode: Scalars['String'];
};

export type CaptureOrUpdateUserDemographicPayload = {
  __typename?: 'CaptureOrUpdateUserDemographicPayload';
  userDemographic?: Maybe<UserDemographic>;
};

export enum CardBrand {
  AMEX = 'AMEX',
  DINERS = 'DINERS',
  DISCOVER = 'DISCOVER',
  JCB = 'JCB',
  MASTERCARD = 'MASTERCARD',
  UNIONPAY = 'UNIONPAY',
  UNKNOWN = 'UNKNOWN',
  VISA = 'VISA'
}

export enum CardDeclineCode {
  APPROVE_WITH_ID = 'APPROVE_WITH_ID',
  AUTHENTICATION_REQUIRED = 'AUTHENTICATION_REQUIRED',
  CALL_ISSUER = 'CALL_ISSUER',
  CARD_NOT_SUPPORTED = 'CARD_NOT_SUPPORTED',
  CARD_VELOCITY_EXCEEDED = 'CARD_VELOCITY_EXCEEDED',
  CURRENCY_NOT_SUPPORTED = 'CURRENCY_NOT_SUPPORTED',
  DO_NOT_HONOR = 'DO_NOT_HONOR',
  DO_NOT_TRY_AGAIN = 'DO_NOT_TRY_AGAIN',
  DUPLICATE_TRANSACTION = 'DUPLICATE_TRANSACTION',
  EXPIRED_CARD = 'EXPIRED_CARD',
  FRAUDULENT = 'FRAUDULENT',
  GENERIC_DECLINE = 'GENERIC_DECLINE',
  INCORRECT_CVC = 'INCORRECT_CVC',
  INCORRECT_NUMBER = 'INCORRECT_NUMBER',
  INCORRECT_PIN = 'INCORRECT_PIN',
  INCORRECT_ZIP = 'INCORRECT_ZIP',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  INVALID_ACCOUNT = 'INVALID_ACCOUNT',
  INVALID_AMOUNT = 'INVALID_AMOUNT',
  INVALID_CVC = 'INVALID_CVC',
  INVALID_EXPIRY_MONTH = 'INVALID_EXPIRY_MONTH',
  INVALID_EXPIRY_YEAR = 'INVALID_EXPIRY_YEAR',
  INVALID_NUMBER = 'INVALID_NUMBER',
  INVALID_PIN = 'INVALID_PIN',
  ISSUER_NOT_AVAILABLE = 'ISSUER_NOT_AVAILABLE',
  LOST_CARD = 'LOST_CARD',
  MERCHANT_BLACKLIST = 'MERCHANT_BLACKLIST',
  NEW_ACCOUNT_INFORMATION_AVAILABLE = 'NEW_ACCOUNT_INFORMATION_AVAILABLE',
  NO_ACTION_TAKEN = 'NO_ACTION_TAKEN',
  NOT_PERMITTED = 'NOT_PERMITTED',
  OFFLINE_PIN_REQUIRED = 'OFFLINE_PIN_REQUIRED',
  ONLINE_OR_OFFLINE_PIN_REQUIRED = 'ONLINE_OR_OFFLINE_PIN_REQUIRED',
  PICKUP_CARD = 'PICKUP_CARD',
  PIN_TRY_EXCEEDED = 'PIN_TRY_EXCEEDED',
  PROCESSING_ERROR = 'PROCESSING_ERROR',
  REENTER_TRANSACTION = 'REENTER_TRANSACTION',
  RESTRICTED_CARD = 'RESTRICTED_CARD',
  REVOCATION_OF_ALL_AUTHORIZATIONS = 'REVOCATION_OF_ALL_AUTHORIZATIONS',
  SECURITY_VIOLATION = 'SECURITY_VIOLATION',
  SERVICE_NOT_ALLOWED = 'SERVICE_NOT_ALLOWED',
  STOLEN_CARD = 'STOLEN_CARD',
  STOP_PAYMENT_ORDER = 'STOP_PAYMENT_ORDER',
  TESTMODE_DECLINE = 'TESTMODE_DECLINE',
  TRANSACTION_NOT_ALLOWED = 'TRANSACTION_NOT_ALLOWED',
  TRY_AGAIN_LATER = 'TRY_AGAIN_LATER',
  WITHDRAWAL_COUNT_LIMIT_EXCEEDED = 'WITHDRAWAL_COUNT_LIMIT_EXCEEDED'
}

export type CardExternalAccount = ExternalAccount &
  Node & {
    __typename?: 'CardExternalAccount';
    brand: CardBrand;
    country: CountryCode;
    createdAt: Scalars['DateTime'];
    currency: CurrencyCode;
    defaultForCurrency: Scalars['Boolean'];
    expMonth: Scalars['Long'];
    expYear: Scalars['Long'];
    id: Scalars['ID'];
    last4: Scalars['String'];
    paymentAccount?: Maybe<PaymentAccount>;
    paymentAccountId: Scalars['ID'];
    paymentProvider: PaymentProvider;
  };

export enum CardFunding {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  PREPAID = 'PREPAID',
  UNKNOWN = 'UNKNOWN'
}

export type CarrierShippingRate = Node &
  ShippingRate & {
    __typename?: 'CarrierShippingRate';
    defaultShippingCarrierPackage?: Maybe<ShippingCarrierPackage>;
    defaultShippingCarrierPackageId: Scalars['ID'];
    defaultShippingCarrierPackageService?: Maybe<ShippingCarrierPackageService>;
    handlingFee: Money;
    handlingFeePercent: Scalars['Decimal'];
    id: Scalars['ID'];
    shippingCarrierService?: Maybe<ShippingCarrierService>;
    shippingCarrierServiceId: Scalars['ID'];
    shop?: Maybe<Shop>;
    shopId: Scalars['ID'];
  };

export type Cart = Node & {
  __typename?: 'Cart';
  cartItems?: Maybe<CartItemsConnection>;
  customer: Customer;
  customerId: Scalars['ID'];
  id: Scalars['ID'];
  shop: Shop;
  shopId: Scalars['ID'];
};

export type CartCartItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CartItemFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<CartItemSortInput>>;
};

export type CartFilterInput = {
  and?: InputMaybe<Array<CartFilterInput>>;
  cartItems?: InputMaybe<ListFilterInputTypeOfCartItemFilterInput>;
  checkoutCarts?: InputMaybe<ListFilterInputTypeOfCheckoutCartFilterInput>;
  checkouts?: InputMaybe<ListFilterInputTypeOfCheckoutFilterInput>;
  customer?: InputMaybe<CustomerFilterInput>;
  customerId?: InputMaybe<ComparableGuidOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<CartFilterInput>>;
  shop?: InputMaybe<ShopFilterInput>;
  shopId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type CartItem = Node & {
  __typename?: 'CartItem';
  cart: Cart;
  cartId: Scalars['ID'];
  customizations?: Maybe<CartItemCustomizationsConnectionConnection>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  quantity: Scalars['Int'];
  reservationId?: Maybe<Scalars['ID']>;
  show?: Maybe<Show>;
  showId?: Maybe<Scalars['ID']>;
  variant: Variant;
  variantId: Scalars['ID'];
};

export type CartItemCustomizationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CartItemCustomization = {
  __typename?: 'CartItemCustomization';
  cartItem: CartItem;
  cartItemId: Scalars['ID'];
  customizationOption: CustomizationOption;
  customizationOptionId: Scalars['ID'];
  index: Scalars['Int'];
  value: Scalars['String'];
};

/** A connection to a list of items. */
export type CartItemCustomizationsConnection = {
  __typename?: 'CartItemCustomizationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CartItemCustomizationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CartItemCustomization>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** A connection to a list of items. */
export type CartItemCustomizationsConnectionConnection = {
  __typename?: 'CartItemCustomizationsConnectionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CartItemCustomizationsConnectionEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CartItemCustomization>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CartItemCustomizationsConnectionEdge = {
  __typename?: 'CartItemCustomizationsConnectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CartItemCustomization;
};

/** An edge in a connection. */
export type CartItemCustomizationsEdge = {
  __typename?: 'CartItemCustomizationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CartItemCustomization;
};

export type CartItemFilterInput = {
  and?: InputMaybe<Array<CartItemFilterInput>>;
  or?: InputMaybe<Array<CartItemFilterInput>>;
  quantity?: InputMaybe<ComparableInt32OperationFilterInput>;
};

/** A connection to a list of items. */
export type CartItemsByCartConnection = {
  __typename?: 'CartItemsByCartConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CartItemsByCartEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CartItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CartItemsByCartEdge = {
  __typename?: 'CartItemsByCartEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CartItem;
};

/** A connection to a list of items. */
export type CartItemsConnection = {
  __typename?: 'CartItemsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CartItemsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CartItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CartItemsEdge = {
  __typename?: 'CartItemsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CartItem;
};

export type CartItemSortInput = {
  quantity?: InputMaybe<OrderDirection>;
};

export type CartNotFoundError = Error & {
  __typename?: 'CartNotFoundError';
  message: Scalars['String'];
};

/** A connection to a list of items. */
export type CartsByCustomerConnection = {
  __typename?: 'CartsByCustomerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CartsByCustomerEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Cart>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CartsByCustomerEdge = {
  __typename?: 'CartsByCustomerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Cart;
};

/** A connection to a list of items. */
export type CartsConnection = {
  __typename?: 'CartsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CartsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Cart>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CartsEdge = {
  __typename?: 'CartsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Cart;
};

export type CashPayment = Node &
  Payment & {
    __typename?: 'CashPayment';
    amount: Money;
    amountPaid: Money;
    applicationFee: Money;
    cardDeclineCode?: Maybe<CardDeclineCode>;
    changeDue: Money;
    createdAt: Scalars['DateTime'];
    currency: CurrencyCode;
    customer?: Maybe<Customer>;
    customerId?: Maybe<Scalars['ID']>;
    customerPaymentMethod?: Maybe<CustomerPaymentMethod>;
    customerPaymentMethodId?: Maybe<Scalars['ID']>;
    errorCode?: Maybe<PaymentErrorCode>;
    id: Scalars['ID'];
    orders?: Maybe<OrdersConnection>;
    paymentProviderFee?: Maybe<Money>;
    refunds?: Maybe<RefundsConnection>;
    salesTaxAmount: Money;
    shippingAmount: Money;
    shopPayments?: Maybe<ShopPaymentsConnection>;
    status: PaymentStatus;
  };

export type CashPaymentOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CashPaymentRefundsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CashPaymentShopPaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CashShopPayment = Node &
  ShopPayment & {
    __typename?: 'CashShopPayment';
    affiliateShop?: Maybe<Shop>;
    affiliateShopId?: Maybe<Scalars['ID']>;
    amount: Money;
    amountPaid: Money;
    applicationFeeMoney: Money;
    changeDue: Money;
    commissionPercent?: Maybe<Scalars['Decimal']>;
    createdAt: Scalars['DateTime'];
    currency: CurrencyCode;
    errorCode?: Maybe<PaymentErrorCode>;
    id: Scalars['ID'];
    netAffiliateAmount?: Maybe<Scalars['Int']>;
    netAmountMoney?: Maybe<Money>;
    payment?: Maybe<Payment>;
    paymentCaptureDate?: Maybe<Scalars['DateTime']>;
    paymentId?: Maybe<Scalars['ID']>;
    paymentProviderFee: Money;
    paymentProviderFeeMoney?: Maybe<Money>;
    shippingAmountMoney: Money;
    shop: Shop;
    shopId: Scalars['ID'];
    shopOrders?: Maybe<ShopOrdersConnection>;
    shopRefunds?: Maybe<ShopRefundsConnection>;
    shopTransactions: Array<ShopTransaction>;
    show?: Maybe<Show>;
    showId?: Maybe<Scalars['UUID']>;
    status: ShopPaymentStatus;
    type: ShopPaymentType;
  };

export type CashShopPaymentShopOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CashShopPaymentShopRefundsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type CategoriesConnection = {
  __typename?: 'CategoriesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CategoriesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Category>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CategoriesEdge = {
  __typename?: 'CategoriesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Category;
};

export type Category = Node & {
  __typename?: 'Category';
  children: Array<Category>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isArchived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  path: Array<Category>;
  products?: Maybe<ProductsConnection>;
  productsByShop?: Maybe<ProductsByShopConnection>;
  shopCategories: Array<ShopCategory>;
  shops: Array<Shop>;
  slug: Scalars['String'];
  taxCategories?: Maybe<TaxCategoriesConnection>;
};

export type CategoryProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  directDescendantsOnly?: Scalars['Boolean'];
  filter?: InputMaybe<ProductFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ProductSortInput>>;
};

export type CategoryProductsByShopArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  directDescendantsOnly?: Scalars['Boolean'];
  filter?: InputMaybe<ProductFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ProductSortInput>>;
  shopId: Scalars['ID'];
};

export type CategoryTaxCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TaxCategoryFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<TaxCategorySortInput>>;
};

export type CategoryFilterInput = {
  and?: InputMaybe<Array<CategoryFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CategoryFilterInput>>;
  slug?: InputMaybe<StringOperationFilterInput>;
};

export type CategoryGroupedResponse = {
  __typename?: 'CategoryGroupedResponse';
  categories: Array<Category>;
  name: Scalars['String'];
};

export type CategoryNotFoundError = Error & {
  __typename?: 'CategoryNotFoundError';
  message: Scalars['String'];
};

export type CategorySortInput = {
  description?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
  slug?: InputMaybe<OrderDirection>;
};

/** A connection to a list of items. */
export type CategoryTreeConnection = {
  __typename?: 'CategoryTreeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CategoryTreeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Category>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CategoryTreeEdge = {
  __typename?: 'CategoryTreeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Category;
};

export type Checkout = Node & {
  __typename?: 'Checkout';
  billingAddress: Address;
  billingCustomerAddress?: Maybe<CustomerAddress>;
  billingCustomerAddressId?: Maybe<Scalars['ID']>;
  carts?: Maybe<CartsConnection>;
  checkoutLineItems?: Maybe<CheckoutLineItemsConnection>;
  checkoutShipments?: Maybe<CheckoutShipmentsConnection>;
  createdAt: Scalars['DateTime'];
  currency: CurrencyCode;
  customer: Customer;
  customerId: Scalars['ID'];
  customerPaymentMethod?: Maybe<CustomerPaymentMethod>;
  customerPaymentMethodId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  salesTaxTotal: Money;
  shippingAddress: Address;
  shippingCustomerAddress?: Maybe<CustomerAddress>;
  shippingCustomerAddressId?: Maybe<Scalars['ID']>;
  shippingTotal: Money;
  subtotal: Money;
  total: Money;
  updatedAt: Scalars['DateTime'];
};

export type CheckoutCartsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CheckoutCheckoutLineItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CheckoutCheckoutShipmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CheckoutCartFilterInput = {
  and?: InputMaybe<Array<CheckoutCartFilterInput>>;
  cart?: InputMaybe<CartFilterInput>;
  cartId?: InputMaybe<ComparableGuidOperationFilterInput>;
  checkout?: InputMaybe<CheckoutFilterInput>;
  checkoutId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<CheckoutCartFilterInput>>;
};

export type CheckoutFilterInput = {
  and?: InputMaybe<Array<CheckoutFilterInput>>;
  billingAddress?: InputMaybe<AddressFilterInput>;
  billingCustomerAddress?: InputMaybe<CustomerAddressFilterInput>;
  billingCustomerAddressId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  carts?: InputMaybe<ListFilterInputTypeOfCartFilterInput>;
  checkoutCarts?: InputMaybe<ListFilterInputTypeOfCheckoutCartFilterInput>;
  checkoutLineItems?: InputMaybe<ListFilterInputTypeOfCheckoutLineItemFilterInput>;
  checkoutShipments?: InputMaybe<ListFilterInputTypeOfCheckoutShipmentFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  currency?: InputMaybe<CurrencyCodeOperationFilterInput>;
  customer?: InputMaybe<CustomerFilterInput>;
  customerId?: InputMaybe<ComparableGuidOperationFilterInput>;
  customerPaymentMethod?: InputMaybe<CustomerPaymentMethodFilterInput>;
  customerPaymentMethodId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  lineItemsCount?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<CheckoutFilterInput>>;
  salesTaxTotal?: InputMaybe<ComparableInt32OperationFilterInput>;
  salesTaxTotalMoney?: InputMaybe<MoneyFilterInput>;
  shippingAddress?: InputMaybe<AddressFilterInput>;
  shippingCustomerAddress?: InputMaybe<CustomerAddressFilterInput>;
  shippingCustomerAddressId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  shippingTotal?: InputMaybe<ComparableInt32OperationFilterInput>;
  shippingTotalMoney?: InputMaybe<MoneyFilterInput>;
  subtotal?: InputMaybe<ComparableInt32OperationFilterInput>;
  subtotalMoney?: InputMaybe<MoneyFilterInput>;
  total?: InputMaybe<ComparableInt32OperationFilterInput>;
  totalMoney?: InputMaybe<MoneyFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

export type CheckoutLineItem = Node & {
  __typename?: 'CheckoutLineItem';
  cartItem?: Maybe<CartItem>;
  cartItemId?: Maybe<Scalars['ID']>;
  checkout: Checkout;
  checkoutId: Scalars['ID'];
  checkoutShipmentLineItems: Array<CheckoutShipmentLineItem>;
  currency: CurrencyCode;
  customizations?: Maybe<CheckoutLineItemCustomizationsConnectionConnection>;
  id: Scalars['ID'];
  totalQuantity: Scalars['Int'];
  unitPrice: Money;
  variant: Variant;
  variantId: Scalars['ID'];
};

export type CheckoutLineItemCustomizationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CheckoutLineItemCustomization = {
  __typename?: 'CheckoutLineItemCustomization';
  checkoutLineItem: CheckoutLineItem;
  checkoutLineItemId: Scalars['ID'];
  customizationOption: CustomizationOption;
  customizationOptionId: Scalars['ID'];
  index: Scalars['Int'];
  value: Scalars['String'];
};

export type CheckoutLineItemCustomizationFilterInput = {
  and?: InputMaybe<Array<CheckoutLineItemCustomizationFilterInput>>;
  checkoutLineItem?: InputMaybe<CheckoutLineItemFilterInput>;
  checkoutLineItemId?: InputMaybe<ComparableGuidOperationFilterInput>;
  customizationOption?: InputMaybe<CustomizationOptionFilterInput>;
  customizationOptionId?: InputMaybe<ComparableGuidOperationFilterInput>;
  index?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<CheckoutLineItemCustomizationFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

/** A connection to a list of items. */
export type CheckoutLineItemCustomizationsConnection = {
  __typename?: 'CheckoutLineItemCustomizationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CheckoutLineItemCustomizationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CheckoutLineItemCustomization>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** A connection to a list of items. */
export type CheckoutLineItemCustomizationsConnectionConnection = {
  __typename?: 'CheckoutLineItemCustomizationsConnectionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CheckoutLineItemCustomizationsConnectionEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CheckoutLineItemCustomization>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CheckoutLineItemCustomizationsConnectionEdge = {
  __typename?: 'CheckoutLineItemCustomizationsConnectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CheckoutLineItemCustomization;
};

/** An edge in a connection. */
export type CheckoutLineItemCustomizationsEdge = {
  __typename?: 'CheckoutLineItemCustomizationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CheckoutLineItemCustomization;
};

export type CheckoutLineItemFilterInput = {
  and?: InputMaybe<Array<CheckoutLineItemFilterInput>>;
  cartItem?: InputMaybe<CartItemFilterInput>;
  cartItemId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  checkout?: InputMaybe<CheckoutFilterInput>;
  checkoutId?: InputMaybe<ComparableGuidOperationFilterInput>;
  checkoutShipmentLineItems?: InputMaybe<ListFilterInputTypeOfCheckoutShipmentLineItemFilterInput>;
  currency?: InputMaybe<CurrencyCodeOperationFilterInput>;
  customizations?: InputMaybe<ListFilterInputTypeOfCheckoutLineItemCustomizationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<CheckoutLineItemFilterInput>>;
  totalQuantity?: InputMaybe<ComparableInt32OperationFilterInput>;
  unitPrice?: InputMaybe<ComparableInt32OperationFilterInput>;
  unitPriceMoney?: InputMaybe<MoneyFilterInput>;
  variant?: InputMaybe<VariantFilterInput>;
  variantId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

/** A connection to a list of items. */
export type CheckoutLineItemsConnection = {
  __typename?: 'CheckoutLineItemsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CheckoutLineItemsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CheckoutLineItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CheckoutLineItemsEdge = {
  __typename?: 'CheckoutLineItemsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CheckoutLineItem;
};

export type CheckoutNotFoundError = Error & {
  __typename?: 'CheckoutNotFoundError';
  message: Scalars['String'];
};

/** A connection to a list of items. */
export type CheckoutsByCartConnection = {
  __typename?: 'CheckoutsByCartConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CheckoutsByCartEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Checkout>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CheckoutsByCartEdge = {
  __typename?: 'CheckoutsByCartEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Checkout;
};

/** A connection to a list of items. */
export type CheckoutsConnection = {
  __typename?: 'CheckoutsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CheckoutsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Checkout>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CheckoutsEdge = {
  __typename?: 'CheckoutsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Checkout;
};

export type CheckoutShipment = Node & {
  __typename?: 'CheckoutShipment';
  checkout: Checkout;
  checkoutId: Scalars['ID'];
  checkoutShipmentLineItems?: Maybe<CheckoutShipmentLineItemsConnection>;
  checkoutShippingRates?: Maybe<CheckoutShippingRatesConnection>;
  currency: CurrencyCode;
  id: Scalars['ID'];
  selectedCheckoutShippingRate?: Maybe<CheckoutShippingRate>;
  selectedCheckoutShippingRateId?: Maybe<Scalars['ID']>;
  shippingTax: Money;
  shippingTotal: Money;
  shop: Shop;
  shopId: Scalars['ID'];
  sourceShopLocation: ShopLocation;
  sourceShopLocationId: Scalars['ID'];
  subtotal: Money;
  taxCollectable: Money;
  total: Money;
};

export type CheckoutShipmentCheckoutShipmentLineItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CheckoutShipmentCheckoutShippingRatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CheckoutShipmentFilterInput = {
  and?: InputMaybe<Array<CheckoutShipmentFilterInput>>;
  checkout?: InputMaybe<CheckoutFilterInput>;
  checkoutId?: InputMaybe<ComparableGuidOperationFilterInput>;
  checkoutShipmentLineItems?: InputMaybe<ListFilterInputTypeOfCheckoutShipmentLineItemFilterInput>;
  checkoutShippingRates?: InputMaybe<ListFilterInputTypeOfCheckoutShippingRateFilterInput>;
  currency?: InputMaybe<CurrencyCodeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<CheckoutShipmentFilterInput>>;
  shippingTax?: InputMaybe<ComparableInt32OperationFilterInput>;
  shippingTaxMoney?: InputMaybe<MoneyFilterInput>;
  shippingTotal?: InputMaybe<ComparableInt32OperationFilterInput>;
  shippingTotalMoney?: InputMaybe<MoneyFilterInput>;
  shop?: InputMaybe<ShopFilterInput>;
  shopId?: InputMaybe<ComparableGuidOperationFilterInput>;
  sourceShopLocation?: InputMaybe<ShopLocationFilterInput>;
  sourceShopLocationId?: InputMaybe<ComparableGuidOperationFilterInput>;
  subtotal?: InputMaybe<ComparableInt32OperationFilterInput>;
  subtotalMoney?: InputMaybe<MoneyFilterInput>;
  taxCollectable?: InputMaybe<ComparableInt32OperationFilterInput>;
  taxCollectableMoney?: InputMaybe<MoneyFilterInput>;
  total?: InputMaybe<ComparableInt32OperationFilterInput>;
  totalMoney?: InputMaybe<MoneyFilterInput>;
};

export type CheckoutShipmentLineItem = Node & {
  __typename?: 'CheckoutShipmentLineItem';
  checkoutLineItem: CheckoutLineItem;
  checkoutLineItemId: Scalars['ID'];
  checkoutShipment: CheckoutShipment;
  checkoutShipmentId: Scalars['ID'];
  currency: CurrencyCode;
  id: Scalars['ID'];
  quantity: Scalars['Int'];
  taxableAmount: Money;
  taxCollectable: Money;
};

export type CheckoutShipmentLineItemFilterInput = {
  and?: InputMaybe<Array<CheckoutShipmentLineItemFilterInput>>;
  checkoutLineItem?: InputMaybe<CheckoutLineItemFilterInput>;
  checkoutLineItemId?: InputMaybe<ComparableGuidOperationFilterInput>;
  checkoutShipment?: InputMaybe<CheckoutShipmentFilterInput>;
  checkoutShipmentId?: InputMaybe<ComparableGuidOperationFilterInput>;
  currency?: InputMaybe<CurrencyCodeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<CheckoutShipmentLineItemFilterInput>>;
  quantity?: InputMaybe<ComparableInt32OperationFilterInput>;
  taxableAmount?: InputMaybe<ComparableInt32OperationFilterInput>;
  taxableAmountMoney?: InputMaybe<MoneyFilterInput>;
  taxCollectable?: InputMaybe<ComparableInt32OperationFilterInput>;
  taxCollectableMoney?: InputMaybe<MoneyFilterInput>;
};

/** A connection to a list of items. */
export type CheckoutShipmentLineItemsConnection = {
  __typename?: 'CheckoutShipmentLineItemsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CheckoutShipmentLineItemsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CheckoutShipmentLineItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CheckoutShipmentLineItemsEdge = {
  __typename?: 'CheckoutShipmentLineItemsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CheckoutShipmentLineItem;
};

export type CheckoutShipmentNotFoundError = Error & {
  __typename?: 'CheckoutShipmentNotFoundError';
  message: Scalars['String'];
};

/** A connection to a list of items. */
export type CheckoutShipmentsConnection = {
  __typename?: 'CheckoutShipmentsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CheckoutShipmentsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CheckoutShipment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CheckoutShipmentsEdge = {
  __typename?: 'CheckoutShipmentsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CheckoutShipment;
};

export type CheckoutShippingRate = Node & {
  __typename?: 'CheckoutShippingRate';
  checkoutShipment: CheckoutShipment;
  checkoutShipmentId: Scalars['ID'];
  deliveryDays?: Maybe<Scalars['Int']>;
  estimatedDeliveryDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  price: Money;
  selected: Scalars['Boolean'];
  shippingMethod: ShippingMethod;
  shippingMethodId: Scalars['ID'];
};

export type CheckoutShippingRateFilterInput = {
  and?: InputMaybe<Array<CheckoutShippingRateFilterInput>>;
  checkoutShipment?: InputMaybe<CheckoutShipmentFilterInput>;
  checkoutShipmentId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deliveryDays?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  estimatedDeliveryDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<CheckoutShippingRateFilterInput>>;
  price?: InputMaybe<MoneyFilterInput>;
  selected?: InputMaybe<BooleanOperationFilterInput>;
  shippingCarrierPackage?: InputMaybe<ShippingCarrierPackageFilterInput>;
  shippingCarrierPackageId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  shippingCarrierService?: InputMaybe<ShippingCarrierServiceFilterInput>;
  shippingCarrierServiceId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  shippingMethod?: InputMaybe<ShippingMethodFilterInput>;
  shippingMethodId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type CheckoutShippingRateNotFoundError = Error & {
  __typename?: 'CheckoutShippingRateNotFoundError';
  message: Scalars['String'];
};

/** A connection to a list of items. */
export type CheckoutShippingRatesConnection = {
  __typename?: 'CheckoutShippingRatesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CheckoutShippingRatesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CheckoutShippingRate>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CheckoutShippingRatesEdge = {
  __typename?: 'CheckoutShippingRatesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CheckoutShippingRate;
};

export type CollectCashPaymentForOrderInput = {
  cashCollected: Scalars['Int'];
  customerId: Scalars['ID'];
  orderId: Scalars['ID'];
};

export type CollectCashPaymentForOrderPayload = {
  __typename?: 'CollectCashPaymentForOrderPayload';
  order?: Maybe<Order>;
};

export type ColorOptionValue = Node &
  OptionValue & {
    __typename?: 'ColorOptionValue';
    color: Scalars['HexColor'];
    id: Scalars['ID'];
    label: Scalars['String'];
    option: Option;
    optionId: Scalars['ID'];
    order: Scalars['Int'];
    variants?: Maybe<VariantsConnection>;
  };

export type ColorOptionValueVariantsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ColorOptionValueInput = {
  color: Scalars['HexColor'];
  id?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type CommentsConnection = {
  __typename?: 'CommentsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CommentsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<FbComment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CommentsEdge = {
  __typename?: 'CommentsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FbComment;
};

export type ComparableDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<Scalars['DateTime']>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableDoubleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<Scalars['Float']>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type ComparableGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<Scalars['UUID']>>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  neq?: InputMaybe<Scalars['UUID']>;
  ngt?: InputMaybe<Scalars['UUID']>;
  ngte?: InputMaybe<Scalars['UUID']>;
  nin?: InputMaybe<Array<Scalars['UUID']>>;
  nlt?: InputMaybe<Scalars['UUID']>;
  nlte?: InputMaybe<Scalars['UUID']>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<Scalars['Int']>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableNullableOfDecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  neq?: InputMaybe<Scalars['Decimal']>;
  ngt?: InputMaybe<Scalars['Decimal']>;
  ngte?: InputMaybe<Scalars['Decimal']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  nlt?: InputMaybe<Scalars['Decimal']>;
  nlte?: InputMaybe<Scalars['Decimal']>;
};

export type ComparableNullableOfGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  neq?: InputMaybe<Scalars['UUID']>;
  ngt?: InputMaybe<Scalars['UUID']>;
  ngte?: InputMaybe<Scalars['UUID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  nlt?: InputMaybe<Scalars['UUID']>;
  nlte?: InputMaybe<Scalars['UUID']>;
};

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type ConnectFbBusinessAccountError = FbApiError | FbBusinessAccountAlreadyConnectedError;

export type ConnectFbBusinessAccountInput = {
  shopId: Scalars['ID'];
  token: Scalars['String'];
};

export type ConnectFbBusinessAccountPayload = {
  __typename?: 'ConnectFbBusinessAccountPayload';
  errors?: Maybe<Array<ConnectFbBusinessAccountError>>;
  fbBusinessAccount?: Maybe<FbBusinessAccount>;
};

export type ConnectToHostInput = {
  hostEmail: Scalars['String'];
  shopSlug: Scalars['String'];
};

export type ConnectToHostPayload = {
  __typename?: 'ConnectToHostPayload';
  shopConnectionRequest?: Maybe<ShopConnectionRequest>;
};

export type ConnectToShopInput = {
  shopSlug: Scalars['String'];
};

export type ConnectToShopPayload = {
  __typename?: 'ConnectToShopPayload';
  shopConnectionRequest?: Maybe<ShopConnectionRequest>;
};

export type CoordinateFilterInput = {
  and?: InputMaybe<Array<CoordinateFilterInput>>;
  coordinateValue?: InputMaybe<CoordinateFilterInput>;
  m?: InputMaybe<ComparableDoubleOperationFilterInput>;
  or?: InputMaybe<Array<CoordinateFilterInput>>;
  x?: InputMaybe<ComparableDoubleOperationFilterInput>;
  y?: InputMaybe<ComparableDoubleOperationFilterInput>;
  z?: InputMaybe<ComparableDoubleOperationFilterInput>;
};

export type CoordinateSequenceFactoryFilterInput = {
  and?: InputMaybe<Array<CoordinateSequenceFactoryFilterInput>>;
  or?: InputMaybe<Array<CoordinateSequenceFactoryFilterInput>>;
  ordinates?: InputMaybe<OrdinatesOperationFilterInput>;
};

export type CoordinateSequenceFilterInput = {
  and?: InputMaybe<Array<CoordinateSequenceFilterInput>>;
  count?: InputMaybe<ComparableInt32OperationFilterInput>;
  dimension?: InputMaybe<ComparableInt32OperationFilterInput>;
  hasM?: InputMaybe<BooleanOperationFilterInput>;
  hasZ?: InputMaybe<BooleanOperationFilterInput>;
  measures?: InputMaybe<ComparableInt32OperationFilterInput>;
  mOrdinateIndex?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<CoordinateSequenceFilterInput>>;
  ordinates?: InputMaybe<OrdinatesOperationFilterInput>;
  spatial?: InputMaybe<ComparableInt32OperationFilterInput>;
  zOrdinateIndex?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export enum CountryCode {
  US = 'US'
}

export type CreateCheckoutFromCartError =
  | BillingCustomerAddressNotFoundError
  | CartNotFoundError
  | CustomerPaymentMethodNotFoundError
  | OutOfStockError
  | ShippingCustomerAddressNotFoundError;

export type CreateCheckoutFromCartInput = {
  billingCustomerAddressId?: InputMaybe<Scalars['ID']>;
  cartId: Scalars['ID'];
  customerPaymentMethodId?: InputMaybe<Scalars['ID']>;
  shippingCustomerAddressId?: InputMaybe<Scalars['ID']>;
};

export type CreateCheckoutFromCartPayload = {
  __typename?: 'CreateCheckoutFromCartPayload';
  checkout?: Maybe<Checkout>;
  errors?: Maybe<Array<CreateCheckoutFromCartError>>;
};

export type CreateCheckoutFromCartPosError =
  | BillingCustomerAddressNotFoundError
  | CartNotFoundError
  | CustomerPaymentMethodNotFoundError
  | OutOfStockError
  | ShippingCustomerAddressNotFoundError;

export type CreateCheckoutFromCartPosInput = {
  billingCustomerAddressId?: InputMaybe<Scalars['ID']>;
  cartId: Scalars['ID'];
  customerId: Scalars['ID'];
  customerPaymentMethodId?: InputMaybe<Scalars['ID']>;
  shippingCustomerAddressId?: InputMaybe<Scalars['ID']>;
  shopId: Scalars['ID'];
};

export type CreateCheckoutFromCartPosPayload = {
  __typename?: 'CreateCheckoutFromCartPosPayload';
  checkout?: Maybe<Checkout>;
  errors?: Maybe<Array<CreateCheckoutFromCartPosError>>;
};

export type CreateCustomerAddressError = CustomerNotFoundError | InvalidAddressError;

export type CreateCustomerAddressInput = {
  address: AddressInput;
  customerId: Scalars['ID'];
};

export type CreateCustomerAddressPayload = {
  __typename?: 'CreateCustomerAddressPayload';
  customerAddress?: Maybe<CustomerAddress>;
  errors?: Maybe<Array<CreateCustomerAddressError>>;
};

export type CreateCustomerAddressPosError = CustomerNotFoundError | InvalidAddressError;

export type CreateCustomerAddressPosInput = {
  address: AddressInput;
  customerId: Scalars['ID'];
};

export type CreateCustomerAddressPosPayload = {
  __typename?: 'CreateCustomerAddressPosPayload';
  customerAddress?: Maybe<CustomerAddress>;
  errors?: Maybe<Array<CreateCustomerAddressPosError>>;
};

export type CreateCustomerCardInput = {
  customerId: Scalars['ID'];
};

export type CreateCustomerCardPayload = {
  __typename?: 'CreateCustomerCardPayload';
  customerCard?: Maybe<CustomerCard>;
};

export type CreateCustomPackageError = InvalidAddressError | InvalidSlugError | ShopSlugAlreadyExistsError;

export type CreateCustomPackageInput = {
  shopId: Scalars['ID'];
  userPackageCreateRequest: UserPackageCreateRequestInput;
};

export type CreateCustomPackagePayload = {
  __typename?: 'CreateCustomPackagePayload';
  errors?: Maybe<Array<CreateCustomPackageError>>;
  shippingUserPackage?: Maybe<ShippingUserPackage>;
};

export type CreateExternalAccountError = PaymentAccountNotFoundError;

export type CreateExternalAccountInput = {
  defaultForCurrency?: InputMaybe<Scalars['Boolean']>;
  paymentAccountId: Scalars['ID'];
  token: Scalars['String'];
};

export type CreateExternalAccountPayload = {
  __typename?: 'CreateExternalAccountPayload';
  errors?: Maybe<Array<CreateExternalAccountError>>;
  externalAccount?: Maybe<ExternalAccount>;
};

export type CreateExternalAccountWithDetailsError = PaymentAccountNotFoundError;

export type CreateExternalAccountWithDetailsInput = {
  accountDetails: AccountDetailsInput;
  defaultForCurrency?: InputMaybe<Scalars['Boolean']>;
  paymentAccountId: Scalars['ID'];
};

export type CreateExternalAccountWithDetailsPayload = {
  __typename?: 'CreateExternalAccountWithDetailsPayload';
  errors?: Maybe<Array<CreateExternalAccountWithDetailsError>>;
  externalAccount?: Maybe<ExternalAccount>;
};

export type CreateGuestCustomerRequestInput = {
  phone?: InputMaybe<Scalars['String']>;
};

export type CreateImageUploadsInput = {
  images: Array<ImageUploadInput>;
  shopId: Scalars['ID'];
};

export type CreateImageUploadsPayload = {
  __typename?: 'CreateImageUploadsPayload';
  imageUploads?: Maybe<Array<ImageUpload>>;
};

export type CreateInventoryItemError =
  | InvalidMaxReservableQuantityError
  | ShopLocationNotFoundError
  | VariantNotFoundError;

export type CreateInventoryItemInput = {
  barcode?: InputMaybe<Scalars['Barcode']>;
  cost?: InputMaybe<MoneyInput>;
  dimensions?: InputMaybe<DimensionsInput>;
  locationInventories?: InputMaybe<Array<CreateLocationInventoryInput>>;
  maxReservable?: InputMaybe<Scalars['NonNegativeInt']>;
  sku?: InputMaybe<Scalars['Sku']>;
  variantId: Scalars['ID'];
  weight: WeightInput;
};

export type CreateInventoryItemLocationInventoryInput = {
  incoming: Scalars['NonNegativeInt'];
  quantity: Scalars['NonNegativeInt'];
  shopLocationId: Scalars['ID'];
  stockLocation?: InputMaybe<StockLocationInput>;
};

export type CreateInventoryItemPayload = {
  __typename?: 'CreateInventoryItemPayload';
  errors?: Maybe<Array<CreateInventoryItemError>>;
  inventoryItem?: Maybe<InventoryItem>;
};

export type CreateLinkTokenInput = {
  userId: Scalars['String'];
};

export type CreateLinkTokenPayload = {
  __typename?: 'CreateLinkTokenPayload';
  string?: Maybe<Scalars['String']>;
};

export type CreateLocationInventoryInput = {
  externalId?: InputMaybe<Scalars['String']>;
  incoming: Scalars['NonNegativeInt'];
  quantity: Scalars['NonNegativeInt'];
  shopLocationId: Scalars['ID'];
  source?: InputMaybe<InventorySource>;
  stockLocation?: InputMaybe<StockLocationInput>;
};

export type CreateOptionError = ProductNotFoundError;

export type CreateOptionInput = {
  createOption: OptionInput;
  productId: Scalars['ID'];
};

export type CreateOptionPayload = {
  __typename?: 'CreateOptionPayload';
  errors?: Maybe<Array<CreateOptionError>>;
  option?: Maybe<Option>;
};

export type CreateOrderFromCheckoutError =
  | CheckoutNotFoundError
  | CustomerEmailRequiredError
  | LineItemsCountError
  | NoItemsError
  | OutOfStockError
  | PaymentError;

export type CreateOrderFromCheckoutInput = {
  checkoutId: Scalars['ID'];
  ipAddress?: InputMaybe<Scalars['IPv4']>;
  isAfterpay?: InputMaybe<Scalars['Boolean']>;
  showId?: InputMaybe<Scalars['ID']>;
};

export type CreateOrderFromCheckoutPayload = {
  __typename?: 'CreateOrderFromCheckoutPayload';
  errors?: Maybe<Array<CreateOrderFromCheckoutError>>;
  order?: Maybe<Order>;
};

export type CreateOrderFromCheckoutPosError =
  | CheckoutNotFoundError
  | CustomerEmailRequiredError
  | LineItemsCountError
  | NoItemsError
  | OutOfStockError
  | PaymentError;

export type CreateOrderFromCheckoutPosInput = {
  checkoutId: Scalars['ID'];
  customerId: Scalars['ID'];
  ipAddress?: InputMaybe<Scalars['IPv4']>;
  shopId?: InputMaybe<Scalars['ID']>;
};

export type CreateOrderFromCheckoutPosPayload = {
  __typename?: 'CreateOrderFromCheckoutPosPayload';
  errors?: Maybe<Array<CreateOrderFromCheckoutPosError>>;
  order?: Maybe<Order>;
};

export type CreateOrGetGuestCustomerInput = {
  request: CreateGuestCustomerRequestInput;
};

export type CreateOrGetGuestCustomerPayload = {
  __typename?: 'CreateOrGetGuestCustomerPayload';
  customer?: Maybe<Customer>;
};

export type CreatePackageError = CannotFulfillQuantityError;

export type CreatePackageInput = {
  carrierPackageId: Scalars['ID'];
  isCustomPackage?: Scalars['Boolean'];
  shopOrderId: Scalars['ID'];
};

export type CreatePackagePayload = {
  __typename?: 'CreatePackagePayload';
  errors?: Maybe<Array<CreatePackageError>>;
  orderPackage?: Maybe<OrderPackage>;
};

export type CreatePaymentAccountError = PaymentAccountAlreadyExistsError | ShopNotFoundError;

export type CreatePaymentAccountInput = {
  accountToken?: InputMaybe<Scalars['String']>;
  businessAccountCreationInfo?: InputMaybe<BusinessAccountCreationInfoInput>;
  individualAccountCreationInfo?: InputMaybe<IndividualAccountCreationInfoInput>;
  shopId: Scalars['ID'];
  tosAcceptanceDate: Scalars['DateTime'];
  tosAcceptanceIp?: InputMaybe<Scalars['IPv4']>;
};

export type CreatePaymentAccountOnboardingLinkError = PaymentAccountNotFoundError;

export type CreatePaymentAccountOnboardingLinkInput = {
  paymentAccountId: Scalars['ID'];
  refreshUrl?: InputMaybe<Scalars['URL']>;
  returnUrl?: InputMaybe<Scalars['URL']>;
};

export type CreatePaymentAccountOnboardingLinkPayload = {
  __typename?: 'CreatePaymentAccountOnboardingLinkPayload';
  errors?: Maybe<Array<CreatePaymentAccountOnboardingLinkError>>;
  paymentAccountLink?: Maybe<PaymentAccountLink>;
};

export type CreatePaymentAccountPayload = {
  __typename?: 'CreatePaymentAccountPayload';
  errors?: Maybe<Array<CreatePaymentAccountError>>;
  paymentAccount?: Maybe<PaymentAccount>;
};

export type CreatePaymentAccountUpdateLinkInput = {
  paymentAccountId: Scalars['ID'];
  refreshUrl?: InputMaybe<Scalars['URL']>;
  returnUrl?: InputMaybe<Scalars['URL']>;
};

export type CreatePaymentAccountUpdateLinkPayload = {
  __typename?: 'CreatePaymentAccountUpdateLinkPayload';
  paymentAccountLink?: Maybe<PaymentAccountLink>;
};

export type CreateProductCollectionInput = {
  affiliateUserId?: InputMaybe<Scalars['ID']>;
  commission?: InputMaybe<Scalars['Decimal']>;
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  productIds?: InputMaybe<Array<Scalars['ID']>>;
  shopId: Scalars['ID'];
};

export type CreateProductCollectionPayload = {
  __typename?: 'CreateProductCollectionPayload';
  productCollection?: Maybe<ProductCollection>;
};

export type CreateProductError =
  | CategoryNotFoundError
  | InvalidMaxReservableQuantityError
  | InvalidSlugError
  | ProductSlugAlreadyExistsError
  | ShopLocationNotFoundError
  | ShopNotFoundError;

export type CreateProductInput = {
  categoryId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<ProductImageInput>>;
  name: Scalars['String'];
  options?: InputMaybe<Array<OptionInput>>;
  shopId: Scalars['ID'];
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProductStatus>;
  variants?: InputMaybe<Array<CreateProductVariantInput>>;
};

export type CreateProductPayload = {
  __typename?: 'CreateProductPayload';
  errors?: Maybe<Array<CreateProductError>>;
  product?: Maybe<Product>;
};

export type CreateProductVariantInput = {
  externalId?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<VariantImageInput>>;
  inventoryItem: CreateVariantInventoryItemInput;
  optionValueIds?: InputMaybe<Array<Scalars['String']>>;
  prices: PricesInput;
  shippingProfileId?: InputMaybe<Scalars['ID']>;
  taxCategoryId?: InputMaybe<Scalars['ID']>;
};

export type CreateProfileImageUploadsInput = {
  images: Array<ProfileImageUploadInput>;
};

export type CreateProfileImageUploadsPayload = {
  __typename?: 'CreateProfileImageUploadsPayload';
  profileImageUploads?: Maybe<Array<ProfileImageUpload>>;
};

export type CreateShopCustomizationError = InvalidAddressError;

export type CreateShopCustomizationInput = {
  shopCoverPhotoId?: InputMaybe<Scalars['ID']>;
  shopDescription?: InputMaybe<Scalars['String']>;
  shopId: Scalars['ID'];
  shopProfileImageId?: InputMaybe<Scalars['ID']>;
};

export type CreateShopCustomizationPayload = {
  __typename?: 'CreateShopCustomizationPayload';
  errors?: Maybe<Array<CreateShopCustomizationError>>;
  shopCustomization?: Maybe<ShopCustomization>;
};

export type CreateShopError = InvalidAddressError | InvalidSlugError | ShopSlugAlreadyExistsError;

export type CreateShopInput = {
  address?: InputMaybe<AddressInput>;
  contactEmail?: InputMaybe<Scalars['EmailAddress']>;
  country: CountryCode;
  description: Scalars['String'];
  legalBusinessName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['PhoneNumber']>;
  slug: Scalars['String'];
};

export type CreateShopPayload = {
  __typename?: 'CreateShopPayload';
  errors?: Maybe<Array<CreateShopError>>;
  shop?: Maybe<Shop>;
};

export type CreateShowError = InvalidSlugError | ShopNotFoundError | ShowSlugAlreadyExistsError;

export type CreateShowInput = {
  affiliateShopId?: InputMaybe<Scalars['ID']>;
  commission?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  scheduledStartTime?: InputMaybe<Scalars['DateTime']>;
  shopId: Scalars['ID'];
  showFbPage?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type CreateShowPayload = {
  __typename?: 'CreateShowPayload';
  errors?: Maybe<Array<CreateShowError>>;
  show?: Maybe<Show>;
};

export type CreateStreamError = ShowNotFoundError;

export type CreateStreamInput = {
  chatHistory?: InputMaybe<Scalars['String']>;
  sessionId: Scalars['String'];
  showId: Scalars['ID'];
  streamReplayUrl?: InputMaybe<Scalars['String']>;
};

export type CreateStreamPayload = {
  __typename?: 'CreateStreamPayload';
  errors?: Maybe<Array<CreateStreamError>>;
  stream?: Maybe<Stream>;
};

export type CreateStripeBankAccountTokenInput = {
  accountToken?: InputMaybe<Scalars['String']>;
  businessAccountCreationInfo?: InputMaybe<BusinessAccountCreationInfoInput>;
  individualAccountCreationInfo?: InputMaybe<IndividualAccountCreationInfoInput>;
  shopId: Scalars['ID'];
  tosAcceptanceIp?: InputMaybe<Scalars['IPv4']>;
};

export type CreateStripeBankAccountTokenPayload = {
  __typename?: 'CreateStripeBankAccountTokenPayload';
  string?: Maybe<Scalars['String']>;
};

export type CreateVariantError =
  | DuplicateVariantOptionError
  | ImageNotFoundError
  | ShippingProfileNotFoundError
  | ShopLocationNotFoundError
  | TaxCategoryNotFoundError;

export type CreateVariantInput = {
  images?: InputMaybe<Array<VariantImageInput>>;
  inventoryItem: CreateVariantInventoryItemInput;
  optionValueIds?: InputMaybe<Array<Scalars['ID']>>;
  prices: PricesInput;
  productId: Scalars['ID'];
  shippingProfileId?: InputMaybe<Scalars['ID']>;
  taxCategoryId?: InputMaybe<Scalars['ID']>;
};

export type CreateVariantInventoryItemInput = {
  barcode?: InputMaybe<Scalars['Barcode']>;
  cost?: InputMaybe<MoneyInput>;
  dimensions?: InputMaybe<DimensionsInput>;
  locationInventories: Array<CreateInventoryItemLocationInventoryInput>;
  maxReservable?: InputMaybe<Scalars['NonNegativeInt']>;
  sku?: InputMaybe<Scalars['Sku']>;
  weight: WeightInput;
};

export type CreateVariantPayload = {
  __typename?: 'CreateVariantPayload';
  errors?: Maybe<Array<CreateVariantError>>;
  variant?: Maybe<Variant>;
};

export enum CurrencyCode {
  USD = 'USD'
}

export type CurrencyCodeOperationFilterInput = {
  eq?: InputMaybe<CurrencyCode>;
  in?: InputMaybe<Array<CurrencyCode>>;
  neq?: InputMaybe<CurrencyCode>;
  nin?: InputMaybe<Array<CurrencyCode>>;
};

export type Customer = Node & {
  __typename?: 'Customer';
  carts?: Maybe<CartsConnection>;
  checkouts?: Maybe<CheckoutsConnection>;
  createdAt: Scalars['DateTime'];
  customerAddresses?: Maybe<CustomerAddressesConnection>;
  customerPaymentMethods?: Maybe<CustomerPaymentMethodsConnection>;
  defaultCustomerAddress?: Maybe<CustomerAddress>;
  defaultCustomerAddressId?: Maybe<Scalars['ID']>;
  defaultCustomerPaymentMethod?: Maybe<CustomerPaymentMethod>;
  defaultCustomerPaymentMethodId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['EmailAddress']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['PhoneNumber']>;
  user?: Maybe<User>;
};

export type CustomerCartsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CustomerCheckoutsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CustomerCustomerAddressesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CustomerCustomerPaymentMethodsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CustomerPaymentMethodFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CustomerAddress = Node & {
  __typename?: 'CustomerAddress';
  address: Address;
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId: Scalars['ID'];
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

/** A connection to a list of items. */
export type CustomerAddressesByCustomerConnection = {
  __typename?: 'CustomerAddressesByCustomerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CustomerAddressesByCustomerEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CustomerAddress>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CustomerAddressesByCustomerEdge = {
  __typename?: 'CustomerAddressesByCustomerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CustomerAddress;
};

/** A connection to a list of items. */
export type CustomerAddressesConnection = {
  __typename?: 'CustomerAddressesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CustomerAddressesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CustomerAddress>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CustomerAddressesEdge = {
  __typename?: 'CustomerAddressesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CustomerAddress;
};

export type CustomerAddressFilterInput = {
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<CustomerAddressFilterInput>>;
  coordinates?: InputMaybe<PointFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customer?: InputMaybe<CustomerFilterInput>;
  customerId?: InputMaybe<ComparableGuidOperationFilterInput>;
  default?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<CustomerAddressFilterInput>>;
  updatedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

export type CustomerCard = CustomerPaymentMethod &
  Node & {
    __typename?: 'CustomerCard';
    brand?: Maybe<CardBrand>;
    country?: Maybe<CountryCode>;
    createdAt: Scalars['DateTime'];
    customer?: Maybe<Customer>;
    customerId: Scalars['ID'];
    default: Scalars['Boolean'];
    expMonth?: Maybe<Scalars['Int']>;
    expYear?: Maybe<Scalars['Int']>;
    funding?: Maybe<CardFunding>;
    id: Scalars['ID'];
    last4?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    setupSecret?: Maybe<Scalars['String']>;
    status: CustomerPaymentMethodStatus;
  };

export type CustomerEmailRequiredError = Error & {
  __typename?: 'CustomerEmailRequiredError';
  message: Scalars['String'];
};

export type CustomerFilterInput = {
  and?: InputMaybe<Array<CustomerFilterInput>>;
  carts?: InputMaybe<ListFilterInputTypeOfCartFilterInput>;
  checkouts?: InputMaybe<ListFilterInputTypeOfCheckoutFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customerAddresses?: InputMaybe<ListFilterInputTypeOfCustomerAddressFilterInput>;
  customerPaymentMethods?: InputMaybe<ListFilterInputTypeOfCustomerPaymentMethodFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CustomerFilterInput>>;
  orders?: InputMaybe<ListFilterInputTypeOfOrderFilterInput>;
  paymentIntents?: InputMaybe<ListFilterInputTypeOfPaymentFilterInput>;
  paymentProviderCustomers?: InputMaybe<ListFilterInputTypeOfPaymentProviderCustomerFilterInput>;
  phone?: InputMaybe<StringOperationFilterInput>;
  searchVector?: InputMaybe<NpgsqlTsVectorFilterInput>;
  user?: InputMaybe<UserFilterInput>;
};

export type CustomerNotFoundError = Error & {
  __typename?: 'CustomerNotFoundError';
  message: Scalars['String'];
};

export type CustomerPaymentMethod = {
  createdAt: Scalars['DateTime'];
  customerId: Scalars['ID'];
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  setupSecret?: Maybe<Scalars['String']>;
  status: CustomerPaymentMethodStatus;
};

export type CustomerPaymentMethodFilterInput = {
  and?: InputMaybe<Array<CustomerPaymentMethodFilterInput>>;
  or?: InputMaybe<Array<CustomerPaymentMethodFilterInput>>;
  status?: InputMaybe<CustomerPaymentMethodStatusOperationFilterInput>;
};

export type CustomerPaymentMethodNotFoundError = Error & {
  __typename?: 'CustomerPaymentMethodNotFoundError';
  message: Scalars['String'];
};

/** A connection to a list of items. */
export type CustomerPaymentMethodsByCustomerConnection = {
  __typename?: 'CustomerPaymentMethodsByCustomerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CustomerPaymentMethodsByCustomerEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<CustomerPaymentMethod>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CustomerPaymentMethodsByCustomerEdge = {
  __typename?: 'CustomerPaymentMethodsByCustomerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CustomerPaymentMethod>;
};

/** A connection to a list of items. */
export type CustomerPaymentMethodsConnection = {
  __typename?: 'CustomerPaymentMethodsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CustomerPaymentMethodsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<CustomerPaymentMethod>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CustomerPaymentMethodsEdge = {
  __typename?: 'CustomerPaymentMethodsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CustomerPaymentMethod>;
};

export enum CustomerPaymentMethodStatus {
  EXPIRED = 'EXPIRED',
  SETUP = 'SETUP',
  VERIFIED = 'VERIFIED'
}

export type CustomerPaymentMethodStatusOperationFilterInput = {
  eq?: InputMaybe<CustomerPaymentMethodStatus>;
  in?: InputMaybe<Array<CustomerPaymentMethodStatus>>;
  neq?: InputMaybe<CustomerPaymentMethodStatus>;
  nin?: InputMaybe<Array<CustomerPaymentMethodStatus>>;
};

export type CustomerSortInput = {
  createdAt?: InputMaybe<OrderDirection>;
  email?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
  phone?: InputMaybe<OrderDirection>;
  searchVector?: InputMaybe<NpgsqlTsVectorSortInput>;
  user?: InputMaybe<UserSortInput>;
};

export type CustomizationOption = Node &
  Option & {
    __typename?: 'CustomizationOption';
    cartItemCustomizations?: Maybe<CartItemCustomizationsConnection>;
    checkoutLineItemCustomizations?: Maybe<CheckoutLineItemCustomizationsConnection>;
    enableEmojis: Scalars['Boolean'];
    enableLetters: Scalars['Boolean'];
    enableNumbers: Scalars['Boolean'];
    id: Scalars['ID'];
    maxLength: Scalars['Int'];
    minLength: Scalars['Int'];
    name: Scalars['String'];
    optionValues?: Maybe<OptionValuesConnection>;
    order: Scalars['Int'];
    orderLineItemCustomizations?: Maybe<OrderLineItemCustomizationsConnection>;
    product: Product;
    productId: Scalars['ID'];
    required: Scalars['Boolean'];
    uppercaseOnly: Scalars['Boolean'];
  };

export type CustomizationOptionCartItemCustomizationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CustomizationOptionCheckoutLineItemCustomizationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CustomizationOptionOptionValuesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CustomizationOptionOrderLineItemCustomizationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CustomizationOptionFilterInput = {
  and?: InputMaybe<Array<CustomizationOptionFilterInput>>;
  enableEmojis?: InputMaybe<BooleanOperationFilterInput>;
  enableLetters?: InputMaybe<BooleanOperationFilterInput>;
  enableNumbers?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  maxLength?: InputMaybe<ComparableInt32OperationFilterInput>;
  minLength?: InputMaybe<ComparableInt32OperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  optionValues?: InputMaybe<ListFilterInputTypeOfOptionValueFilterInput>;
  or?: InputMaybe<Array<CustomizationOptionFilterInput>>;
  order?: InputMaybe<ComparableInt32OperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<ComparableGuidOperationFilterInput>;
  required?: InputMaybe<BooleanOperationFilterInput>;
  uppercaseOnly?: InputMaybe<BooleanOperationFilterInput>;
};

export type CustomizationOptionInput = {
  enableEmojis: Scalars['Boolean'];
  enableLetters: Scalars['Boolean'];
  enableNumbers: Scalars['Boolean'];
  id?: InputMaybe<Scalars['String']>;
  maxLength: Scalars['Int'];
  minLength: Scalars['Int'];
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  required: Scalars['Boolean'];
  uppercaseOnly: Scalars['Boolean'];
};

export type CustomizationOptionValue = Node &
  OptionValue & {
    __typename?: 'CustomizationOptionValue';
    enabled: Scalars['Boolean'];
    id: Scalars['ID'];
    label: Scalars['String'];
    option: Option;
    optionId: Scalars['ID'];
    order: Scalars['Int'];
    variantOptionValues: Array<VariantOptionValue>;
    variants?: Maybe<VariantsConnection>;
  };

export type CustomizationOptionValueVariantsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DeclineAffiliateInviteError = ShopNotFoundError | ShowNotFoundError;

export type DeclineAffiliateInviteInput = {
  showId: Scalars['ID'];
};

export type DeclineAffiliateInvitePayload = {
  __typename?: 'DeclineAffiliateInvitePayload';
  errors?: Maybe<Array<DeclineAffiliateInviteError>>;
  show?: Maybe<Show>;
};

export type DeleteCheckoutError = CheckoutNotFoundError;

export type DeleteCheckoutInput = {
  checkoutId: Scalars['ID'];
};

export type DeleteCheckoutPayload = {
  __typename?: 'DeleteCheckoutPayload';
  checkout?: Maybe<Checkout>;
  errors?: Maybe<Array<DeleteCheckoutError>>;
};

export type DeleteCustomerAddressInput = {
  customerAddressId: Scalars['ID'];
};

export type DeleteCustomerAddressPayload = {
  __typename?: 'DeleteCustomerAddressPayload';
  customerAddress?: Maybe<CustomerAddress>;
};

export type DeleteCustomerPaymentMethodInput = {
  customerPaymentMethodId: Scalars['ID'];
};

export type DeleteCustomerPaymentMethodPayload = {
  __typename?: 'DeleteCustomerPaymentMethodPayload';
  customerPaymentMethod?: Maybe<CustomerPaymentMethod>;
};

export type DeleteExternalAccountInput = {
  externalAccountId: Scalars['ID'];
};

export type DeleteExternalAccountPayload = {
  __typename?: 'DeleteExternalAccountPayload';
  externalAccount?: Maybe<ExternalAccount>;
};

export type DeleteInventoryItemError = InventoryItemNotFoundError;

export type DeleteInventoryItemInput = {
  inventoryItemId: Scalars['ID'];
};

export type DeleteInventoryItemPayload = {
  __typename?: 'DeleteInventoryItemPayload';
  errors?: Maybe<Array<DeleteInventoryItemError>>;
  inventoryItem?: Maybe<InventoryItem>;
};

export type DeleteOptionInput = {
  optionId: Scalars['ID'];
  productId: Scalars['ID'];
};

export type DeleteOptionPayload = {
  __typename?: 'DeleteOptionPayload';
  option?: Maybe<Option>;
};

export type DeletePackageError = CannotFulfillQuantityError;

export type DeletePackageInput = {
  orderPackageId: Scalars['ID'];
  shopOrderId: Scalars['ID'];
};

export type DeletePackagePayload = {
  __typename?: 'DeletePackagePayload';
  errors?: Maybe<Array<DeletePackageError>>;
  shopOrder?: Maybe<ShopOrder>;
};

export type DeleteProductCollectionInput = {
  collectionId: Scalars['ID'];
};

export type DeleteProductCollectionPayload = {
  __typename?: 'DeleteProductCollectionPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type DeleteProductError = ProductNotArchivedError;

export type DeleteProductImageInput = {
  imageId: Scalars['ID'];
  productId: Scalars['ID'];
};

export type DeleteProductImagePayload = {
  __typename?: 'DeleteProductImagePayload';
  productImage?: Maybe<ProductImage>;
};

export type DeleteProductInput = {
  productId: Scalars['ID'];
};

export type DeleteProductPayload = {
  __typename?: 'DeleteProductPayload';
  errors?: Maybe<Array<DeleteProductError>>;
  product?: Maybe<Product>;
};

export type DeleteVariantImageInput = {
  imageId: Scalars['ID'];
  variantId: Scalars['ID'];
};

export type DeleteVariantImagePayload = {
  __typename?: 'DeleteVariantImagePayload';
  variantImage?: Maybe<VariantImage>;
};

export type DeleteVariantInput = {
  variantId: Scalars['ID'];
};

export type DeleteVariantPayload = {
  __typename?: 'DeleteVariantPayload';
  variant?: Maybe<Variant>;
};

export enum Dimension {
  CURVE = 'CURVE',
  DONTCARE = 'DONTCARE',
  FALSE = 'FALSE',
  POINT = 'POINT',
  SURFACE = 'SURFACE',
  TRUE = 'TRUE'
}

export type DimensionOperationFilterInput = {
  eq?: InputMaybe<Dimension>;
  in?: InputMaybe<Array<Dimension>>;
  neq?: InputMaybe<Dimension>;
  nin?: InputMaybe<Array<Dimension>>;
};

export type Dimensions = {
  __typename?: 'Dimensions';
  height: Scalars['Float'];
  length: Scalars['Float'];
  unit: LengthUnit;
  width: Scalars['Float'];
};

export type DimensionsFilterInput = {
  and?: InputMaybe<Array<DimensionsFilterInput>>;
  height?: InputMaybe<ComparableDoubleOperationFilterInput>;
  heightCm?: InputMaybe<ComparableDoubleOperationFilterInput>;
  length?: InputMaybe<ComparableDoubleOperationFilterInput>;
  lengthCm?: InputMaybe<ComparableDoubleOperationFilterInput>;
  or?: InputMaybe<Array<DimensionsFilterInput>>;
  unit?: InputMaybe<LengthUnitOperationFilterInput>;
  width?: InputMaybe<ComparableDoubleOperationFilterInput>;
  widthCm?: InputMaybe<ComparableDoubleOperationFilterInput>;
};

export type DimensionsInput = {
  height: Scalars['Float'];
  length: Scalars['Float'];
  unit: LengthUnit;
  width: Scalars['Float'];
};

export type DimensionsSortInput = {
  height?: InputMaybe<OrderDirection>;
  heightCm?: InputMaybe<OrderDirection>;
  length?: InputMaybe<OrderDirection>;
  lengthCm?: InputMaybe<OrderDirection>;
  unit?: InputMaybe<OrderDirection>;
  width?: InputMaybe<OrderDirection>;
  widthCm?: InputMaybe<OrderDirection>;
};

export type DisableFbCommentsError = FbCommentsSessionNotFoundError;

export type DisableFbCommentsInput = {
  sessionId: Scalars['ID'];
};

export type DisableFbCommentsPayload = {
  __typename?: 'DisableFbCommentsPayload';
  errors?: Maybe<Array<DisableFbCommentsError>>;
  fbCommentsSession?: Maybe<FbCommentsSession>;
};

export type DisconnectFbBusinessAccountError = FbBusinessAccountNotFoundError;

export type DisconnectFbBusinessAccountInput = {
  fbBusinessAccountId: Scalars['ID'];
};

export type DisconnectFbBusinessAccountPayload = {
  __typename?: 'DisconnectFbBusinessAccountPayload';
  errors?: Maybe<Array<DisconnectFbBusinessAccountError>>;
  fbBusinessAccount?: Maybe<FbBusinessAccount>;
};

export type DuplicateVariantOptionError = Error & {
  __typename?: 'DuplicateVariantOptionError';
  message: Scalars['String'];
  variantOptionId: Scalars['ID'];
};

export type EmailVerificationResponse = {
  __typename?: 'EmailVerificationResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type EnableFbCommentsError =
  | FbApiError
  | FbBusinessAccountNotFoundError
  | FbCommentsSessionAlreadyStartedError
  | FbLiveNotFoundError
  | FbPageNotFoundError
  | ShowNotFoundError;

export type EnableFbCommentsInput = {
  liveId: Scalars['ID'];
  pageId: Scalars['ID'];
  showId: Scalars['ID'];
};

export type EnableFbCommentsPayload = {
  __typename?: 'EnableFbCommentsPayload';
  errors?: Maybe<Array<EnableFbCommentsError>>;
  fbCommentsSession?: Maybe<FbCommentsSession>;
};

export type EnvelopeFilterInput = {
  and?: InputMaybe<Array<EnvelopeFilterInput>>;
  area?: InputMaybe<ComparableDoubleOperationFilterInput>;
  centre?: InputMaybe<CoordinateFilterInput>;
  height?: InputMaybe<ComparableDoubleOperationFilterInput>;
  isNull?: InputMaybe<BooleanOperationFilterInput>;
  maxExtent?: InputMaybe<ComparableDoubleOperationFilterInput>;
  maxX?: InputMaybe<ComparableDoubleOperationFilterInput>;
  maxY?: InputMaybe<ComparableDoubleOperationFilterInput>;
  minExtent?: InputMaybe<ComparableDoubleOperationFilterInput>;
  minX?: InputMaybe<ComparableDoubleOperationFilterInput>;
  minY?: InputMaybe<ComparableDoubleOperationFilterInput>;
  or?: InputMaybe<Array<EnvelopeFilterInput>>;
  width?: InputMaybe<ComparableDoubleOperationFilterInput>;
};

export type Error = {
  message: Scalars['String'];
};

export type ExchangePublicTokenInput = {
  publicToken: Scalars['String'];
  shopId: Scalars['ID'];
};

export type ExchangePublicTokenPayload = {
  __typename?: 'ExchangePublicTokenPayload';
  string?: Maybe<Scalars['String']>;
};

export type ExternalAccount = {
  country: CountryCode;
  createdAt: Scalars['DateTime'];
  currency: CurrencyCode;
  defaultForCurrency: Scalars['Boolean'];
  id: Scalars['ID'];
  last4: Scalars['String'];
  paymentAccountId: Scalars['ID'];
  paymentProvider: PaymentProvider;
};

/** A connection to a list of items. */
export type ExternalAccountsByPaymentAccountConnection = {
  __typename?: 'ExternalAccountsByPaymentAccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ExternalAccountsByPaymentAccountEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<ExternalAccount>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ExternalAccountsByPaymentAccountEdge = {
  __typename?: 'ExternalAccountsByPaymentAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ExternalAccount>;
};

/** A connection to a list of items. */
export type ExternalAccountsConnection = {
  __typename?: 'ExternalAccountsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ExternalAccountsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<ExternalAccount>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ExternalAccountsEdge = {
  __typename?: 'ExternalAccountsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ExternalAccount>;
};

export type FbApiError = Error & {
  __typename?: 'FbApiError';
  message: Scalars['String'];
};

export type FbBusinessAccount = Node & {
  __typename?: 'FbBusinessAccount';
  connectedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  pages?: Maybe<PagesConnection>;
  scopes: Array<Scalars['String']>;
  shopId: Scalars['ID'];
  systemUserId: Scalars['ID'];
};

export type FbBusinessAccountPagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type FbBusinessAccountAlreadyConnectedError = Error & {
  __typename?: 'FbBusinessAccountAlreadyConnectedError';
  message: Scalars['String'];
  shopId: Scalars['ID'];
};

export type FbBusinessAccountNotFoundError = Error & {
  __typename?: 'FbBusinessAccountNotFoundError';
  businessAccountId?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
  pageId?: Maybe<Scalars['ID']>;
  shopId?: Maybe<Scalars['ID']>;
};

export type FbComment = {
  __typename?: 'FbComment';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  liveId: Scalars['ID'];
  message: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
};

export type FbCommentsSession = {
  __typename?: 'FbCommentsSession';
  businessAccountId: Scalars['ID'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  live?: Maybe<FbLive>;
  liveId: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  page: FbPage;
  pageId: Scalars['ID'];
  shopId: Scalars['ID'];
  showId: Scalars['ID'];
  status: FbCommentsSessionStatus;
};

export type FbCommentsSessionAlreadyStartedError = Error & {
  __typename?: 'FbCommentsSessionAlreadyStartedError';
  message: Scalars['String'];
};

export type FbCommentsSessionNotFoundError = Error & {
  __typename?: 'FbCommentsSessionNotFoundError';
  message: Scalars['String'];
  sessionId: Scalars['ID'];
};

export enum FbCommentsSessionStatus {
  ERROR = 'ERROR',
  RETRYING = 'RETRYING',
  RUNNING = 'RUNNING',
  STARTING = 'STARTING',
  STOPPED = 'STOPPED',
  STOPPING = 'STOPPING'
}

export type FbCommentsSessionStatusEvent = {
  __typename?: 'FbCommentsSessionStatusEvent';
  businessAccountId: Scalars['ID'];
  error?: Maybe<Scalars['String']>;
  lastUpdatedAt: Scalars['DateTime'];
  liveId: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  pageId: Scalars['ID'];
  sessionId: Scalars['ID'];
  shopId: Scalars['ID'];
  showId: Scalars['ID'];
  status: FbCommentsSessionStatus;
};

export type FbLive = {
  __typename?: 'FbLive';
  comments?: Maybe<CommentsConnection>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  pageId: Scalars['ID'];
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<FbLiveStatus>;
};

export type FbLiveCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type FbLiveNotFoundError = Error & {
  __typename?: 'FbLiveNotFoundError';
  liveId: Scalars['ID'];
  message: Scalars['String'];
};

export enum FbLiveStatus {
  LIVE = 'LIVE',
  LIVE_STOPPED = 'LIVE_STOPPED',
  PROCESSING = 'PROCESSING',
  SCHEDULED_CANCELED = 'SCHEDULED_CANCELED',
  SCHEDULED_EXPIRED = 'SCHEDULED_EXPIRED',
  SCHEDULED_LIVE = 'SCHEDULED_LIVE',
  SCHEDULED_UNPUBLISHED = 'SCHEDULED_UNPUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  VOD = 'VOD'
}

export type FbPage = {
  __typename?: 'FbPage';
  category: Scalars['String'];
  id: Scalars['ID'];
  lives?: Maybe<LivesConnection>;
  name: Scalars['String'];
};

export type FbPageLivesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<FbLiveStatus>>;
};

export type FbPageNotFoundError = Error & {
  __typename?: 'FbPageNotFoundError';
  message: Scalars['String'];
  pageId: Scalars['ID'];
};

/** A connection to a list of items. */
export type FbPagesByShopConnection = {
  __typename?: 'FbPagesByShopConnection';
  /** A list of edges. */
  edges?: Maybe<Array<FbPagesByShopEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<FbPage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type FbPagesByShopEdge = {
  __typename?: 'FbPagesByShopEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FbPage;
};

/** A connection to a list of items. */
export type FbPagesConnection = {
  __typename?: 'FbPagesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<FbPagesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<FbPage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type FbPagesEdge = {
  __typename?: 'FbPagesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FbPage;
};

export type FbPageUser = {
  __typename?: 'FbPageUser';
  businessAccountId: Scalars['ID'];
  pageId: Scalars['ID'];
  pageScopedUserId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type FbPageUserNotFoundError = Error & {
  __typename?: 'FbPageUserNotFoundError';
  message: Scalars['String'];
  pageId: Scalars['ID'];
  pageScopedUserId: Scalars['ID'];
};

export type FixedShippingRate = Node &
  ShippingRate & {
    __typename?: 'FixedShippingRate';
    id: Scalars['ID'];
    price: Money;
    shop?: Maybe<Shop>;
    shopId: Scalars['ID'];
  };

export type FulfillOrderShipmentLineItemError = CannotFulfillQuantityError;

export type FulfillOrderShipmentLineItemInput = {
  fulfillQuantity: Scalars['Int'];
  orderShipmentLineItemId: Scalars['ID'];
};

export type FulfillOrderShipmentLineItemPayload = {
  __typename?: 'FulfillOrderShipmentLineItemPayload';
  errors?: Maybe<Array<FulfillOrderShipmentLineItemError>>;
  orderShipmentLineItem?: Maybe<OrderShipmentLineItem>;
};

export enum FulfilmentStatus {
  FULFILED = 'FULFILED',
  NOT_STARTED = 'NOT_STARTED',
  PACKED = 'PACKED',
  PICKED = 'PICKED'
}

export type GeometryFactoryFilterInput = {
  and?: InputMaybe<Array<GeometryFactoryFilterInput>>;
  coordinateSequenceFactory?: InputMaybe<CoordinateSequenceFactoryFilterInput>;
  or?: InputMaybe<Array<GeometryFactoryFilterInput>>;
  precisionModel?: InputMaybe<PrecisionModelFilterInput>;
  sRID?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type GeometryFilterInput = {
  and?: InputMaybe<Array<GeometryFilterInput>>;
  area?: InputMaybe<ComparableDoubleOperationFilterInput>;
  boundary?: InputMaybe<GeometryFilterInput>;
  boundaryDimension?: InputMaybe<DimensionOperationFilterInput>;
  centroid?: InputMaybe<PointFilterInput>;
  coordinate?: InputMaybe<CoordinateFilterInput>;
  coordinates?: InputMaybe<ListFilterInputTypeOfCoordinateFilterInput>;
  dimension?: InputMaybe<DimensionOperationFilterInput>;
  envelope?: InputMaybe<GeometryFilterInput>;
  envelopeInternal?: InputMaybe<EnvelopeFilterInput>;
  factory?: InputMaybe<GeometryFactoryFilterInput>;
  geometryType?: InputMaybe<StringOperationFilterInput>;
  interiorPoint?: InputMaybe<PointFilterInput>;
  isEmpty?: InputMaybe<BooleanOperationFilterInput>;
  isRectangle?: InputMaybe<BooleanOperationFilterInput>;
  isSimple?: InputMaybe<BooleanOperationFilterInput>;
  isValid?: InputMaybe<BooleanOperationFilterInput>;
  length?: InputMaybe<ComparableDoubleOperationFilterInput>;
  numGeometries?: InputMaybe<ComparableInt32OperationFilterInput>;
  numPoints?: InputMaybe<ComparableInt32OperationFilterInput>;
  ogcGeometryType?: InputMaybe<OgcGeometryTypeOperationFilterInput>;
  or?: InputMaybe<Array<GeometryFilterInput>>;
  pointOnSurface?: InputMaybe<PointFilterInput>;
  precisionModel?: InputMaybe<PrecisionModelFilterInput>;
  sRID?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type HidePackageTemplateError = InvalidAddressError | InvalidSlugError | ShopSlugAlreadyExistsError;

export type HidePackageTemplateInput = {
  carrierPackageIds: Array<Scalars['ID']>;
  shopId: Scalars['ID'];
};

export type HidePackageTemplatePayload = {
  __typename?: 'HidePackageTemplatePayload';
  errors?: Maybe<Array<HidePackageTemplateError>>;
  shippingCarrierPackage?: Maybe<Array<ShippingCarrierPackage>>;
};

export type Identity = {
  __typename?: 'Identity';
  accessToken?: Maybe<Scalars['String']>;
  provider: IdentityProvider;
  refreshToken?: Maybe<Scalars['String']>;
  social: Scalars['Boolean'];
  userId: Scalars['String'];
};

export enum IdentityProvider {
  EMAIL = 'EMAIL',
  FACEBOOK = 'FACEBOOK'
}

export type Image = Node & {
  __typename?: 'Image';
  createdAt: Scalars['DateTime'];
  externalId?: Maybe<Scalars['String']>;
  focusRegion?: Maybe<ImageRegion>;
  format: ImageFormat;
  height: Scalars['Int'];
  id: Scalars['ID'];
  originalSrc: Scalars['URL'];
  shop: Shop;
  shopId: Scalars['ID'];
  shopImages: Array<ShopImage>;
  showImages: Array<ShowImage>;
  source: ImageSource;
  thumbnailSrc?: Maybe<Scalars['URL']>;
  transformedSrc?: Maybe<Scalars['URL']>;
  updatedAt: Scalars['DateTime'];
  width: Scalars['Int'];
};

export type ImageTransformedSrcArgs = {
  crop?: InputMaybe<ImageRegionInput>;
  format?: InputMaybe<ImageFormat>;
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type ImageFilterInput = {
  and?: InputMaybe<Array<ImageFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  focusRegion?: InputMaybe<ImageRegionFilterInput>;
  format?: InputMaybe<ImageFormatOperationFilterInput>;
  height?: InputMaybe<ComparableInt32OperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<ImageFilterInput>>;
  productImages?: InputMaybe<ListFilterInputTypeOfProductImageFilterInput>;
  products?: InputMaybe<ListFilterInputTypeOfProductFilterInput>;
  shop?: InputMaybe<ShopFilterInput>;
  shopId?: InputMaybe<ComparableGuidOperationFilterInput>;
  shopImages?: InputMaybe<ListFilterInputTypeOfShopImageFilterInput>;
  showImages?: InputMaybe<ListFilterInputTypeOfShowImageFilterInput>;
  source?: InputMaybe<ImageSourceOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  variantImages?: InputMaybe<ListFilterInputTypeOfVariantImageFilterInput>;
  variants?: InputMaybe<ListFilterInputTypeOfVariantFilterInput>;
  width?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export enum ImageFormat {
  JPG = 'JPG',
  PNG = 'PNG',
  WEBP = 'WEBP'
}

export type ImageFormatOperationFilterInput = {
  eq?: InputMaybe<ImageFormat>;
  in?: InputMaybe<Array<ImageFormat>>;
  neq?: InputMaybe<ImageFormat>;
  nin?: InputMaybe<Array<ImageFormat>>;
};

export type ImageNotFoundError = Error & {
  __typename?: 'ImageNotFoundError';
  message: Scalars['String'];
};

export type ImageRegion = {
  __typename?: 'ImageRegion';
  height: Scalars['Int'];
  width: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type ImageRegionFilterInput = {
  and?: InputMaybe<Array<ImageRegionFilterInput>>;
  height?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<ImageRegionFilterInput>>;
  width?: InputMaybe<ComparableInt32OperationFilterInput>;
  x?: InputMaybe<ComparableInt32OperationFilterInput>;
  y?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type ImageRegionInput = {
  height: Scalars['Int'];
  width: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type ImageRegionSortInput = {
  height?: InputMaybe<OrderDirection>;
  width?: InputMaybe<OrderDirection>;
  x?: InputMaybe<OrderDirection>;
  y?: InputMaybe<OrderDirection>;
};

export type ImageSortInput = {
  createdAt?: InputMaybe<OrderDirection>;
  externalId?: InputMaybe<OrderDirection>;
  focusRegion?: InputMaybe<ImageRegionSortInput>;
  format?: InputMaybe<OrderDirection>;
  height?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  shop?: InputMaybe<ShopSortInput>;
  shopId?: InputMaybe<OrderDirection>;
  source?: InputMaybe<OrderDirection>;
  updatedAt?: InputMaybe<OrderDirection>;
  width?: InputMaybe<OrderDirection>;
};

export enum ImageSource {
  JATANGO = 'JATANGO',
  SHOPIFY = 'SHOPIFY'
}

export type ImageSourceOperationFilterInput = {
  eq?: InputMaybe<ImageSource>;
  in?: InputMaybe<Array<ImageSource>>;
  neq?: InputMaybe<ImageSource>;
  nin?: InputMaybe<Array<ImageSource>>;
};

export type ImageUpload = {
  __typename?: 'ImageUpload';
  filename: Scalars['String'];
  focusRegion?: Maybe<ImageRegion>;
  imageId: Scalars['ID'];
  uploadUrl: Scalars['URL'];
};

export type ImageUploadInput = {
  filename: Scalars['String'];
  focusRegion?: InputMaybe<ImageRegionInput>;
  removeBackground?: InputMaybe<Scalars['Boolean']>;
};

export type IndividualAccountCreationInfoInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  last4DigitsOfSsn?: InputMaybe<Scalars['String']>;
  legalFirstName?: InputMaybe<Scalars['String']>;
  legalLastName?: InputMaybe<Scalars['String']>;
};

export type Integration = {
  __typename?: 'Integration';
  accessToken: Scalars['String'];
  connectedAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  integrationDomain: Scalars['String'];
  integrationSource: IntegrationSource;
  metadata: Scalars['String'];
  name: Scalars['String'];
  shop: Shop;
  shopId?: Maybe<Scalars['UUID']>;
};

export enum IntegrationSource {
  SHOPIFY = 'SHOPIFY'
}

export type InvalidAddressError = Error & {
  __typename?: 'InvalidAddressError';
  address: Address;
  errors?: Maybe<Array<AddressValidationError>>;
  matchedAddress?: Maybe<Address>;
  message: Scalars['String'];
};

export type InvalidMaxReservableQuantityError = Error & {
  __typename?: 'InvalidMaxReservableQuantityError';
  message: Scalars['String'];
};

export type InvalidQuantityError = Error & {
  __typename?: 'InvalidQuantityError';
  message: Scalars['String'];
};

export type InvalidReservationExpirationError = Error & {
  __typename?: 'InvalidReservationExpirationError';
  expiresAt: Scalars['DateTime'];
  message: Scalars['String'];
};

export type InvalidReservationQuantityError = Error & {
  __typename?: 'InvalidReservationQuantityError';
  message: Scalars['String'];
  quantity: Scalars['NonNegativeInt'];
};

export type InvalidSlugError = Error & {
  __typename?: 'InvalidSlugError';
  message: Scalars['String'];
};

export type InventoryItem = Node & {
  __typename?: 'InventoryItem';
  barcode?: Maybe<Scalars['Barcode']>;
  cost?: Maybe<Money>;
  createdAt: Scalars['DateTime'];
  dimensions?: Maybe<Dimensions>;
  id: Scalars['ID'];
  locationInventories?: Maybe<LocationInventoriesConnection>;
  maxReservable?: Maybe<Scalars['NonNegativeInt']>;
  product: Product;
  productId: Scalars['ID'];
  quantity: Quantity;
  reservations?: Maybe<ReservationsConnection>;
  shop: Shop;
  shopId: Scalars['ID'];
  sku?: Maybe<Scalars['Sku']>;
  updatedAt: Scalars['DateTime'];
  variant: Variant;
  variantId: Scalars['ID'];
  weight: Weight;
};

export type InventoryItemLocationInventoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type InventoryItemReservationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type InventoryItemNotFoundError = Error & {
  __typename?: 'InventoryItemNotFoundError';
  inventoryItemId: Scalars['ID'];
  message: Scalars['String'];
};

export type InventoryItemQuantityChangedEvent = {
  __typename?: 'InventoryItemQuantityChangedEvent';
  inventoryItemId: Scalars['ID'];
  quantity: Quantity;
};

/** A connection to a list of items. */
export type InventoryItemsByBarcodeConnection = {
  __typename?: 'InventoryItemsByBarcodeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<InventoryItemsByBarcodeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<InventoryItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type InventoryItemsByBarcodeEdge = {
  __typename?: 'InventoryItemsByBarcodeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: InventoryItem;
};

/** A connection to a list of items. */
export type InventoryItemsByProductConnection = {
  __typename?: 'InventoryItemsByProductConnection';
  /** A list of edges. */
  edges?: Maybe<Array<InventoryItemsByProductEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<InventoryItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type InventoryItemsByProductEdge = {
  __typename?: 'InventoryItemsByProductEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: InventoryItem;
};

/** A connection to a list of items. */
export type InventoryItemsByShopConnection = {
  __typename?: 'InventoryItemsByShopConnection';
  /** A list of edges. */
  edges?: Maybe<Array<InventoryItemsByShopEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<InventoryItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type InventoryItemsByShopEdge = {
  __typename?: 'InventoryItemsByShopEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: InventoryItem;
};

/** A connection to a list of items. */
export type InventoryItemsBySkuConnection = {
  __typename?: 'InventoryItemsBySkuConnection';
  /** A list of edges. */
  edges?: Maybe<Array<InventoryItemsBySkuEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<InventoryItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type InventoryItemsBySkuEdge = {
  __typename?: 'InventoryItemsBySkuEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: InventoryItem;
};

/** A connection to a list of items. */
export type InventoryItemsConnection = {
  __typename?: 'InventoryItemsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<InventoryItemsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<InventoryItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type InventoryItemsEdge = {
  __typename?: 'InventoryItemsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: InventoryItem;
};

export enum InventorySource {
  JATANGO = 'JATANGO',
  SHOPIFY = 'SHOPIFY'
}

export type InviteShowAffiliateError = AffiliateAlreadyInvitedError | ShopNotFoundError | ShowNotFoundError;

export type InviteShowAffiliateInput = {
  affiliateShopId: Scalars['ID'];
  commission: Scalars['Float'];
  showId: Scalars['ID'];
};

export type InviteShowAffiliatePayload = {
  __typename?: 'InviteShowAffiliatePayload';
  errors?: Maybe<Array<InviteShowAffiliateError>>;
  show?: Maybe<Show>;
};

export type ItemAlreadyInCartError = Error & {
  __typename?: 'ItemAlreadyInCartError';
  message: Scalars['String'];
};

export type Length = {
  __typename?: 'Length';
  unit: LengthUnit;
  value: Scalars['Float'];
};

export type LengthFilterInput = {
  and?: InputMaybe<Array<LengthFilterInput>>;
  or?: InputMaybe<Array<LengthFilterInput>>;
  unit?: InputMaybe<LengthUnitOperationFilterInput>;
  value?: InputMaybe<ComparableDoubleOperationFilterInput>;
  valueCm?: InputMaybe<ComparableDoubleOperationFilterInput>;
};

export type LengthSortInput = {
  unit?: InputMaybe<OrderDirection>;
  value?: InputMaybe<OrderDirection>;
  valueCm?: InputMaybe<OrderDirection>;
};

export enum LengthUnit {
  CENTIMETERS = 'CENTIMETERS',
  FEET = 'FEET',
  INCHES = 'INCHES',
  METERS = 'METERS'
}

export type LengthUnitOperationFilterInput = {
  eq?: InputMaybe<LengthUnit>;
  in?: InputMaybe<Array<LengthUnit>>;
  neq?: InputMaybe<LengthUnit>;
  nin?: InputMaybe<Array<LengthUnit>>;
};

export type LineItemsCountError = Error & {
  __typename?: 'LineItemsCountError';
  message: Scalars['String'];
};

export type LinkFbPageUserError = FbApiError | FbPageUserNotFoundError | UserNotFoundError;

export type LinkFbPageUserInput = {
  commentId: Scalars['ID'];
};

export type LinkFbPageUserPayload = {
  __typename?: 'LinkFbPageUserPayload';
  errors?: Maybe<Array<LinkFbPageUserError>>;
  fbPageUser?: Maybe<FbPageUser>;
};

export type ListFilterInputTypeOfCartFilterInput = {
  all?: InputMaybe<CartFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CartFilterInput>;
  some?: InputMaybe<CartFilterInput>;
};

export type ListFilterInputTypeOfCartItemFilterInput = {
  all?: InputMaybe<CartItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CartItemFilterInput>;
  some?: InputMaybe<CartItemFilterInput>;
};

export type ListFilterInputTypeOfCheckoutCartFilterInput = {
  all?: InputMaybe<CheckoutCartFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CheckoutCartFilterInput>;
  some?: InputMaybe<CheckoutCartFilterInput>;
};

export type ListFilterInputTypeOfCheckoutFilterInput = {
  all?: InputMaybe<CheckoutFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CheckoutFilterInput>;
  some?: InputMaybe<CheckoutFilterInput>;
};

export type ListFilterInputTypeOfCheckoutLineItemCustomizationFilterInput = {
  all?: InputMaybe<CheckoutLineItemCustomizationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CheckoutLineItemCustomizationFilterInput>;
  some?: InputMaybe<CheckoutLineItemCustomizationFilterInput>;
};

export type ListFilterInputTypeOfCheckoutLineItemFilterInput = {
  all?: InputMaybe<CheckoutLineItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CheckoutLineItemFilterInput>;
  some?: InputMaybe<CheckoutLineItemFilterInput>;
};

export type ListFilterInputTypeOfCheckoutShipmentFilterInput = {
  all?: InputMaybe<CheckoutShipmentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CheckoutShipmentFilterInput>;
  some?: InputMaybe<CheckoutShipmentFilterInput>;
};

export type ListFilterInputTypeOfCheckoutShipmentLineItemFilterInput = {
  all?: InputMaybe<CheckoutShipmentLineItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CheckoutShipmentLineItemFilterInput>;
  some?: InputMaybe<CheckoutShipmentLineItemFilterInput>;
};

export type ListFilterInputTypeOfCheckoutShippingRateFilterInput = {
  all?: InputMaybe<CheckoutShippingRateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CheckoutShippingRateFilterInput>;
  some?: InputMaybe<CheckoutShippingRateFilterInput>;
};

export type ListFilterInputTypeOfCoordinateFilterInput = {
  all?: InputMaybe<CoordinateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CoordinateFilterInput>;
  some?: InputMaybe<CoordinateFilterInput>;
};

export type ListFilterInputTypeOfCustomerAddressFilterInput = {
  all?: InputMaybe<CustomerAddressFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CustomerAddressFilterInput>;
  some?: InputMaybe<CustomerAddressFilterInput>;
};

export type ListFilterInputTypeOfCustomerPaymentMethodFilterInput = {
  all?: InputMaybe<CustomerPaymentMethodFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CustomerPaymentMethodFilterInput>;
  some?: InputMaybe<CustomerPaymentMethodFilterInput>;
};

export type ListFilterInputTypeOfOptionValueFilterInput = {
  all?: InputMaybe<OptionValueFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<OptionValueFilterInput>;
  some?: InputMaybe<OptionValueFilterInput>;
};

export type ListFilterInputTypeOfOrderFilterInput = {
  all?: InputMaybe<OrderFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<OrderFilterInput>;
  some?: InputMaybe<OrderFilterInput>;
};

export type ListFilterInputTypeOfOrderPaymentFilterInput = {
  all?: InputMaybe<OrderPaymentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<OrderPaymentFilterInput>;
  some?: InputMaybe<OrderPaymentFilterInput>;
};

export type ListFilterInputTypeOfPaymentFilterInput = {
  all?: InputMaybe<PaymentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<PaymentFilterInput>;
  some?: InputMaybe<PaymentFilterInput>;
};

export type ListFilterInputTypeOfPaymentProviderCustomerFilterInput = {
  all?: InputMaybe<PaymentProviderCustomerFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<PaymentProviderCustomerFilterInput>;
  some?: InputMaybe<PaymentProviderCustomerFilterInput>;
};

export type ListFilterInputTypeOfPlaidLinkFilterInput = {
  all?: InputMaybe<PlaidLinkFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<PlaidLinkFilterInput>;
  some?: InputMaybe<PlaidLinkFilterInput>;
};

export type ListFilterInputTypeOfProductFilterInput = {
  all?: InputMaybe<ProductFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductFilterInput>;
  some?: InputMaybe<ProductFilterInput>;
};

export type ListFilterInputTypeOfProductImageFilterInput = {
  all?: InputMaybe<ProductImageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductImageFilterInput>;
  some?: InputMaybe<ProductImageFilterInput>;
};

export type ListFilterInputTypeOfRefundFilterInput = {
  all?: InputMaybe<RefundFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RefundFilterInput>;
  some?: InputMaybe<RefundFilterInput>;
};

export type ListFilterInputTypeOfShopFilterInput = {
  all?: InputMaybe<ShopFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ShopFilterInput>;
  some?: InputMaybe<ShopFilterInput>;
};

export type ListFilterInputTypeOfShopImageFilterInput = {
  all?: InputMaybe<ShopImageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ShopImageFilterInput>;
  some?: InputMaybe<ShopImageFilterInput>;
};

export type ListFilterInputTypeOfShopOrderFilterInput = {
  all?: InputMaybe<ShopOrderFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ShopOrderFilterInput>;
  some?: InputMaybe<ShopOrderFilterInput>;
};

export type ListFilterInputTypeOfShopOrderShopPaymentFilterInput = {
  all?: InputMaybe<ShopOrderShopPaymentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ShopOrderShopPaymentFilterInput>;
  some?: InputMaybe<ShopOrderShopPaymentFilterInput>;
};

export type ListFilterInputTypeOfShopPaymentFilterInput = {
  all?: InputMaybe<ShopPaymentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ShopPaymentFilterInput>;
  some?: InputMaybe<ShopPaymentFilterInput>;
};

export type ListFilterInputTypeOfShopRefundFilterInput = {
  all?: InputMaybe<ShopRefundFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ShopRefundFilterInput>;
  some?: InputMaybe<ShopRefundFilterInput>;
};

export type ListFilterInputTypeOfShopTransactionFilterInput = {
  all?: InputMaybe<ShopTransactionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ShopTransactionFilterInput>;
  some?: InputMaybe<ShopTransactionFilterInput>;
};

export type ListFilterInputTypeOfShopUserFilterInput = {
  all?: InputMaybe<ShopUserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ShopUserFilterInput>;
  some?: InputMaybe<ShopUserFilterInput>;
};

export type ListFilterInputTypeOfShowImageFilterInput = {
  all?: InputMaybe<ShowImageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ShowImageFilterInput>;
  some?: InputMaybe<ShowImageFilterInput>;
};

export type ListFilterInputTypeOfUserDemographicFilterInput = {
  all?: InputMaybe<UserDemographicFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UserDemographicFilterInput>;
  some?: InputMaybe<UserDemographicFilterInput>;
};

export type ListFilterInputTypeOfUserImageFilterInput = {
  all?: InputMaybe<UserImageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UserImageFilterInput>;
  some?: InputMaybe<UserImageFilterInput>;
};

export type ListFilterInputTypeOfUserShoppingCategoryFilterInput = {
  all?: InputMaybe<UserShoppingCategoryFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UserShoppingCategoryFilterInput>;
  some?: InputMaybe<UserShoppingCategoryFilterInput>;
};

export type ListFilterInputTypeOfUserShoppingSubcategoryFilterInput = {
  all?: InputMaybe<UserShoppingSubcategoryFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UserShoppingSubcategoryFilterInput>;
  some?: InputMaybe<UserShoppingSubcategoryFilterInput>;
};

export type ListFilterInputTypeOfVariantFilterInput = {
  all?: InputMaybe<VariantFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<VariantFilterInput>;
  some?: InputMaybe<VariantFilterInput>;
};

export type ListFilterInputTypeOfVariantImageFilterInput = {
  all?: InputMaybe<VariantImageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<VariantImageFilterInput>;
  some?: InputMaybe<VariantImageFilterInput>;
};

export type ListFilterInputTypeOfVariantOptionValueFilterInput = {
  all?: InputMaybe<VariantOptionValueFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<VariantOptionValueFilterInput>;
  some?: InputMaybe<VariantOptionValueFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export enum LiveExperienceType {
  ABOVE100_SHOWS = 'ABOVE100_SHOWS',
  BELOW10_SHOWS = 'BELOW10_SHOWS',
  BETWEEN10_TO50_SHOWS = 'BETWEEN10_TO50_SHOWS',
  BETWEEN50_TO100_SHOWS = 'BETWEEN50_TO100_SHOWS',
  NONE = 'NONE'
}

/** A connection to a list of items. */
export type LivesConnection = {
  __typename?: 'LivesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<LivesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<FbLive>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LivesEdge = {
  __typename?: 'LivesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FbLive;
};

/** A connection to a list of items. */
export type LocationInventoriesByInventoryItemConnection = {
  __typename?: 'LocationInventoriesByInventoryItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<LocationInventoriesByInventoryItemEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<LocationInventory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LocationInventoriesByInventoryItemEdge = {
  __typename?: 'LocationInventoriesByInventoryItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: LocationInventory;
};

/** A connection to a list of items. */
export type LocationInventoriesByShopLocationConnection = {
  __typename?: 'LocationInventoriesByShopLocationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<LocationInventoriesByShopLocationEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<LocationInventory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LocationInventoriesByShopLocationEdge = {
  __typename?: 'LocationInventoriesByShopLocationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: LocationInventory;
};

/** A connection to a list of items. */
export type LocationInventoriesConnection = {
  __typename?: 'LocationInventoriesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<LocationInventoriesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<LocationInventory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LocationInventoriesEdge = {
  __typename?: 'LocationInventoriesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: LocationInventory;
};

export type LocationInventory = Node & {
  __typename?: 'LocationInventory';
  id: Scalars['ID'];
  incoming: Scalars['NonNegativeInt'];
  inventoryItem: InventoryItem;
  inventoryItemId: Scalars['ID'];
  quantity: Quantity;
  reservations?: Maybe<ReservationsConnection>;
  shopLocation: ShopLocation;
  shopLocationId: Scalars['ID'];
  stockLocation: StockLocation;
};

export type LocationInventoryReservationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type LocationInventoryAlreadyExistsError = Error & {
  __typename?: 'LocationInventoryAlreadyExistsError';
  inventoryItemId: Scalars['ID'];
  message: Scalars['String'];
  shopLocationId: Scalars['ID'];
};

export type LocationInventoryNotFoundError = Error & {
  __typename?: 'LocationInventoryNotFoundError';
  locationInventoryId: Scalars['ID'];
  message: Scalars['String'];
};

export type MarkShopOrderAsFulfilledInput = {
  shopOrderId: Scalars['ID'];
};

export type MarkShopOrderAsFulfilledPayload = {
  __typename?: 'MarkShopOrderAsFulfilledPayload';
  shopOrder?: Maybe<ShopOrder>;
};

export type MarkShopOrderAsPickedError = CannotFulfillQuantityError;

export type MarkShopOrderAsPickedInput = {
  lineItemQuantities: Array<OrderLineItemQuantitiesInput>;
  shopOrderId: Scalars['ID'];
};

export type MarkShopOrderAsPickedPayload = {
  __typename?: 'MarkShopOrderAsPickedPayload';
  errors?: Maybe<Array<MarkShopOrderAsPickedError>>;
  shopOrder?: Maybe<ShopOrder>;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Amount'];
  currency: CurrencyCode;
};

export type MoneyFilterInput = {
  amount?: InputMaybe<ComparableInt32OperationFilterInput>;
  and?: InputMaybe<Array<MoneyFilterInput>>;
  currency?: InputMaybe<CurrencyCodeOperationFilterInput>;
  or?: InputMaybe<Array<MoneyFilterInput>>;
};

export type MoneyInput = {
  amount: Scalars['Amount'];
  currency: CurrencyCode;
};

export type MoneySortInput = {
  amount?: InputMaybe<OrderDirection>;
  currency?: InputMaybe<OrderDirection>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptAffiliateInvite: AcceptAffiliateInvitePayload;
  addCartItemCustomization: AddCartItemCustomizationPayload;
  addLocationInventory: AddLocationInventoryPayload;
  addOptionValue: AddOptionValuePayload;
  addOrUpdateUserShoppingCategories: AddOrUpdateUserShoppingCategoriesPayload;
  addOrUpdateUserShoppingSubcategories: AddOrUpdateUserShoppingSubcategoriesPayload;
  addProductImages: AddProductImagesPayload;
  addShopImages: AddShopImagesPayload;
  addShowImage: AddShowImagePayload;
  addUserImages: AddUserImagesPayload;
  addVariantCardsToShow: AddVariantCardsToShowPayload;
  addVariantCardToShow: AddVariantCardToShowPayload;
  addVariantImages: AddVariantImagesPayload;
  addVariantOptionValue: AddVariantOptionValuePayload;
  addVariantToCart: AddVariantToCartPayload;
  addVariantToCartPOS: AddVariantToCartPosPayload;
  approveShopConnectionRequest: ApproveShopConnectionRequestPayload;
  archiveProduct: ArchiveProductPayload;
  archiveProductVariant: ArchiveProductVariantPayload;
  cancelCardReaderAction: CancelCardReaderActionPayload;
  cancelOrder: CancelOrderPayload;
  cancelOrderLineItems: CancelOrderLineItemsPayload;
  cancelShow: CancelShowPayload;
  captureOrUpdateUserDemographic: CaptureOrUpdateUserDemographicPayload;
  collectCashPaymentForOrder: CollectCashPaymentForOrderPayload;
  connectFbBusinessAccount: ConnectFbBusinessAccountPayload;
  connectToHost: ConnectToHostPayload;
  connectToShop: ConnectToShopPayload;
  createCheckoutFromCart: CreateCheckoutFromCartPayload;
  createCheckoutFromCartPos: CreateCheckoutFromCartPosPayload;
  createCustomerAddress: CreateCustomerAddressPayload;
  createCustomerAddressPos: CreateCustomerAddressPosPayload;
  createCustomerCard: CreateCustomerCardPayload;
  createCustomPackage: CreateCustomPackagePayload;
  createExternalAccount: CreateExternalAccountPayload;
  createExternalAccountWithDetails: CreateExternalAccountWithDetailsPayload;
  createImageUploads: CreateImageUploadsPayload;
  createInventoryItem: CreateInventoryItemPayload;
  createLinkToken: CreateLinkTokenPayload;
  createOption: CreateOptionPayload;
  createOrderFromCheckout: CreateOrderFromCheckoutPayload;
  createOrderFromCheckoutPos: CreateOrderFromCheckoutPosPayload;
  createOrGetGuestCustomer: CreateOrGetGuestCustomerPayload;
  createPackage: CreatePackagePayload;
  createPaymentAccount: CreatePaymentAccountPayload;
  createPaymentAccountOnboardingLink: CreatePaymentAccountOnboardingLinkPayload;
  createPaymentAccountUpdateLink: CreatePaymentAccountUpdateLinkPayload;
  createProduct: CreateProductPayload;
  createProductCollection: CreateProductCollectionPayload;
  createProfileImageUploads: CreateProfileImageUploadsPayload;
  createShop: CreateShopPayload;
  createShopCustomization: CreateShopCustomizationPayload;
  createShow: CreateShowPayload;
  createStream: CreateStreamPayload;
  createStripeBankAccountToken: CreateStripeBankAccountTokenPayload;
  createVariant: CreateVariantPayload;
  declineAffiliateInvite: DeclineAffiliateInvitePayload;
  deleteCheckout: DeleteCheckoutPayload;
  deleteCustomerAddress: DeleteCustomerAddressPayload;
  deleteCustomerPaymentMethod: DeleteCustomerPaymentMethodPayload;
  deleteExternalAccount: DeleteExternalAccountPayload;
  deleteInventoryItem: DeleteInventoryItemPayload;
  deleteOption: DeleteOptionPayload;
  deletePackage: DeletePackagePayload;
  deleteProduct: DeleteProductPayload;
  deleteProductCollection: DeleteProductCollectionPayload;
  deleteProductImage: DeleteProductImagePayload;
  deleteVariant: DeleteVariantPayload;
  deleteVariantImage: DeleteVariantImagePayload;
  disableFbComments: DisableFbCommentsPayload;
  disconnectFbBusinessAccount: DisconnectFbBusinessAccountPayload;
  enableFbComments: EnableFbCommentsPayload;
  exchangePublicToken: ExchangePublicTokenPayload;
  fulfillOrderShipmentLineItem: FulfillOrderShipmentLineItemPayload;
  hidePackageTemplate: HidePackageTemplatePayload;
  inviteShowAffiliate: InviteShowAffiliatePayload;
  linkFbPageUser: LinkFbPageUserPayload;
  markShopOrderAsFulfilled: MarkShopOrderAsFulfilledPayload;
  markShopOrderAsPicked: MarkShopOrderAsPickedPayload;
  packItems: PackItemsPayload;
  printPackagePackingSlip: PrintPackagePackingSlipPayload;
  printPackingSlip: PrintPackingSlipPayload;
  processCardReaderPaymentIntent: ProcessCardReaderPaymentIntentPayload;
  publishProduct: PublishProductPayload;
  purchaseShippingLabel: PurchaseShippingLabelPayload;
  reconnectFbBusinessAccount: ReconnectFbBusinessAccountPayload;
  releaseInventory: ReleaseInventoryPayload;
  removeItemFromCart: RemoveItemFromCartPayload;
  removeItemFromCartPos: RemoveItemFromCartPosPayload;
  removeLocationInventory: RemoveLocationInventoryPayload;
  removeOptionValue: RemoveOptionValuePayload;
  removeVariantOptionValue: RemoveVariantOptionValuePayload;
  requestEmailVerification: RequestEmailVerificationPayload;
  reserveInventory: ReserveInventoryPayload;
  selectCheckoutShipmentShippingRate: SelectCheckoutShipmentShippingRatePayload;
  sendConfirmationEmailToCustomerPOS: SendConfirmationEmailToCustomerPosPayload;
  sendVerificationCode: SendVerificationCodePayload;
  setupShop: SetupShopPayload;
  shipmentRates: ShipmentRatesPayload;
  unarchiveProduct: UnarchiveProductPayload;
  unfulfillOrderShipmentLineItem: UnfulfillOrderShipmentLineItemPayload;
  unHidePackageTemplate: UnHidePackageTemplatePayload;
  unpublishProduct: UnpublishProductPayload;
  updateCartItem: UpdateCartItemPayload;
  updateCartItemCustomization: UpdateCartItemCustomizationPayload;
  updateCartItemPos: UpdateCartItemPosPayload;
  updateCheckout: UpdateCheckoutPayload;
  updateCheckoutPos: UpdateCheckoutPosPayload;
  updateCustomer: UpdateCustomerPayload;
  updateCustomerAddress: UpdateCustomerAddressPayload;
  updateEmailAddress: UpdateEmailAddressPayload;
  updateExternalAccount: UpdateExternalAccountPayload;
  updateImage: UpdateImagePayload;
  updateInventoryItem: UpdateInventoryItemPayload;
  updateLocationInventory: UpdateLocationInventoryPayload;
  updateOption: UpdateOptionPayload;
  updateOrderAddress: UpdateOrderAddressPayload;
  updateOrderPaymentMethod: UpdateOrderPaymentMethodPayload;
  updatePackage: UpdatePackagePayload;
  updatePassword: UpdatePasswordPayload;
  updatePayment: UpdatePaymentPayload;
  updatePhone: UpdatePhonePayload;
  updateProduct: UpdateProductPayload;
  updateProductCollection: UpdateProductCollectionPayload;
  updateReservation: UpdateReservationPayload;
  updateReservationQuantity: UpdateReservationQuantityPayload;
  updateShop: UpdateShopPayload;
  updateShopCategories: UpdateShopCategoriesPayload;
  updateShopSubcategories: UpdateShopSubcategoriesPayload;
  updateStream: UpdateStreamPayload;
  updateUserLiveExperience: UpdateUserLiveExperiencePayload;
  updateUserNames: UpdateUserNamesPayload;
  updateUserRole: UpdateUserRolePayload;
  updateVariant: UpdateVariantPayload;
  verifyCode: VerifyCodePayload;
  verifyUserLogin: VerifyUserLoginPayload;
};

export type MutationAcceptAffiliateInviteArgs = {
  input: AcceptAffiliateInviteInput;
};

export type MutationAddCartItemCustomizationArgs = {
  input: AddCartItemCustomizationInput;
};

export type MutationAddLocationInventoryArgs = {
  input: AddLocationInventoryInput;
};

export type MutationAddOptionValueArgs = {
  input: AddOptionValueInput;
};

export type MutationAddOrUpdateUserShoppingCategoriesArgs = {
  input: AddOrUpdateUserShoppingCategoriesInput;
};

export type MutationAddOrUpdateUserShoppingSubcategoriesArgs = {
  input: AddOrUpdateUserShoppingSubcategoriesInput;
};

export type MutationAddProductImagesArgs = {
  input: AddProductImagesInput;
};

export type MutationAddShopImagesArgs = {
  input: AddShopImagesInput;
};

export type MutationAddShowImageArgs = {
  input: AddShowImageInput;
};

export type MutationAddUserImagesArgs = {
  input: AddUserImagesInput;
};

export type MutationAddVariantCardsToShowArgs = {
  input: AddVariantCardsToShowInput;
};

export type MutationAddVariantCardToShowArgs = {
  input: AddVariantCardToShowInput;
};

export type MutationAddVariantImagesArgs = {
  input: AddVariantImagesInput;
};

export type MutationAddVariantOptionValueArgs = {
  input: AddVariantOptionValueInput;
};

export type MutationAddVariantToCartArgs = {
  input: AddVariantToCartInput;
};

export type MutationAddVariantToCartPosArgs = {
  input: AddVariantToCartPosInput;
};

export type MutationApproveShopConnectionRequestArgs = {
  input: ApproveShopConnectionRequestInput;
};

export type MutationArchiveProductArgs = {
  input: ArchiveProductInput;
};

export type MutationArchiveProductVariantArgs = {
  input: ArchiveProductVariantInput;
};

export type MutationCancelCardReaderActionArgs = {
  input: CancelCardReaderActionInput;
};

export type MutationCancelOrderArgs = {
  input: CancelOrderInput;
};

export type MutationCancelOrderLineItemsArgs = {
  input: CancelOrderLineItemsInput;
};

export type MutationCancelShowArgs = {
  input: CancelShowInput;
};

export type MutationCaptureOrUpdateUserDemographicArgs = {
  input: CaptureOrUpdateUserDemographicInput;
};

export type MutationCollectCashPaymentForOrderArgs = {
  input: CollectCashPaymentForOrderInput;
};

export type MutationConnectFbBusinessAccountArgs = {
  input: ConnectFbBusinessAccountInput;
};

export type MutationConnectToHostArgs = {
  input: ConnectToHostInput;
};

export type MutationConnectToShopArgs = {
  input: ConnectToShopInput;
};

export type MutationCreateCheckoutFromCartArgs = {
  input: CreateCheckoutFromCartInput;
};

export type MutationCreateCheckoutFromCartPosArgs = {
  input: CreateCheckoutFromCartPosInput;
};

export type MutationCreateCustomerAddressArgs = {
  input: CreateCustomerAddressInput;
};

export type MutationCreateCustomerAddressPosArgs = {
  input: CreateCustomerAddressPosInput;
};

export type MutationCreateCustomerCardArgs = {
  input: CreateCustomerCardInput;
};

export type MutationCreateCustomPackageArgs = {
  input: CreateCustomPackageInput;
};

export type MutationCreateExternalAccountArgs = {
  input: CreateExternalAccountInput;
};

export type MutationCreateExternalAccountWithDetailsArgs = {
  input: CreateExternalAccountWithDetailsInput;
};

export type MutationCreateImageUploadsArgs = {
  input: CreateImageUploadsInput;
};

export type MutationCreateInventoryItemArgs = {
  input: CreateInventoryItemInput;
};

export type MutationCreateLinkTokenArgs = {
  input: CreateLinkTokenInput;
};

export type MutationCreateOptionArgs = {
  input: CreateOptionInput;
};

export type MutationCreateOrderFromCheckoutArgs = {
  input: CreateOrderFromCheckoutInput;
};

export type MutationCreateOrderFromCheckoutPosArgs = {
  input: CreateOrderFromCheckoutPosInput;
};

export type MutationCreateOrGetGuestCustomerArgs = {
  input: CreateOrGetGuestCustomerInput;
};

export type MutationCreatePackageArgs = {
  input: CreatePackageInput;
};

export type MutationCreatePaymentAccountArgs = {
  input: CreatePaymentAccountInput;
};

export type MutationCreatePaymentAccountOnboardingLinkArgs = {
  input: CreatePaymentAccountOnboardingLinkInput;
};

export type MutationCreatePaymentAccountUpdateLinkArgs = {
  input: CreatePaymentAccountUpdateLinkInput;
};

export type MutationCreateProductArgs = {
  input: CreateProductInput;
};

export type MutationCreateProductCollectionArgs = {
  input: CreateProductCollectionInput;
};

export type MutationCreateProfileImageUploadsArgs = {
  input: CreateProfileImageUploadsInput;
};

export type MutationCreateShopArgs = {
  input: CreateShopInput;
};

export type MutationCreateShopCustomizationArgs = {
  input: CreateShopCustomizationInput;
};

export type MutationCreateShowArgs = {
  input: CreateShowInput;
};

export type MutationCreateStreamArgs = {
  input: CreateStreamInput;
};

export type MutationCreateStripeBankAccountTokenArgs = {
  input: CreateStripeBankAccountTokenInput;
};

export type MutationCreateVariantArgs = {
  input: CreateVariantInput;
};

export type MutationDeclineAffiliateInviteArgs = {
  input: DeclineAffiliateInviteInput;
};

export type MutationDeleteCheckoutArgs = {
  input: DeleteCheckoutInput;
};

export type MutationDeleteCustomerAddressArgs = {
  input: DeleteCustomerAddressInput;
};

export type MutationDeleteCustomerPaymentMethodArgs = {
  input: DeleteCustomerPaymentMethodInput;
};

export type MutationDeleteExternalAccountArgs = {
  input: DeleteExternalAccountInput;
};

export type MutationDeleteInventoryItemArgs = {
  input: DeleteInventoryItemInput;
};

export type MutationDeleteOptionArgs = {
  input: DeleteOptionInput;
};

export type MutationDeletePackageArgs = {
  input: DeletePackageInput;
};

export type MutationDeleteProductArgs = {
  input: DeleteProductInput;
};

export type MutationDeleteProductCollectionArgs = {
  input: DeleteProductCollectionInput;
};

export type MutationDeleteProductImageArgs = {
  input: DeleteProductImageInput;
};

export type MutationDeleteVariantArgs = {
  input: DeleteVariantInput;
};

export type MutationDeleteVariantImageArgs = {
  input: DeleteVariantImageInput;
};

export type MutationDisableFbCommentsArgs = {
  input: DisableFbCommentsInput;
};

export type MutationDisconnectFbBusinessAccountArgs = {
  input: DisconnectFbBusinessAccountInput;
};

export type MutationEnableFbCommentsArgs = {
  input: EnableFbCommentsInput;
};

export type MutationExchangePublicTokenArgs = {
  input: ExchangePublicTokenInput;
};

export type MutationFulfillOrderShipmentLineItemArgs = {
  input: FulfillOrderShipmentLineItemInput;
};

export type MutationHidePackageTemplateArgs = {
  input: HidePackageTemplateInput;
};

export type MutationInviteShowAffiliateArgs = {
  input: InviteShowAffiliateInput;
};

export type MutationLinkFbPageUserArgs = {
  input: LinkFbPageUserInput;
};

export type MutationMarkShopOrderAsFulfilledArgs = {
  input: MarkShopOrderAsFulfilledInput;
};

export type MutationMarkShopOrderAsPickedArgs = {
  input: MarkShopOrderAsPickedInput;
};

export type MutationPackItemsArgs = {
  input: PackItemsInput;
};

export type MutationPrintPackagePackingSlipArgs = {
  input: PrintPackagePackingSlipInput;
};

export type MutationPrintPackingSlipArgs = {
  input: PrintPackingSlipInput;
};

export type MutationProcessCardReaderPaymentIntentArgs = {
  input: ProcessCardReaderPaymentIntentInput;
};

export type MutationPublishProductArgs = {
  input: PublishProductInput;
};

export type MutationPurchaseShippingLabelArgs = {
  input: PurchaseShippingLabelInput;
};

export type MutationReconnectFbBusinessAccountArgs = {
  input: ReconnectFbBusinessAccountInput;
};

export type MutationReleaseInventoryArgs = {
  input: ReleaseInventoryInput;
};

export type MutationRemoveItemFromCartArgs = {
  input: RemoveItemFromCartInput;
};

export type MutationRemoveItemFromCartPosArgs = {
  input: RemoveItemFromCartPosInput;
};

export type MutationRemoveLocationInventoryArgs = {
  input: RemoveLocationInventoryInput;
};

export type MutationRemoveOptionValueArgs = {
  input: RemoveOptionValueInput;
};

export type MutationRemoveVariantOptionValueArgs = {
  input: RemoveVariantOptionValueInput;
};

export type MutationRequestEmailVerificationArgs = {
  input: RequestEmailVerificationInput;
};

export type MutationReserveInventoryArgs = {
  input: ReserveInventoryInput;
};

export type MutationSelectCheckoutShipmentShippingRateArgs = {
  input: SelectCheckoutShipmentShippingRateInput;
};

export type MutationSendConfirmationEmailToCustomerPosArgs = {
  input: SendConfirmationEmailToCustomerPosInput;
};

export type MutationSendVerificationCodeArgs = {
  input: SendVerificationCodeInput;
};

export type MutationSetupShopArgs = {
  input: SetupShopInput;
};

export type MutationShipmentRatesArgs = {
  input: ShipmentRatesInput;
};

export type MutationUnarchiveProductArgs = {
  input: UnarchiveProductInput;
};

export type MutationUnfulfillOrderShipmentLineItemArgs = {
  input: UnfulfillOrderShipmentLineItemInput;
};

export type MutationUnHidePackageTemplateArgs = {
  input: UnHidePackageTemplateInput;
};

export type MutationUnpublishProductArgs = {
  input: UnpublishProductInput;
};

export type MutationUpdateCartItemArgs = {
  input: UpdateCartItemInput;
};

export type MutationUpdateCartItemCustomizationArgs = {
  input: UpdateCartItemCustomizationInput;
};

export type MutationUpdateCartItemPosArgs = {
  input: UpdateCartItemPosInput;
};

export type MutationUpdateCheckoutArgs = {
  input: UpdateCheckoutInput;
};

export type MutationUpdateCheckoutPosArgs = {
  input: UpdateCheckoutPosInput;
};

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationUpdateCustomerAddressArgs = {
  input: UpdateCustomerAddressInput;
};

export type MutationUpdateEmailAddressArgs = {
  input: UpdateEmailAddressInput;
};

export type MutationUpdateExternalAccountArgs = {
  input: UpdateExternalAccountInput;
};

export type MutationUpdateImageArgs = {
  input: UpdateImageInput;
};

export type MutationUpdateInventoryItemArgs = {
  input: UpdateInventoryItemInput;
};

export type MutationUpdateLocationInventoryArgs = {
  input: UpdateLocationInventoryInput;
};

export type MutationUpdateOptionArgs = {
  input: UpdateOptionInput;
};

export type MutationUpdateOrderAddressArgs = {
  input: UpdateOrderAddressInput;
};

export type MutationUpdateOrderPaymentMethodArgs = {
  input: UpdateOrderPaymentMethodInput;
};

export type MutationUpdatePackageArgs = {
  input: UpdatePackageInput;
};

export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};

export type MutationUpdatePaymentArgs = {
  input: UpdatePaymentInput;
};

export type MutationUpdatePhoneArgs = {
  input: UpdatePhoneInput;
};

export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};

export type MutationUpdateProductCollectionArgs = {
  input: UpdateProductCollectionInput;
};

export type MutationUpdateReservationArgs = {
  input: UpdateReservationInput;
};

export type MutationUpdateReservationQuantityArgs = {
  input: UpdateReservationQuantityInput;
};

export type MutationUpdateShopArgs = {
  input: UpdateShopInput;
};

export type MutationUpdateShopCategoriesArgs = {
  input: UpdateShopCategoriesInput;
};

export type MutationUpdateShopSubcategoriesArgs = {
  input: UpdateShopSubcategoriesInput;
};

export type MutationUpdateStreamArgs = {
  input: UpdateStreamInput;
};

export type MutationUpdateUserLiveExperienceArgs = {
  input: UpdateUserLiveExperienceInput;
};

export type MutationUpdateUserNamesArgs = {
  input: UpdateUserNamesInput;
};

export type MutationUpdateUserRoleArgs = {
  input: UpdateUserRoleInput;
};

export type MutationUpdateVariantArgs = {
  input: UpdateVariantInput;
};

export type MutationVerifyCodeArgs = {
  input: VerifyCodeInput;
};

export type MutationVerifyUserLoginArgs = {
  input: VerifyUserLoginInput;
};

/** The node interface is implemented by entities that have a global unique identifier. */
export type Node = {
  id: Scalars['ID'];
};

export type NoItemsError = Error & {
  __typename?: 'NoItemsError';
  checkoutId: Scalars['ID'];
  message: Scalars['String'];
};

export type NpgsqlTsVectorFilterInput = {
  and?: InputMaybe<Array<NpgsqlTsVectorFilterInput>>;
  count?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<NpgsqlTsVectorFilterInput>>;
};

export type NpgsqlTsVectorSortInput = {
  count?: InputMaybe<OrderDirection>;
};

export type NullableOfCardDeclineCodeOperationFilterInput = {
  eq?: InputMaybe<CardDeclineCode>;
  in?: InputMaybe<Array<InputMaybe<CardDeclineCode>>>;
  neq?: InputMaybe<CardDeclineCode>;
  nin?: InputMaybe<Array<InputMaybe<CardDeclineCode>>>;
};

export type NullableOfCountryCodeOperationFilterInput = {
  eq?: InputMaybe<CountryCode>;
  in?: InputMaybe<Array<InputMaybe<CountryCode>>>;
  neq?: InputMaybe<CountryCode>;
  nin?: InputMaybe<Array<InputMaybe<CountryCode>>>;
};

export type NullableOfLiveExperienceTypeOperationFilterInput = {
  eq?: InputMaybe<LiveExperienceType>;
  in?: InputMaybe<Array<InputMaybe<LiveExperienceType>>>;
  neq?: InputMaybe<LiveExperienceType>;
  nin?: InputMaybe<Array<InputMaybe<LiveExperienceType>>>;
};

export type NullableOfPaymentErrorCodeOperationFilterInput = {
  eq?: InputMaybe<PaymentErrorCode>;
  in?: InputMaybe<Array<InputMaybe<PaymentErrorCode>>>;
  neq?: InputMaybe<PaymentErrorCode>;
  nin?: InputMaybe<Array<InputMaybe<PaymentErrorCode>>>;
};

export type NullableOfRefundReasonOperationFilterInput = {
  eq?: InputMaybe<RefundReason>;
  in?: InputMaybe<Array<InputMaybe<RefundReason>>>;
  neq?: InputMaybe<RefundReason>;
  nin?: InputMaybe<Array<InputMaybe<RefundReason>>>;
};

export type NullableOfStateProvinceCodeOperationFilterInput = {
  eq?: InputMaybe<StateProvinceCode>;
  in?: InputMaybe<Array<InputMaybe<StateProvinceCode>>>;
  neq?: InputMaybe<StateProvinceCode>;
  nin?: InputMaybe<Array<InputMaybe<StateProvinceCode>>>;
};

export type NullableOfUserImageTypeOperationFilterInput = {
  eq?: InputMaybe<UserImageType>;
  in?: InputMaybe<Array<InputMaybe<UserImageType>>>;
  neq?: InputMaybe<UserImageType>;
  nin?: InputMaybe<Array<InputMaybe<UserImageType>>>;
};

export enum OgcGeometryType {
  CIRCULAR_STRING = 'CIRCULAR_STRING',
  COMPOUND_CURVE = 'COMPOUND_CURVE',
  CURVE = 'CURVE',
  CURVE_POLYGON = 'CURVE_POLYGON',
  GEOMETRY_COLLECTION = 'GEOMETRY_COLLECTION',
  LINE_STRING = 'LINE_STRING',
  MULTI_CURVE = 'MULTI_CURVE',
  MULTI_LINE_STRING = 'MULTI_LINE_STRING',
  MULTI_POINT = 'MULTI_POINT',
  MULTI_POLYGON = 'MULTI_POLYGON',
  MULTI_SURFACE = 'MULTI_SURFACE',
  POINT = 'POINT',
  POLYGON = 'POLYGON',
  POLYHEDRAL_SURFACE = 'POLYHEDRAL_SURFACE',
  SURFACE = 'SURFACE',
  TIN = 'TIN'
}

export type OgcGeometryTypeOperationFilterInput = {
  eq?: InputMaybe<OgcGeometryType>;
  in?: InputMaybe<Array<OgcGeometryType>>;
  neq?: InputMaybe<OgcGeometryType>;
  nin?: InputMaybe<Array<OgcGeometryType>>;
};

export type Option = {
  id: Scalars['ID'];
  name: Scalars['String'];
  optionValues?: Maybe<OptionValuesConnection>;
  order: Scalars['Int'];
  product: Product;
  productId: Scalars['ID'];
  required: Scalars['Boolean'];
};

export type OptionOptionValuesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type OptionFilterInput = {
  and?: InputMaybe<Array<OptionFilterInput>>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  optionValues?: InputMaybe<ListFilterInputTypeOfOptionValueFilterInput>;
  or?: InputMaybe<Array<OptionFilterInput>>;
  order?: InputMaybe<ComparableInt32OperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<ComparableGuidOperationFilterInput>;
  required?: InputMaybe<BooleanOperationFilterInput>;
};

export type OptionInput = {
  customizationOption?: InputMaybe<CustomizationOptionInput>;
  selectOption?: InputMaybe<SelectOptionInput>;
};

export type OptionNotFoundError = Error & {
  __typename?: 'OptionNotFoundError';
  message: Scalars['String'];
};

/** A connection to a list of items. */
export type OptionsConnection = {
  __typename?: 'OptionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OptionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Option>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OptionsEdge = {
  __typename?: 'OptionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Option>;
};

export type OptionValue = {
  id: Scalars['ID'];
  label: Scalars['String'];
  option: Option;
  optionId: Scalars['ID'];
  order: Scalars['Int'];
  variants?: Maybe<VariantsConnection>;
};

export type OptionValueVariantsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type OptionValueFilterInput = {
  and?: InputMaybe<Array<OptionValueFilterInput>>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  label?: InputMaybe<StringOperationFilterInput>;
  option?: InputMaybe<OptionFilterInput>;
  optionId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<OptionValueFilterInput>>;
  order?: InputMaybe<ComparableInt32OperationFilterInput>;
  variantOptionValues?: InputMaybe<ListFilterInputTypeOfVariantOptionValueFilterInput>;
  variants?: InputMaybe<ListFilterInputTypeOfVariantFilterInput>;
};

export type OptionValueInput = {
  colorOptionValue?: InputMaybe<ColorOptionValueInput>;
  textOptionValue?: InputMaybe<TextOptionValueInput>;
};

/** A connection to a list of items. */
export type OptionValuesConnection = {
  __typename?: 'OptionValuesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OptionValuesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<OptionValue>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OptionValuesEdge = {
  __typename?: 'OptionValuesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<OptionValue>;
};

export type Order = Node & {
  __typename?: 'Order';
  billingAddress: Address;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  closed: Scalars['Boolean'];
  closedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  currency: CurrencyCode;
  currentSalesTaxTotal: Money;
  currentSubtotal: Money;
  currentTotal: Money;
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  orderFulfilmentlineItems?: Maybe<OrderFulfilmentlineItemsConnection>;
  orderLineItems?: Maybe<OrderLineItemsConnection>;
  orderNumber: Scalars['Long'];
  orderShipments?: Maybe<OrderShipmentsConnection>;
  paid: Scalars['Boolean'];
  payments?: Maybe<PaymentsConnection>;
  phone?: Maybe<Scalars['String']>;
  refundedTotal: Money;
  salesTaxTotal: Money;
  shippingAddress: Address;
  shippingTotal: Money;
  shopOrders?: Maybe<ShopOrdersConnection>;
  subtotal: Money;
  total: Money;
  updatedAt: Scalars['DateTime'];
};

export type OrderOrderFulfilmentlineItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type OrderOrderLineItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type OrderOrderShipmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type OrderPaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type OrderShopOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShopOrderFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShopOrderSortInput>>;
};

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type OrderFilterInput = {
  and?: InputMaybe<Array<OrderFilterInput>>;
  cancelledAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  closed?: InputMaybe<BooleanOperationFilterInput>;
  closedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrderFilterInput>>;
  paid?: InputMaybe<BooleanOperationFilterInput>;
  phone?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

export type OrderFulfilmentLineItem = Node & {
  __typename?: 'OrderFulfilmentLineItem';
  clone: OrderFulfilmentLineItem;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  order: Order;
  orderId: Scalars['ID'];
  orderLineItem: OrderLineItem;
  orderLineItemId: Scalars['ID'];
  orderPackage?: Maybe<OrderPackage>;
  orderPackageId?: Maybe<Scalars['UUID']>;
  orderShipmentLineItem?: Maybe<OrderShipmentLineItem>;
  orderShipmentLineItemId?: Maybe<Scalars['ID']>;
  quantity: Scalars['Int'];
  shopOrder: ShopOrder;
  shopOrderId: Scalars['ID'];
  status: FulfilmentStatus;
  updatedAt: Scalars['DateTime'];
};

export type OrderFulfilmentLineItemCloneArgs = {
  newQuantity: Scalars['Int'];
};

/** A connection to a list of items. */
export type OrderFulfilmentlineItemsConnection = {
  __typename?: 'OrderFulfilmentlineItemsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrderFulfilmentlineItemsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OrderFulfilmentLineItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrderFulfilmentlineItemsEdge = {
  __typename?: 'OrderFulfilmentlineItemsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: OrderFulfilmentLineItem;
};

export type OrderLineItem = Node & {
  __typename?: 'OrderLineItem';
  createdAt: Scalars['DateTime'];
  currency: CurrencyCode;
  currentQuantity: Scalars['Int'];
  customizations?: Maybe<OrderLineItemCustomizationsConnectionConnection>;
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Order;
  orderId: Scalars['ID'];
  orderShipmentLineItems?: Maybe<OrderShipmentLineItemsConnection>;
  quantity: Scalars['Int'];
  reservationId: Scalars['UUID'];
  shopOrder: ShopOrder;
  shopOrderId: Scalars['ID'];
  sku?: Maybe<Scalars['String']>;
  status: OrderLineItemStatus;
  unitPrice: Money;
  updatedAt: Scalars['DateTime'];
  variant: Variant;
  variantId: Scalars['ID'];
};

export type OrderLineItemCustomizationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type OrderLineItemOrderShipmentLineItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type OrderLineItemCustomization = {
  __typename?: 'OrderLineItemCustomization';
  customizationOption: CustomizationOption;
  customizationOptionId: Scalars['ID'];
  index: Scalars['Int'];
  orderLineItem: OrderLineItem;
  orderLineItemId: Scalars['ID'];
  value: Scalars['String'];
};

/** A connection to a list of items. */
export type OrderLineItemCustomizationsConnection = {
  __typename?: 'OrderLineItemCustomizationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrderLineItemCustomizationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OrderLineItemCustomization>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** A connection to a list of items. */
export type OrderLineItemCustomizationsConnectionConnection = {
  __typename?: 'OrderLineItemCustomizationsConnectionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrderLineItemCustomizationsConnectionEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OrderLineItemCustomization>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrderLineItemCustomizationsConnectionEdge = {
  __typename?: 'OrderLineItemCustomizationsConnectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: OrderLineItemCustomization;
};

/** An edge in a connection. */
export type OrderLineItemCustomizationsEdge = {
  __typename?: 'OrderLineItemCustomizationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: OrderLineItemCustomization;
};

export type OrderLineItemQuantitiesInput = {
  orderLineItemId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type OrderLineItemQuantityInput = {
  orderLineItemId: Scalars['ID'];
  quantity: Scalars['Int'];
};

/** A connection to a list of items. */
export type OrderLineItemsConnection = {
  __typename?: 'OrderLineItemsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrderLineItemsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OrderLineItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrderLineItemsEdge = {
  __typename?: 'OrderLineItemsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: OrderLineItem;
};

export enum OrderLineItemStatus {
  FULFILED = 'FULFILED',
  NOT_STARTED = 'NOT_STARTED',
  PACKED = 'PACKED',
  PICKED = 'PICKED'
}

export type OrderPackage = Node & {
  __typename?: 'OrderPackage';
  createdAt: Scalars['DateTime'];
  currency: CurrencyCode;
  deliveryDays?: Maybe<Scalars['Int']>;
  dimensions?: Maybe<Dimensions>;
  estimatedDeliveryDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isCustomPackage: Scalars['Boolean'];
  orderFulfilmentLineItems: Array<OrderFulfilmentLineItem>;
  orderId: Scalars['UUID'];
  orderShipmentId: Scalars['UUID'];
  packingSlipUrl?: Maybe<Scalars['String']>;
  packingSlipUrlExpiry?: Maybe<Scalars['DateTime']>;
  payoutDifference: Scalars['Int'];
  payoutDifferenceMoney: Money;
  primaryWeight?: Maybe<Weight>;
  secondaryWeight?: Maybe<Weight>;
  shipmentTrackingId?: Maybe<Scalars['String']>;
  shippingCarrierPackage?: Maybe<ShippingCarrierPackage>;
  shippingLabelPriceMoney: Money;
  shippingLabelUrl?: Maybe<Scalars['URL']>;
  shippingProvider: ShippingProvider;
  shippingProviderRateExternalId?: Maybe<Scalars['String']>;
  shippingProviderRefundExternalId?: Maybe<Scalars['String']>;
  shippingProviderTrackingUrl?: Maybe<Scalars['String']>;
  shippingProviderTransactionExternalId?: Maybe<Scalars['String']>;
  shippingUserPackage?: Maybe<ShippingUserPackage>;
  shippingUserPackageId?: Maybe<Scalars['UUID']>;
  shippoOrderId?: Maybe<Scalars['String']>;
  shopOrderId: Scalars['UUID'];
  status: PackageStatus;
  totalWeight?: Maybe<Weight>;
};

export type OrderPackageRatesInput = {
  packageId: Scalars['ID'];
  rateId: Scalars['String'];
};

/** A connection to a list of items. */
export type OrderPackagesConnection = {
  __typename?: 'OrderPackagesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrderPackagesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OrderPackage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrderPackagesEdge = {
  __typename?: 'OrderPackagesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: OrderPackage;
};

export type OrderPackageSortInput = {
  createdAt?: InputMaybe<OrderDirection>;
  currency?: InputMaybe<OrderDirection>;
  deliveryDays?: InputMaybe<OrderDirection>;
  dimensions?: InputMaybe<DimensionsSortInput>;
  estimatedDeliveryDate?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  isCustomPackage?: InputMaybe<OrderDirection>;
  order?: InputMaybe<OrderSortInput>;
  orderId?: InputMaybe<OrderDirection>;
  orderShipment?: InputMaybe<OrderShipmentSortInput>;
  orderShipmentId?: InputMaybe<OrderDirection>;
  packingSlipUrl?: InputMaybe<OrderDirection>;
  packingSlipUrlExpiry?: InputMaybe<OrderDirection>;
  payoutDifference?: InputMaybe<OrderDirection>;
  payoutDifferenceMoney?: InputMaybe<MoneySortInput>;
  primaryWeight?: InputMaybe<WeightSortInput>;
  secondaryWeight?: InputMaybe<WeightSortInput>;
  shipmentTrackingId?: InputMaybe<OrderDirection>;
  shippingCarrierPackage?: InputMaybe<ShippingCarrierPackageSortInput>;
  shippingCarrierPackageId?: InputMaybe<OrderDirection>;
  shippingCarrierService?: InputMaybe<ShippingCarrierServiceSortInput>;
  shippingCarrierServiceId?: InputMaybe<OrderDirection>;
  shippingLabelPrice?: InputMaybe<OrderDirection>;
  shippingLabelPriceMoney?: InputMaybe<MoneySortInput>;
  shippingLabelUrl?: InputMaybe<UriSortInput>;
  shippingPrice?: InputMaybe<OrderDirection>;
  shippingPriceMoney?: InputMaybe<MoneySortInput>;
  shippingProvider?: InputMaybe<OrderDirection>;
  shippingProviderRateExternalId?: InputMaybe<OrderDirection>;
  shippingProviderRefundExternalId?: InputMaybe<OrderDirection>;
  shippingProviderShipmentExternalId?: InputMaybe<OrderDirection>;
  shippingProviderTrackingUrl?: InputMaybe<OrderDirection>;
  shippingProviderTransactionExternalId?: InputMaybe<OrderDirection>;
  shippingUserPackage?: InputMaybe<ShippingUserPackageSortInput>;
  shippingUserPackageId?: InputMaybe<OrderDirection>;
  shippoOrderId?: InputMaybe<OrderDirection>;
  shopOrder?: InputMaybe<ShopOrderSortInput>;
  shopOrderId?: InputMaybe<OrderDirection>;
  status?: InputMaybe<OrderDirection>;
  totalWeight?: InputMaybe<WeightSortInput>;
};

export type OrderPaymentFilterInput = {
  and?: InputMaybe<Array<OrderPaymentFilterInput>>;
  or?: InputMaybe<Array<OrderPaymentFilterInput>>;
  order?: InputMaybe<OrderFilterInput>;
  orderId?: InputMaybe<ComparableGuidOperationFilterInput>;
  payment?: InputMaybe<PaymentFilterInput>;
  paymentId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

/** A connection to a list of items. */
export type OrdersByCustomerConnection = {
  __typename?: 'OrdersByCustomerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrdersByCustomerEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Order>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrdersByCustomerEdge = {
  __typename?: 'OrdersByCustomerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Order;
};

/** A connection to a list of items. */
export type OrdersConnection = {
  __typename?: 'OrdersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrdersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Refund>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrdersEdge = {
  __typename?: 'OrdersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Refund>;
};

export type OrderShipment = Node & {
  __typename?: 'OrderShipment';
  currency: CurrencyCode;
  deliveryDays?: Maybe<Scalars['Int']>;
  destinationAddress: Address;
  estimatedDeliveryDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  order: Order;
  orderId: Scalars['ID'];
  orderShipmentLineItems?: Maybe<OrderShipmentLineItemsConnection>;
  shippingMethod?: Maybe<ShippingMethod>;
  shippingMethodId?: Maybe<Scalars['ID']>;
  shippingPrice: Money;
  shippingTax: Money;
  shopOrder: ShopOrder;
  shopOrderId: Scalars['ID'];
  sourceAddress: Address;
  sourceShopLocation?: Maybe<ShopLocation>;
  sourceShopLocationId?: Maybe<Scalars['ID']>;
  taxCollected: Money;
};

export type OrderShipmentOrderShipmentLineItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type OrderShipmentLineItem = Node & {
  __typename?: 'OrderShipmentLineItem';
  currency: CurrencyCode;
  fulfilledQuantity: Scalars['Int'];
  id: Scalars['ID'];
  orderLineItem: OrderLineItem;
  orderLineItemId: Scalars['ID'];
  orderShipment: OrderShipment;
  orderShipmentId: Scalars['ID'];
  taxCollected: Money;
  totalQuantity: Scalars['Int'];
};

/** A connection to a list of items. */
export type OrderShipmentLineItemsConnection = {
  __typename?: 'OrderShipmentLineItemsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrderShipmentLineItemsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OrderShipmentLineItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrderShipmentLineItemsEdge = {
  __typename?: 'OrderShipmentLineItemsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: OrderShipmentLineItem;
};

/** A connection to a list of items. */
export type OrderShipmentsByOrderConnection = {
  __typename?: 'OrderShipmentsByOrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrderShipmentsByOrderEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OrderShipment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrderShipmentsByOrderEdge = {
  __typename?: 'OrderShipmentsByOrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: OrderShipment;
};

/** A connection to a list of items. */
export type OrderShipmentsByShopOrderConnection = {
  __typename?: 'OrderShipmentsByShopOrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrderShipmentsByShopOrderEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OrderShipment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrderShipmentsByShopOrderEdge = {
  __typename?: 'OrderShipmentsByShopOrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: OrderShipment;
};

/** A connection to a list of items. */
export type OrderShipmentsConnection = {
  __typename?: 'OrderShipmentsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrderShipmentsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OrderShipment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrderShipmentsEdge = {
  __typename?: 'OrderShipmentsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: OrderShipment;
};

export type OrderShipmentSortInput = {
  currency?: InputMaybe<OrderDirection>;
  deliveryDays?: InputMaybe<OrderDirection>;
  destinationAddress?: InputMaybe<AddressSortInput>;
  estimatedDeliveryDate?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  order?: InputMaybe<OrderSortInput>;
  orderId?: InputMaybe<OrderDirection>;
  shippingCarrierPackage?: InputMaybe<ShippingCarrierPackageSortInput>;
  shippingCarrierPackageId?: InputMaybe<OrderDirection>;
  shippingCarrierService?: InputMaybe<ShippingCarrierServiceSortInput>;
  shippingCarrierServiceId?: InputMaybe<OrderDirection>;
  shippingMethod?: InputMaybe<ShippingMethodSortInput>;
  shippingMethodId?: InputMaybe<OrderDirection>;
  shippingPrice?: InputMaybe<OrderDirection>;
  shippingPriceMoney?: InputMaybe<MoneySortInput>;
  shippingTax?: InputMaybe<OrderDirection>;
  shippingTaxMoney?: InputMaybe<MoneySortInput>;
  shopOrder?: InputMaybe<ShopOrderSortInput>;
  shopOrderId?: InputMaybe<OrderDirection>;
  sourceAddress?: InputMaybe<AddressSortInput>;
  sourceShopLocation?: InputMaybe<ShopLocationSortInput>;
  sourceShopLocationId?: InputMaybe<OrderDirection>;
  taxCollected?: InputMaybe<OrderDirection>;
  taxCollectedMoney?: InputMaybe<MoneySortInput>;
};

export type OrderSortInput = {
  cancelledAt?: InputMaybe<OrderDirection>;
  closed?: InputMaybe<OrderDirection>;
  closedAt?: InputMaybe<OrderDirection>;
  createdAt?: InputMaybe<OrderDirection>;
  email?: InputMaybe<OrderDirection>;
  paid?: InputMaybe<OrderDirection>;
  phone?: InputMaybe<OrderDirection>;
  updatedAt?: InputMaybe<OrderDirection>;
};

export enum Ordinates {
  ALL_MEASURE_ORDINATES = 'ALL_MEASURE_ORDINATES',
  ALL_ORDINATES = 'ALL_ORDINATES',
  ALL_SPATIAL_ORDINATES = 'ALL_SPATIAL_ORDINATES',
  M = 'M',
  MEASURE10 = 'MEASURE10',
  MEASURE11 = 'MEASURE11',
  MEASURE12 = 'MEASURE12',
  MEASURE13 = 'MEASURE13',
  MEASURE14 = 'MEASURE14',
  MEASURE15 = 'MEASURE15',
  MEASURE16 = 'MEASURE16',
  MEASURE2 = 'MEASURE2',
  MEASURE3 = 'MEASURE3',
  MEASURE4 = 'MEASURE4',
  MEASURE5 = 'MEASURE5',
  MEASURE6 = 'MEASURE6',
  MEASURE7 = 'MEASURE7',
  MEASURE8 = 'MEASURE8',
  MEASURE9 = 'MEASURE9',
  NONE = 'NONE',
  SPATIAL1 = 'SPATIAL1',
  SPATIAL10 = 'SPATIAL10',
  SPATIAL11 = 'SPATIAL11',
  SPATIAL12 = 'SPATIAL12',
  SPATIAL13 = 'SPATIAL13',
  SPATIAL14 = 'SPATIAL14',
  SPATIAL15 = 'SPATIAL15',
  SPATIAL16 = 'SPATIAL16',
  SPATIAL2 = 'SPATIAL2',
  SPATIAL4 = 'SPATIAL4',
  SPATIAL5 = 'SPATIAL5',
  SPATIAL6 = 'SPATIAL6',
  SPATIAL7 = 'SPATIAL7',
  SPATIAL8 = 'SPATIAL8',
  SPATIAL9 = 'SPATIAL9',
  XY = 'XY',
  XYM = 'XYM',
  XYZ = 'XYZ',
  XYZM = 'XYZM',
  Z = 'Z'
}

export type OrdinatesOperationFilterInput = {
  eq?: InputMaybe<Ordinates>;
  in?: InputMaybe<Array<Ordinates>>;
  neq?: InputMaybe<Ordinates>;
  nin?: InputMaybe<Array<Ordinates>>;
};

export type OutOfStockError = Error & {
  __typename?: 'OutOfStockError';
  inventoryItemId?: Maybe<Scalars['ID']>;
  locationInventoryIds?: Maybe<Array<Scalars['ID']>>;
  message: Scalars['String'];
  quantity?: Maybe<Scalars['NonNegativeInt']>;
};

export enum PackageStatus {
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED',
  FULFILED = 'FULFILED',
  IN_TRANSIT = 'IN_TRANSIT',
  PACKED = 'PACKED',
  READY_TO_PACK = 'READY_TO_PACK',
  READY_TO_SHIP = 'READY_TO_SHIP',
  REFUNDED = 'REFUNDED',
  RETURNED = 'RETURNED',
  RETURNING = 'RETURNING',
  UNKNOWN = 'UNKNOWN'
}

export type PackItemsError = CannotFulfillQuantityError;

export type PackItemsInput = {
  orderLineItemQuantities: Array<OrderLineItemQuantitiesInput>;
  orderPackageId: Scalars['ID'];
  shopOrderId: Scalars['ID'];
};

export type PackItemsPayload = {
  __typename?: 'PackItemsPayload';
  errors?: Maybe<Array<PackItemsError>>;
  orderPackage?: Maybe<OrderPackage>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type PagesConnection = {
  __typename?: 'PagesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PagesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<FbPage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PagesEdge = {
  __typename?: 'PagesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FbPage;
};

export type ParentServicelevel = {
  __typename?: 'ParentServicelevel';
  extendedToken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type Payment = {
  amount: Money;
  applicationFee: Money;
  cardDeclineCode?: Maybe<CardDeclineCode>;
  createdAt: Scalars['DateTime'];
  currency: CurrencyCode;
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['ID']>;
  customerPaymentMethod?: Maybe<CustomerPaymentMethod>;
  customerPaymentMethodId?: Maybe<Scalars['ID']>;
  errorCode?: Maybe<PaymentErrorCode>;
  id: Scalars['ID'];
  orders?: Maybe<OrdersConnection>;
  paymentProviderFee?: Maybe<Money>;
  salesTaxAmount: Money;
  shippingAmount: Money;
  status: PaymentStatus;
};

export type PaymentOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PaymentAccount = Node & {
  __typename?: 'PaymentAccount';
  email: Scalars['String'];
  externalAccounts?: Maybe<ExternalAccountsConnection>;
  id: Scalars['ID'];
  paymentProvider: PaymentProvider;
  payoutsEnabled: Scalars['Boolean'];
  providerAccountId: Scalars['String'];
  shop?: Maybe<Shop>;
  shopId: Scalars['ID'];
  status: PaymentAccountStatus;
};

export type PaymentAccountExternalAccountsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PaymentAccountAlreadyExistsError = Error & {
  __typename?: 'PaymentAccountAlreadyExistsError';
  message: Scalars['String'];
};

export type PaymentAccountLink = {
  __typename?: 'PaymentAccountLink';
  createdAt: Scalars['DateTime'];
  expiresAt: Scalars['DateTime'];
  type: AccountLinkType;
  url: Scalars['URL'];
};

export type PaymentAccountNotFoundError = Error & {
  __typename?: 'PaymentAccountNotFoundError';
  message: Scalars['String'];
};

/** A connection to a list of items. */
export type PaymentAccountsByShopConnection = {
  __typename?: 'PaymentAccountsByShopConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PaymentAccountsByShopEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PaymentAccount>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PaymentAccountsByShopEdge = {
  __typename?: 'PaymentAccountsByShopEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PaymentAccount;
};

/** A connection to a list of items. */
export type PaymentAccountsByShopSlugConnection = {
  __typename?: 'PaymentAccountsByShopSlugConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PaymentAccountsByShopSlugEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PaymentAccount>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PaymentAccountsByShopSlugEdge = {
  __typename?: 'PaymentAccountsByShopSlugEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PaymentAccount;
};

/** A connection to a list of items. */
export type PaymentAccountsConnection = {
  __typename?: 'PaymentAccountsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PaymentAccountsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PaymentAccount>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PaymentAccountsEdge = {
  __typename?: 'PaymentAccountsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PaymentAccount;
};

export enum PaymentAccountStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  RESTRICTED = 'RESTRICTED'
}

export type PaymentError = Error & {
  __typename?: 'PaymentError';
  cardDeclineCode?: Maybe<CardDeclineCode>;
  errorCode?: Maybe<PaymentErrorCode>;
  message: Scalars['String'];
};

export enum PaymentErrorCode {
  AMOUNT_TOO_LARGE = 'AMOUNT_TOO_LARGE',
  AMOUNT_TOO_SMALL = 'AMOUNT_TOO_SMALL',
  CARD_DECLINED = 'CARD_DECLINED',
  CARDHOLDER_PHONE_NUMBER_REQUIRED = 'CARDHOLDER_PHONE_NUMBER_REQUIRED',
  EMAIL_INVALID = 'EMAIL_INVALID',
  EXPIRED_CARD = 'EXPIRED_CARD',
  INCORRECT_ADDRESS = 'INCORRECT_ADDRESS',
  INCORRECT_CVC = 'INCORRECT_CVC',
  INCORRECT_NUMBER = 'INCORRECT_NUMBER',
  INCORRECT_ZIP = 'INCORRECT_ZIP',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  INVALID_CVC = 'INVALID_CVC',
  INVALID_EXPIRY_MONTH = 'INVALID_EXPIRY_MONTH',
  INVALID_EXPIRY_YEAR = 'INVALID_EXPIRY_YEAR',
  INVALID_NUMBER = 'INVALID_NUMBER',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  POSTAL_CODE_INVALID = 'POSTAL_CODE_INVALID',
  PROCESSING_ERROR = 'PROCESSING_ERROR',
  RATE_LIMIT = 'RATE_LIMIT',
  REFER_TO_CUSTOMER = 'REFER_TO_CUSTOMER'
}

export type PaymentFilterInput = {
  amount?: InputMaybe<ComparableInt32OperationFilterInput>;
  amountMoney?: InputMaybe<MoneyFilterInput>;
  and?: InputMaybe<Array<PaymentFilterInput>>;
  applicationFee?: InputMaybe<ComparableInt32OperationFilterInput>;
  applicationFeeMoney?: InputMaybe<MoneyFilterInput>;
  cardDeclineCode?: InputMaybe<NullableOfCardDeclineCodeOperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  currency?: InputMaybe<CurrencyCodeOperationFilterInput>;
  customer?: InputMaybe<CustomerFilterInput>;
  customerId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  customerPaymentMethod?: InputMaybe<CustomerPaymentMethodFilterInput>;
  customerPaymentMethodId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  errorCode?: InputMaybe<NullableOfPaymentErrorCodeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<PaymentFilterInput>>;
  orderPayments?: InputMaybe<ListFilterInputTypeOfOrderPaymentFilterInput>;
  orders?: InputMaybe<ListFilterInputTypeOfOrderFilterInput>;
  paymentProviderFee?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  paymentProviderFeeMoney?: InputMaybe<MoneyFilterInput>;
  refunds?: InputMaybe<ListFilterInputTypeOfRefundFilterInput>;
  salesTaxAmount?: InputMaybe<ComparableInt32OperationFilterInput>;
  salesTaxAmountMoney?: InputMaybe<MoneyFilterInput>;
  shippingAmount?: InputMaybe<ComparableInt32OperationFilterInput>;
  shippingAmountMoney?: InputMaybe<MoneyFilterInput>;
  shopPayments?: InputMaybe<ListFilterInputTypeOfShopPaymentFilterInput>;
  status?: InputMaybe<PaymentStatusOperationFilterInput>;
};

export enum PaymentProvider {
  CASH = 'CASH',
  STRIPE = 'STRIPE'
}

export type PaymentProviderCustomerFilterInput = {
  and?: InputMaybe<Array<PaymentProviderCustomerFilterInput>>;
  customer?: InputMaybe<CustomerFilterInput>;
  customerId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<PaymentProviderCustomerFilterInput>>;
  paymentProvider?: InputMaybe<PaymentProviderOperationFilterInput>;
  providerCustomerId?: InputMaybe<StringOperationFilterInput>;
};

export type PaymentProviderOperationFilterInput = {
  eq?: InputMaybe<PaymentProvider>;
  in?: InputMaybe<Array<PaymentProvider>>;
  neq?: InputMaybe<PaymentProvider>;
  nin?: InputMaybe<Array<PaymentProvider>>;
};

/** A connection to a list of items. */
export type PaymentsConnection = {
  __typename?: 'PaymentsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PaymentsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Payment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PaymentsEdge = {
  __typename?: 'PaymentsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Payment>;
};

export enum PaymentStatus {
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  REQUIRES_ACTION = 'REQUIRES_ACTION',
  REQUIRES_CAPTURE = 'REQUIRES_CAPTURE',
  REQUIRES_CONFIRMATION = 'REQUIRES_CONFIRMATION',
  REQUIRES_PAYMENT_METHOD = 'REQUIRES_PAYMENT_METHOD',
  SUCCEEDED = 'SUCCEEDED'
}

export type PaymentStatusOperationFilterInput = {
  eq?: InputMaybe<PaymentStatus>;
  in?: InputMaybe<Array<PaymentStatus>>;
  neq?: InputMaybe<PaymentStatus>;
  nin?: InputMaybe<Array<PaymentStatus>>;
};

export type PhoneNumberVerificationResponse = {
  __typename?: 'PhoneNumberVerificationResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PickupShippingRate = Node &
  ShippingRate & {
    __typename?: 'PickupShippingRate';
    id: Scalars['ID'];
    shop?: Maybe<Shop>;
    shopId: Scalars['ID'];
  };

export type PlaidLink = {
  __typename?: 'PlaidLink';
  accessToken?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  shop: Shop;
  shopId: Scalars['UUID'];
  user: User;
  userId: Scalars['UUID'];
};

export type PlaidLinkFilterInput = {
  accessToken?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<PlaidLinkFilterInput>>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<PlaidLinkFilterInput>>;
  shop?: InputMaybe<ShopFilterInput>;
  shopId?: InputMaybe<ComparableGuidOperationFilterInput>;
  user?: InputMaybe<UserFilterInput>;
  userId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type PointFilterInput = {
  and?: InputMaybe<Array<PointFilterInput>>;
  area?: InputMaybe<ComparableDoubleOperationFilterInput>;
  boundary?: InputMaybe<GeometryFilterInput>;
  boundaryDimension?: InputMaybe<DimensionOperationFilterInput>;
  centroid?: InputMaybe<PointFilterInput>;
  coordinate?: InputMaybe<CoordinateFilterInput>;
  coordinates?: InputMaybe<ListFilterInputTypeOfCoordinateFilterInput>;
  coordinateSequence?: InputMaybe<CoordinateSequenceFilterInput>;
  dimension?: InputMaybe<DimensionOperationFilterInput>;
  envelope?: InputMaybe<GeometryFilterInput>;
  envelopeInternal?: InputMaybe<EnvelopeFilterInput>;
  factory?: InputMaybe<GeometryFactoryFilterInput>;
  geometryType?: InputMaybe<StringOperationFilterInput>;
  interiorPoint?: InputMaybe<PointFilterInput>;
  isEmpty?: InputMaybe<BooleanOperationFilterInput>;
  isRectangle?: InputMaybe<BooleanOperationFilterInput>;
  isSimple?: InputMaybe<BooleanOperationFilterInput>;
  isValid?: InputMaybe<BooleanOperationFilterInput>;
  length?: InputMaybe<ComparableDoubleOperationFilterInput>;
  m?: InputMaybe<ComparableDoubleOperationFilterInput>;
  numGeometries?: InputMaybe<ComparableInt32OperationFilterInput>;
  numPoints?: InputMaybe<ComparableInt32OperationFilterInput>;
  ogcGeometryType?: InputMaybe<OgcGeometryTypeOperationFilterInput>;
  or?: InputMaybe<Array<PointFilterInput>>;
  pointOnSurface?: InputMaybe<PointFilterInput>;
  precisionModel?: InputMaybe<PrecisionModelFilterInput>;
  sRID?: InputMaybe<ComparableInt32OperationFilterInput>;
  x?: InputMaybe<ComparableDoubleOperationFilterInput>;
  y?: InputMaybe<ComparableDoubleOperationFilterInput>;
  z?: InputMaybe<ComparableDoubleOperationFilterInput>;
};

export type PrecisionModelFilterInput = {
  and?: InputMaybe<Array<PrecisionModelFilterInput>>;
  isFloating?: InputMaybe<BooleanOperationFilterInput>;
  maximumSignificantDigits?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<PrecisionModelFilterInput>>;
  precisionModelType?: InputMaybe<PrecisionModelsOperationFilterInput>;
  scale?: InputMaybe<ComparableDoubleOperationFilterInput>;
};

export enum PrecisionModels {
  FIXED = 'FIXED',
  FLOATING = 'FLOATING',
  FLOATING_SINGLE = 'FLOATING_SINGLE'
}

export type PrecisionModelsOperationFilterInput = {
  eq?: InputMaybe<PrecisionModels>;
  in?: InputMaybe<Array<PrecisionModels>>;
  neq?: InputMaybe<PrecisionModels>;
  nin?: InputMaybe<Array<PrecisionModels>>;
};

export type Prices = {
  __typename?: 'Prices';
  basePrice: Scalars['Amount'];
  currency: CurrencyCode;
  price: Scalars['Amount'];
  retailPrice?: Maybe<Scalars['Amount']>;
  salePrice?: Maybe<Scalars['Amount']>;
};

export type PricesInput = {
  basePrice: Scalars['Amount'];
  currency: CurrencyCode;
  retailPrice?: InputMaybe<Scalars['Amount']>;
  salePrice?: InputMaybe<Scalars['Amount']>;
};

export type PrintPackagePackingSlipInput = {
  orderPackageId: Scalars['ID'];
  shopOrderId: Scalars['ID'];
};

export type PrintPackagePackingSlipPayload = {
  __typename?: 'PrintPackagePackingSlipPayload';
  orderPackage?: Maybe<OrderPackage>;
};

export type PrintPackingSlipError = CannotFulfillQuantityError;

export type PrintPackingSlipInput = {
  shopOrderId: Scalars['ID'];
};

export type PrintPackingSlipPayload = {
  __typename?: 'PrintPackingSlipPayload';
  errors?: Maybe<Array<PrintPackingSlipError>>;
  shopOrder?: Maybe<ShopOrder>;
};

export type ProcessCardReaderPaymentIntentInput = {
  orderId: Scalars['ID'];
};

export type ProcessCardReaderPaymentIntentPayload = {
  __typename?: 'ProcessCardReaderPaymentIntentPayload';
  order?: Maybe<Order>;
};

export type Product = Node & {
  __typename?: 'Product';
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  defaultImage?: Maybe<Image>;
  defaultImageId?: Maybe<Scalars['ID']>;
  defaultVariant?: Maybe<Variant>;
  defaultVariantId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  options?: Maybe<OptionsConnection>;
  productImages?: Maybe<ProductImagesConnection>;
  productPrices: ProductPrices;
  quantity: Quantity;
  shop: Shop;
  shopId: Scalars['ID'];
  slug: Scalars['String'];
  source: Source;
  status: ProductStatus;
  updatedAt: Scalars['DateTime'];
  variants?: Maybe<VariantsConnection>;
};

export type ProductDescriptionTextArgs = {
  maxLength?: Scalars['Int'];
};

export type ProductOptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ProductProductImagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ProductImageSortInput>>;
};

export type ProductVariantsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<VariantFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<VariantSortInput>>;
};

export type ProductAlreadyArchivedError = Error & {
  __typename?: 'ProductAlreadyArchivedError';
  message: Scalars['String'];
};

export type ProductAlreadyPublishedError = Error & {
  __typename?: 'ProductAlreadyPublishedError';
  message: Scalars['String'];
};

export type ProductArchivedError = Error & {
  __typename?: 'ProductArchivedError';
  message: Scalars['String'];
};

export type ProductCollection = Node & {
  __typename?: 'ProductCollection';
  affiliateUser?: Maybe<User>;
  affiliateUserId?: Maybe<Scalars['UUID']>;
  commission?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images: Array<Image>;
  name: Scalars['String'];
  productCollectionImages: Array<ProductCollectionImage>;
  productCollectionProducts: Array<ProductCollectionProduct>;
  products: Array<Product>;
  updatedAt: Scalars['DateTime'];
};

export type ProductCollectionImage = {
  __typename?: 'ProductCollectionImage';
  id: Scalars['UUID'];
  image?: Maybe<Image>;
  imageId: Scalars['UUID'];
  productCollection?: Maybe<ProductCollection>;
  productCollectionId: Scalars['UUID'];
};

export type ProductCollectionProduct = {
  __typename?: 'ProductCollectionProduct';
  id: Scalars['UUID'];
  product: Product;
  productCollection: ProductCollection;
  productCollectionId: Scalars['UUID'];
  productId: Scalars['UUID'];
};

export type ProductFilterInput = {
  and?: InputMaybe<Array<ProductFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductFilterInput>>;
  slug?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<ProductStatusOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

export type ProductImage = Node & {
  __typename?: 'ProductImage';
  default: Scalars['Boolean'];
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image: Image;
  imageId: Scalars['ID'];
  order: Scalars['Int'];
  product: Product;
  productId: Scalars['ID'];
  source: Source;
};

export type ProductImageFilterInput = {
  and?: InputMaybe<Array<ProductImageFilterInput>>;
  default?: InputMaybe<BooleanOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<ProductImageFilterInput>>;
  order?: InputMaybe<ComparableInt32OperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<ComparableGuidOperationFilterInput>;
  source?: InputMaybe<SourceOperationFilterInput>;
};

export type ProductImageInput = {
  imageId: Scalars['ID'];
  order?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type ProductImagesConnection = {
  __typename?: 'ProductImagesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProductImagesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProductImage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProductImagesEdge = {
  __typename?: 'ProductImagesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductImage;
};

export type ProductImageSortInput = {
  order?: InputMaybe<OrderDirection>;
};

export type ProductInventoryQuantityChangedEvent = {
  __typename?: 'ProductInventoryQuantityChangedEvent';
  productId: Scalars['ID'];
  quantity: Quantity;
};

export type ProductNotArchivedError = Error & {
  __typename?: 'ProductNotArchivedError';
  message: Scalars['String'];
};

export type ProductNotFoundError = Error & {
  __typename?: 'ProductNotFoundError';
  message: Scalars['String'];
};

export type ProductNotPublishedError = Error & {
  __typename?: 'ProductNotPublishedError';
  message: Scalars['String'];
};

export type ProductPrices = {
  __typename?: 'ProductPrices';
  currency?: Maybe<CurrencyCode>;
  maxPrice?: Maybe<Scalars['Amount']>;
  maxRetailPrice?: Maybe<Scalars['Amount']>;
  minPrice?: Maybe<Scalars['Amount']>;
  minRetailPrice?: Maybe<Scalars['Amount']>;
};

/** A connection to a list of items. */
export type ProductsByIdsConnection = {
  __typename?: 'ProductsByIdsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProductsByIdsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Product>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProductsByIdsEdge = {
  __typename?: 'ProductsByIdsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Product;
};

/** A connection to a list of items. */
export type ProductsByShopConnection = {
  __typename?: 'ProductsByShopConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProductsByShopEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Product>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProductsByShopEdge = {
  __typename?: 'ProductsByShopEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Product;
};

/** A connection to a list of items. */
export type ProductsByShopSlugConnection = {
  __typename?: 'ProductsByShopSlugConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProductsByShopSlugEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Product>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProductsByShopSlugEdge = {
  __typename?: 'ProductsByShopSlugEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Product;
};

/** A connection to a list of items. */
export type ProductsConnection = {
  __typename?: 'ProductsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProductsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Product>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProductsEdge = {
  __typename?: 'ProductsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Product;
};

export type ProductSlugAlreadyExistsError = Error & {
  __typename?: 'ProductSlugAlreadyExistsError';
  message: Scalars['String'];
};

export type ProductSortInput = {
  createdAt?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
  slug?: InputMaybe<OrderDirection>;
  status?: InputMaybe<OrderDirection>;
  updatedAt?: InputMaybe<OrderDirection>;
};

export enum ProductStatus {
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}

export type ProductStatusOperationFilterInput = {
  eq?: InputMaybe<ProductStatus>;
  in?: InputMaybe<Array<ProductStatus>>;
  neq?: InputMaybe<ProductStatus>;
  nin?: InputMaybe<Array<ProductStatus>>;
};

export type ProfileImage = {
  __typename?: 'ProfileImage';
  createdAt: Scalars['DateTime'];
  focusRegion?: Maybe<ImageRegion>;
  format: ImageFormat;
  height: Scalars['Int'];
  id: Scalars['UUID'];
  originalSrc: Scalars['URL'];
  thumbnailSrc?: Maybe<Scalars['URL']>;
  transformedSrc?: Maybe<Scalars['URL']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId: Scalars['UUID'];
  userImages: Array<UserImage>;
  width: Scalars['Int'];
};

export type ProfileImageTransformedSrcArgs = {
  crop?: InputMaybe<ImageRegionInput>;
  format?: InputMaybe<ImageFormat>;
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type ProfileImageFilterInput = {
  and?: InputMaybe<Array<ProfileImageFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  focusRegion?: InputMaybe<ImageRegionFilterInput>;
  format?: InputMaybe<ImageFormatOperationFilterInput>;
  height?: InputMaybe<ComparableInt32OperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<ProfileImageFilterInput>>;
  updatedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  user?: InputMaybe<UserFilterInput>;
  userId?: InputMaybe<ComparableGuidOperationFilterInput>;
  userImages?: InputMaybe<ListFilterInputTypeOfUserImageFilterInput>;
  width?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type ProfileImageSortInput = {
  createdAt?: InputMaybe<OrderDirection>;
  focusRegion?: InputMaybe<ImageRegionSortInput>;
  format?: InputMaybe<OrderDirection>;
  height?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  updatedAt?: InputMaybe<OrderDirection>;
  user?: InputMaybe<UserSortInput>;
  userId?: InputMaybe<OrderDirection>;
  width?: InputMaybe<OrderDirection>;
};

export type ProfileImageUpload = {
  __typename?: 'ProfileImageUpload';
  filename: Scalars['String'];
  focusRegion?: Maybe<ImageRegion>;
  profileImageId: Scalars['ID'];
  uploadUrl: Scalars['URL'];
};

export type ProfileImageUploadInput = {
  filename: Scalars['String'];
  focusRegion?: InputMaybe<ImageRegionInput>;
  removeBackground?: InputMaybe<Scalars['Boolean']>;
};

export type PublishProductError = ProductAlreadyPublishedError | ProductArchivedError;

export type PublishProductInput = {
  productId: Scalars['ID'];
};

export type PublishProductPayload = {
  __typename?: 'PublishProductPayload';
  errors?: Maybe<Array<PublishProductError>>;
  product?: Maybe<Product>;
};

export type PurchaseShippingLabelError = CannotFulfillQuantityError;

export type PurchaseShippingLabelInput = {
  orderPackageRates: Array<OrderPackageRatesInput>;
  shopOrderId: Scalars['ID'];
};

export type PurchaseShippingLabelPayload = {
  __typename?: 'PurchaseShippingLabelPayload';
  errors?: Maybe<Array<PurchaseShippingLabelError>>;
  shopOrder?: Maybe<ShopOrder>;
};

export type Quantity = {
  __typename?: 'Quantity';
  available: Scalars['NonNegativeInt'];
  reserved: Scalars['NonNegativeInt'];
  total: Scalars['NonNegativeInt'];
};

export type Query = {
  __typename?: 'Query';
  allShoppingCategories: Array<ShoppingCategory>;
  allStreams?: Maybe<AllStreamsConnection>;
  anyShopBySlug?: Maybe<Shop>;
  cardReaderStatus: Scalars['String'];
  cart?: Maybe<Cart>;
  cartItem?: Maybe<CartItem>;
  cartItemsByCart?: Maybe<CartItemsByCartConnection>;
  cartsByCustomer?: Maybe<CartsByCustomerConnection>;
  category?: Maybe<Category>;
  categoryBySlug?: Maybe<Category>;
  categoryTree?: Maybe<CategoryTreeConnection>;
  checkout?: Maybe<Checkout>;
  checkoutsByCart?: Maybe<CheckoutsByCartConnection>;
  checkoutsByCustomer?: Maybe<Checkout>;
  customer?: Maybe<Customer>;
  customerAddress?: Maybe<CustomerAddress>;
  customerAddressesByCustomer?: Maybe<CustomerAddressesByCustomerConnection>;
  customerByUser?: Maybe<Customer>;
  customerPaymentMethod?: Maybe<CustomerPaymentMethod>;
  customerPaymentMethodsByCustomer?: Maybe<CustomerPaymentMethodsByCustomerConnection>;
  externalAccount?: Maybe<ExternalAccount>;
  externalAccountsByPaymentAccount?: Maybe<ExternalAccountsByPaymentAccountConnection>;
  fbBusinessAccount?: Maybe<FbBusinessAccount>;
  fbBusinessAccountByShop?: Maybe<FbBusinessAccount>;
  fbCommentsSession?: Maybe<FbCommentsSession>;
  fbCommentsSessionByLive?: Maybe<FbCommentsSession>;
  fbCommentsSessionByShow?: Maybe<FbCommentsSession>;
  fbPagesByShop?: Maybe<FbPagesByShopConnection>;
  fbPageUserByComment?: Maybe<FbPageUser>;
  image?: Maybe<Image>;
  inventoryItem?: Maybe<InventoryItem>;
  inventoryItemByVariant?: Maybe<InventoryItem>;
  inventoryItemsByBarcode?: Maybe<InventoryItemsByBarcodeConnection>;
  inventoryItemsByProduct?: Maybe<InventoryItemsByProductConnection>;
  inventoryItemsByShop?: Maybe<InventoryItemsByShopConnection>;
  inventoryItemsBySku?: Maybe<InventoryItemsBySkuConnection>;
  locationInventoriesByInventoryItem?: Maybe<LocationInventoriesByInventoryItemConnection>;
  locationInventoriesByShopLocation?: Maybe<LocationInventoriesByShopLocationConnection>;
  locationInventory?: Maybe<LocationInventory>;
  order?: Maybe<Order>;
  orderPackage?: Maybe<OrderPackage>;
  ordersByCustomer?: Maybe<OrdersByCustomerConnection>;
  orderShipment?: Maybe<OrderShipment>;
  orderShipmentsByOrder?: Maybe<OrderShipmentsByOrderConnection>;
  orderShipmentsByShopOrder?: Maybe<OrderShipmentsByShopOrderConnection>;
  payment?: Maybe<Payment>;
  paymentAccount?: Maybe<PaymentAccount>;
  paymentAccountsByShop?: Maybe<PaymentAccountsByShopConnection>;
  paymentAccountsByShopSlug?: Maybe<PaymentAccountsByShopSlugConnection>;
  product?: Maybe<Product>;
  productBySlug?: Maybe<Product>;
  productCollectionById?: Maybe<ProductCollection>;
  productsByIds?: Maybe<ProductsByIdsConnection>;
  productsByShop?: Maybe<ProductsByShopConnection>;
  productsByShopSlug?: Maybe<ProductsByShopSlugConnection>;
  reservation?: Maybe<Reservation>;
  reservationsByInventoryItem?: Maybe<ReservationsByInventoryItemConnection>;
  reservationsByLocationInventory?: Maybe<ReservationsByLocationInventoryConnection>;
  searchCategories?: Maybe<SearchCategoriesConnection>;
  searchOrders?: Maybe<SearchOrdersConnection>;
  searchProducts?: Maybe<SearchProductsConnection>;
  searchProductsByShop?: Maybe<SearchProductsByShopConnection>;
  searchShopOrdersByShop?: Maybe<SearchShopOrdersByShopConnection>;
  searchShops?: Maybe<SearchShopsConnection>;
  searchShopsByUsers?: Maybe<SearchShopsByUsersConnection>;
  searchShows?: Maybe<SearchShowsConnection>;
  searchShowsByShop?: Maybe<SearchShowsByShopConnection>;
  searchTaxCategories?: Maybe<SearchTaxCategoriesConnection>;
  sellerCategories?: Maybe<SellerCategoriesConnection>;
  sellerSubCategories: Array<CategoryGroupedResponse>;
  shippingCarrierAccount?: Maybe<ShippingCarrierAccount>;
  shippingCarrierAccountsByShop?: Maybe<ShippingCarrierAccountsByShopConnection>;
  shippingCarrierPackage?: Maybe<ShippingCarrierPackage>;
  shippingCarrierPackagesByShippingCarrier?: Maybe<ShippingCarrierPackagesByShippingCarrierConnection>;
  shippingCarrierService?: Maybe<ShippingCarrierService>;
  shippingCarrierServicesByShippingCarrier?: Maybe<ShippingCarrierServicesByShippingCarrierConnection>;
  shippingMethod?: Maybe<ShippingMethod>;
  shippingMethodsByShippingProfile?: Maybe<ShippingMethodsByShippingProfileConnection>;
  shippingMethodsByShippingProfileGroup?: Maybe<ShippingMethodsByShippingProfileGroupConnection>;
  shippingMethodsByShippingZone?: Maybe<ShippingMethodsByShippingZoneConnection>;
  shippingPackagesForShop: ShippingPackageResponse;
  shippingProfile?: Maybe<ShippingProfile>;
  shippingProfileGroup?: Maybe<ShippingProfileGroup>;
  shippingProfileGroupsByProfile?: Maybe<ShippingProfileGroupsByProfileConnection>;
  shippingProfilesByShop?: Maybe<ShippingProfilesByShopConnection>;
  shippingRate?: Maybe<ShippingRate>;
  shippingZone?: Maybe<ShippingZone>;
  shippingZonesByGroup?: Maybe<ShippingZonesByGroupConnection>;
  shop?: Maybe<Shop>;
  shopBySlug?: Maybe<Shop>;
  shopConnectionRequest?: Maybe<ShopConnectionRequest>;
  shopLocation?: Maybe<ShopLocation>;
  shopLocationsByShop?: Maybe<ShopLocationsByShopConnection>;
  shopOrder?: Maybe<ShopOrder>;
  shopOrdersByOrder?: Maybe<ShopOrdersByOrderConnection>;
  shopOrdersByShop?: Maybe<ShopOrdersByShopConnection>;
  shopPayment?: Maybe<ShopPayment>;
  shoppingSubcategoriesByIds: Array<ShoppingSubcategory>;
  shops?: Maybe<ShopsConnection>;
  show?: Maybe<Show>;
  showBySlug?: Maybe<Show>;
  showCard?: Maybe<ShowCard>;
  showCardsByShow?: Maybe<ShowCardsByShowConnection>;
  showCardsByShowSlug?: Maybe<ShowCardsByShowSlugConnection>;
  shows?: Maybe<ShowsConnection>;
  showsByShop?: Maybe<ShowsByShopConnection>;
  showsByShopSlug?: Maybe<ShowsByShopSlugConnection>;
  stream?: Maybe<Stream>;
  streamBySessionId?: Maybe<Stream>;
  streams?: Maybe<StreamsConnection>;
  streamsByShopSlug?: Maybe<StreamsByShopSlugConnection>;
  taxCategories?: Maybe<TaxCategoriesConnection>;
  taxCategoriesByCategory?: Maybe<TaxCategoriesByCategoryConnection>;
  taxCategory?: Maybe<TaxCategory>;
  user?: Maybe<User>;
  userByUsername?: Maybe<User>;
  usersByEmail?: Maybe<UsersByEmailConnection>;
  userShippingPackagesForShop?: Maybe<UserShippingPackagesForShopConnection>;
  userShoppingCategories: Array<UserShoppingCategory>;
  userShoppingSubcategories: Array<UserShoppingSubcategory>;
  validateAddress: AddressValidationResult;
  validateBarcode: BarcodeValidationResult;
  variant?: Maybe<Variant>;
  variantsByProduct?: Maybe<VariantsByProductConnection>;
  viewer?: Maybe<User>;
  viewerCartByShop?: Maybe<Cart>;
  viewerCartByShopSlug?: Maybe<Cart>;
  viewerCartItems?: Maybe<ViewerCartItemsConnection>;
  viewerCartItemsByShop?: Maybe<ViewerCartItemsByShopConnection>;
  viewerCartItemsByShopSlug?: Maybe<ViewerCartItemsByShopSlugConnection>;
  viewerCheckoutsByShop?: Maybe<ViewerCheckoutsByShopConnection>;
  viewerCheckoutsByShopSlug?: Maybe<ViewerCheckoutsByShopSlugConnection>;
  viewerConnectedShops?: Maybe<ViewerConnectedShopsConnection>;
  viewerShopsUsers?: Maybe<ViewerShopsUsersConnection>;
};

export type QueryAllStreamsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<StreamFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<StreamSortInput>>;
};

export type QueryAnyShopBySlugArgs = {
  slug: Scalars['String'];
};

export type QueryCartArgs = {
  id: Scalars['ID'];
};

export type QueryCartItemArgs = {
  id: Scalars['ID'];
};

export type QueryCartItemsByCartArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cartId: Scalars['ID'];
  filter?: InputMaybe<CartItemFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<CartItemSortInput>>;
};

export type QueryCartsByCustomerArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  customerId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryCategoryBySlugArgs = {
  slug: Scalars['String'];
};

export type QueryCategoryTreeArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CategoryFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<CategorySortInput>>;
};

export type QueryCheckoutArgs = {
  id: Scalars['ID'];
};

export type QueryCheckoutsByCartArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cartId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryCheckoutsByCustomerArgs = {
  customerId: Scalars['ID'];
};

export type QueryCustomerArgs = {
  id: Scalars['ID'];
};

export type QueryCustomerAddressArgs = {
  id: Scalars['ID'];
};

export type QueryCustomerAddressesByCustomerArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  customerId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryCustomerByUserArgs = {
  userId: Scalars['ID'];
};

export type QueryCustomerPaymentMethodArgs = {
  id: Scalars['ID'];
};

export type QueryCustomerPaymentMethodsByCustomerArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  customerId: Scalars['ID'];
  filter?: InputMaybe<CustomerPaymentMethodFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryExternalAccountArgs = {
  id: Scalars['ID'];
};

export type QueryExternalAccountsByPaymentAccountArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  paymentAccountId: Scalars['ID'];
};

export type QueryFbBusinessAccountArgs = {
  id: Scalars['ID'];
};

export type QueryFbBusinessAccountByShopArgs = {
  shopId: Scalars['ID'];
};

export type QueryFbCommentsSessionArgs = {
  id: Scalars['ID'];
};

export type QueryFbCommentsSessionByLiveArgs = {
  liveId: Scalars['ID'];
};

export type QueryFbCommentsSessionByShowArgs = {
  showId: Scalars['ID'];
};

export type QueryFbPagesByShopArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  shopId: Scalars['ID'];
};

export type QueryFbPageUserByCommentArgs = {
  commentId: Scalars['ID'];
};

export type QueryImageArgs = {
  id: Scalars['ID'];
};

export type QueryInventoryItemArgs = {
  id: Scalars['ID'];
};

export type QueryInventoryItemByVariantArgs = {
  variantId: Scalars['ID'];
};

export type QueryInventoryItemsByBarcodeArgs = {
  after?: InputMaybe<Scalars['String']>;
  barcode: Scalars['Barcode'];
  first?: InputMaybe<Scalars['Int']>;
  shopId: Scalars['ID'];
};

export type QueryInventoryItemsByProductArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  productId: Scalars['ID'];
};

export type QueryInventoryItemsByShopArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  shopId: Scalars['ID'];
};

export type QueryInventoryItemsBySkuArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  shopId: Scalars['ID'];
  sku: Scalars['Sku'];
};

export type QueryLocationInventoriesByInventoryItemArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  inventoryItemId: Scalars['ID'];
};

export type QueryLocationInventoriesByShopLocationArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  shopLocationId: Scalars['ID'];
};

export type QueryLocationInventoryArgs = {
  id: Scalars['ID'];
};

export type QueryOrderArgs = {
  id: Scalars['ID'];
};

export type QueryOrderPackageArgs = {
  id: Scalars['ID'];
};

export type QueryOrdersByCustomerArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  customerId: Scalars['ID'];
  filter?: InputMaybe<OrderFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<OrderSortInput>>;
};

export type QueryOrderShipmentArgs = {
  id: Scalars['ID'];
};

export type QueryOrderShipmentsByOrderArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderId: Scalars['ID'];
};

export type QueryOrderShipmentsByShopOrderArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  shopOrderId: Scalars['ID'];
};

export type QueryPaymentArgs = {
  id: Scalars['ID'];
};

export type QueryPaymentAccountArgs = {
  id: Scalars['ID'];
};

export type QueryPaymentAccountsByShopArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  shopId: Scalars['ID'];
};

export type QueryPaymentAccountsByShopSlugArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  slug: Scalars['String'];
};

export type QueryProductArgs = {
  id: Scalars['ID'];
};

export type QueryProductBySlugArgs = {
  productSlug: Scalars['String'];
  shopSlug: Scalars['String'];
};

export type QueryProductCollectionByIdArgs = {
  collectionId: Scalars['ID'];
};

export type QueryProductsByIdsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  ids: Array<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ProductSortInput>>;
};

export type QueryProductsByShopArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ProductSortInput>>;
  shopId: Scalars['ID'];
};

export type QueryProductsByShopSlugArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ProductSortInput>>;
  shopSlug: Scalars['String'];
};

export type QueryReservationArgs = {
  id: Scalars['ID'];
};

export type QueryReservationsByInventoryItemArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  inventoryItemId: Scalars['ID'];
};

export type QueryReservationsByLocationInventoryArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  locationInventoryId: Scalars['ID'];
};

export type QuerySearchCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CategoryFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<CategorySortInput>>;
  query: Scalars['String'];
};

export type QuerySearchOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrderFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<OrderSortInput>>;
  query: Scalars['String'];
};

export type QuerySearchProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ProductSortInput>>;
  query: Scalars['String'];
};

export type QuerySearchProductsByShopArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ProductSortInput>>;
  query: Scalars['String'];
  shopId: Scalars['ID'];
};

export type QuerySearchShopOrdersByShopArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShopOrderFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShopOrderSortInput>>;
  query: Scalars['String'];
  shopId: Scalars['ID'];
};

export type QuerySearchShopsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShopFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShopSortInput>>;
  query: Scalars['String'];
};

export type QuerySearchShopsByUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShopFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShopSortInput>>;
  query: Scalars['String'];
};

export type QuerySearchShowsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShowFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShowSortInput>>;
  query: Scalars['String'];
};

export type QuerySearchShowsByShopArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShowFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShowSortInput>>;
  query: Scalars['String'];
  shopId: Scalars['ID'];
};

export type QuerySearchTaxCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TaxCategoryFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<TaxCategorySortInput>>;
  query: Scalars['String'];
};

export type QuerySellerCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CategoryFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<CategorySortInput>>;
};

export type QuerySellerSubCategoriesArgs = {
  categoryIds: Array<Scalars['ID']>;
};

export type QueryShippingCarrierAccountArgs = {
  id: Scalars['ID'];
};

export type QueryShippingCarrierAccountsByShopArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShippingCarrierAccountFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShippingCarrierAccountSortInput>>;
  shopId: Scalars['ID'];
};

export type QueryShippingCarrierPackageArgs = {
  id: Scalars['ID'];
};

export type QueryShippingCarrierPackagesByShippingCarrierArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShippingCarrierPackageFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShippingCarrierPackageSortInput>>;
  shippingCarrier: ShippingCarrier;
};

export type QueryShippingCarrierServiceArgs = {
  id: Scalars['ID'];
};

export type QueryShippingCarrierServicesByShippingCarrierArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShippingCarrierServiceFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShippingCarrierServiceSortInput>>;
  shippingCarrier: ShippingCarrier;
};

export type QueryShippingMethodArgs = {
  id: Scalars['ID'];
};

export type QueryShippingMethodsByShippingProfileArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShippingMethodFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShippingMethodSortInput>>;
  shippingProfileId: Scalars['ID'];
};

export type QueryShippingMethodsByShippingProfileGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShippingMethodFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShippingMethodSortInput>>;
  shippingProfileGroupId: Scalars['ID'];
};

export type QueryShippingMethodsByShippingZoneArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShippingMethodFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShippingMethodSortInput>>;
  shippingZoneId: Scalars['ID'];
};

export type QueryShippingPackagesForShopArgs = {
  shopId: Scalars['ID'];
};

export type QueryShippingProfileArgs = {
  id: Scalars['ID'];
};

export type QueryShippingProfileGroupArgs = {
  id: Scalars['ID'];
};

export type QueryShippingProfileGroupsByProfileArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShippingProfileGroupSortInput>>;
  shippingProfileId: Scalars['ID'];
};

export type QueryShippingProfilesByShopArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShippingProfileFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShippingProfileSortInput>>;
  shopId: Scalars['ID'];
};

export type QueryShippingRateArgs = {
  id: Scalars['ID'];
};

export type QueryShippingZoneArgs = {
  id: Scalars['ID'];
};

export type QueryShippingZonesByGroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShippingZoneFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShippingZoneSortInput>>;
  shippingProfileGroupId: Scalars['ID'];
};

export type QueryShopArgs = {
  id: Scalars['ID'];
};

export type QueryShopBySlugArgs = {
  slug: Scalars['String'];
};

export type QueryShopConnectionRequestArgs = {
  shopSlug: Scalars['String'];
};

export type QueryShopLocationArgs = {
  id: Scalars['ID'];
};

export type QueryShopLocationsByShopArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShopLocationFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShopLocationSortInput>>;
  shopId: Scalars['ID'];
};

export type QueryShopOrderArgs = {
  id: Scalars['ID'];
};

export type QueryShopOrdersByOrderArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShopOrderFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShopOrderSortInput>>;
  orderId: Scalars['ID'];
};

export type QueryShopOrdersByShopArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShopOrderFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShopOrderSortInput>>;
  shopId: Scalars['ID'];
};

export type QueryShopPaymentArgs = {
  id: Scalars['ID'];
};

export type QueryShoppingSubcategoriesByIdsArgs = {
  categoryIds: Array<Scalars['ID']>;
};

export type QueryShopsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShopFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShopSortInput>>;
};

export type QueryShowArgs = {
  id: Scalars['ID'];
};

export type QueryShowBySlugArgs = {
  shopSlug: Scalars['String'];
  showSlug: Scalars['String'];
};

export type QueryShowCardArgs = {
  id: Scalars['ID'];
};

export type QueryShowCardsByShowArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShowCardFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShowCardSortInput>>;
  showId: Scalars['ID'];
};

export type QueryShowCardsByShowSlugArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShowCardFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShowCardSortInput>>;
  shopSlug: Scalars['String'];
  showSlug: Scalars['String'];
};

export type QueryShowsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShowFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShowSortInput>>;
};

export type QueryShowsByShopArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShowFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShowSortInput>>;
  shopId: Scalars['ID'];
};

export type QueryShowsByShopSlugArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShowFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShowSortInput>>;
  shopSlug: Scalars['String'];
};

export type QueryStreamArgs = {
  id: Scalars['ID'];
};

export type QueryStreamBySessionIdArgs = {
  sessionId: Scalars['String'];
};

export type QueryStreamsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<StreamFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<StreamSortInput>>;
};

export type QueryStreamsByShopSlugArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<StreamFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<StreamSortInput>>;
  shopSlug: Scalars['String'];
};

export type QueryTaxCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TaxCategoryFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<TaxCategorySortInput>>;
};

export type QueryTaxCategoriesByCategoryArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['ID'];
  filter?: InputMaybe<TaxCategoryFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<TaxCategorySortInput>>;
};

export type QueryTaxCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryUserByUsernameArgs = {
  userName: Scalars['String'];
};

export type QueryUsersByEmailArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryUserShippingPackagesForShopArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShippingUserPackageFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShippingUserPackageSortInput>>;
  shopId: Scalars['ID'];
};

export type QueryValidateAddressArgs = {
  address: AddressInput;
};

export type QueryValidateBarcodeArgs = {
  barcode: Scalars['String'];
};

export type QueryVariantArgs = {
  id: Scalars['ID'];
};

export type QueryVariantsByProductArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<VariantFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<VariantSortInput>>;
  productId: Scalars['ID'];
};

export type QueryViewerCartByShopArgs = {
  shopId: Scalars['ID'];
};

export type QueryViewerCartByShopSlugArgs = {
  shopSlug: Scalars['String'];
};

export type QueryViewerCartItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CartItemFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<CartItemSortInput>>;
};

export type QueryViewerCartItemsByShopArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CartItemFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<CartItemSortInput>>;
  shopId: Scalars['ID'];
};

export type QueryViewerCartItemsByShopSlugArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CartItemFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<CartItemSortInput>>;
  shopSlug: Scalars['String'];
};

export type QueryViewerCheckoutsByShopArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  shopId: Scalars['ID'];
};

export type QueryViewerCheckoutsByShopSlugArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  shopSlug: Scalars['String'];
};

export type QueryViewerConnectedShopsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShopFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShopSortInput>>;
};

export type QueryViewerShopsUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShopFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShopSortInput>>;
};

export type Rate = {
  __typename?: 'Rate';
  amount: Scalars['String'];
  amountLocal: Scalars['String'];
  arrivesBy?: Maybe<Scalars['String']>;
  attributes: Array<Attributes>;
  carrierAccount: Scalars['String'];
  currency: Scalars['String'];
  currencyLocal: Scalars['String'];
  durationTerms?: Maybe<Scalars['String']>;
  estimatedDays?: Maybe<Scalars['Long']>;
  includedInsurancePrice?: Maybe<Scalars['String']>;
  messages?: Maybe<Array<ResponseMessage>>;
  objectCreated: Scalars['DateTime'];
  objectId: Scalars['String'];
  objectOwner: Scalars['String'];
  provider: Scalars['String'];
  providerImage200?: Maybe<Scalars['String']>;
  providerImage75?: Maybe<Scalars['String']>;
  servicelevel: ServiceLevelWithParent;
  shipment: Scalars['String'];
  test?: Maybe<Scalars['Boolean']>;
  zone?: Maybe<Scalars['String']>;
};

export type ReconnectFbBusinessAccountError = FbApiError | FbBusinessAccountNotFoundError;

export type ReconnectFbBusinessAccountInput = {
  fbBusinessAccountId: Scalars['ID'];
  token: Scalars['String'];
};

export type ReconnectFbBusinessAccountPayload = {
  __typename?: 'ReconnectFbBusinessAccountPayload';
  errors?: Maybe<Array<ReconnectFbBusinessAccountError>>;
  fbBusinessAccount?: Maybe<FbBusinessAccount>;
};

export type Refund = {
  amount: Money;
  createdAt: Scalars['DateTime'];
  currency: CurrencyCode;
  id: Scalars['ID'];
  payment: Payment;
  paymentId: Scalars['ID'];
  reason?: Maybe<RefundReason>;
  shopRefunds?: Maybe<ShopRefundsConnection>;
  status: RefundStatus;
};

export type RefundShopRefundsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type RefundFilterInput = {
  amount?: InputMaybe<ComparableInt32OperationFilterInput>;
  amountMoney?: InputMaybe<MoneyFilterInput>;
  and?: InputMaybe<Array<RefundFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  currency?: InputMaybe<CurrencyCodeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<RefundFilterInput>>;
  payment?: InputMaybe<PaymentFilterInput>;
  paymentId?: InputMaybe<ComparableGuidOperationFilterInput>;
  reason?: InputMaybe<NullableOfRefundReasonOperationFilterInput>;
  shopRefunds?: InputMaybe<ListFilterInputTypeOfShopRefundFilterInput>;
  status?: InputMaybe<RefundStatusOperationFilterInput>;
};

export enum RefundReason {
  DUPLICATE = 'DUPLICATE',
  FRAUDULENT = 'FRAUDULENT',
  REQUESTED_BY_CUSTOMER = 'REQUESTED_BY_CUSTOMER'
}

/** A connection to a list of items. */
export type RefundsConnection = {
  __typename?: 'RefundsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<RefundsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Refund>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RefundsEdge = {
  __typename?: 'RefundsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Refund>;
};

export enum RefundStatus {
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED'
}

export type RefundStatusOperationFilterInput = {
  eq?: InputMaybe<RefundStatus>;
  in?: InputMaybe<Array<RefundStatus>>;
  neq?: InputMaybe<RefundStatus>;
  nin?: InputMaybe<Array<RefundStatus>>;
};

export type ReleaseInventoryError = ReservationNotFoundError;

export type ReleaseInventoryInput = {
  reservationId: Scalars['ID'];
};

export type ReleaseInventoryPayload = {
  __typename?: 'ReleaseInventoryPayload';
  errors?: Maybe<Array<ReleaseInventoryError>>;
  reservation?: Maybe<Reservation>;
};

export type RemoveItemFromCartInput = {
  cartItemId: Scalars['ID'];
};

export type RemoveItemFromCartPayload = {
  __typename?: 'RemoveItemFromCartPayload';
  cartItem?: Maybe<CartItem>;
};

export type RemoveItemFromCartPosInput = {
  cartItemId: Scalars['ID'];
  customerId: Scalars['ID'];
};

export type RemoveItemFromCartPosPayload = {
  __typename?: 'RemoveItemFromCartPosPayload';
  cartItem?: Maybe<CartItem>;
};

export type RemoveLocationInventoryError = LocationInventoryNotFoundError;

export type RemoveLocationInventoryInput = {
  locationInventoryId: Scalars['ID'];
};

export type RemoveLocationInventoryPayload = {
  __typename?: 'RemoveLocationInventoryPayload';
  errors?: Maybe<Array<RemoveLocationInventoryError>>;
  locationInventory?: Maybe<LocationInventory>;
};

export type RemoveOptionValueError = OptionNotFoundError;

export type RemoveOptionValueInput = {
  optionId: Scalars['ID'];
  optionValueId: Scalars['ID'];
};

export type RemoveOptionValuePayload = {
  __typename?: 'RemoveOptionValuePayload';
  errors?: Maybe<Array<RemoveOptionValueError>>;
  optionValue?: Maybe<OptionValue>;
};

export type RemoveVariantOptionValueInput = {
  optionValueId: Scalars['ID'];
  variantId: Scalars['ID'];
};

export type RemoveVariantOptionValuePayload = {
  __typename?: 'RemoveVariantOptionValuePayload';
  variant?: Maybe<Variant>;
};

export type RequestEmailVerificationInput = {
  email: Scalars['String'];
};

export type RequestEmailVerificationPayload = {
  __typename?: 'RequestEmailVerificationPayload';
  emailVerificationResponse?: Maybe<EmailVerificationResponse>;
};

export type Reservation = Node & {
  __typename?: 'Reservation';
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  inventoryItem: InventoryItem;
  inventoryItemId: Scalars['ID'];
  quantity: Scalars['NonNegativeInt'];
  reason: ReservationReason;
  reservedQuantities: Array<ReservedQuantity>;
};

export type ReservationNotFoundError = Error & {
  __typename?: 'ReservationNotFoundError';
  message: Scalars['String'];
  reservationId: Scalars['ID'];
};

export enum ReservationReason {
  ADDED_TO_CART = 'ADDED_TO_CART',
  INVENTORY_HOLD = 'INVENTORY_HOLD',
  ORDER_PLACED = 'ORDER_PLACED',
  TEMPORARY_HOLD = 'TEMPORARY_HOLD'
}

/** A connection to a list of items. */
export type ReservationsByInventoryItemConnection = {
  __typename?: 'ReservationsByInventoryItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ReservationsByInventoryItemEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Reservation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ReservationsByInventoryItemEdge = {
  __typename?: 'ReservationsByInventoryItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Reservation;
};

/** A connection to a list of items. */
export type ReservationsByLocationInventoryConnection = {
  __typename?: 'ReservationsByLocationInventoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ReservationsByLocationInventoryEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Reservation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ReservationsByLocationInventoryEdge = {
  __typename?: 'ReservationsByLocationInventoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Reservation;
};

/** A connection to a list of items. */
export type ReservationsConnection = {
  __typename?: 'ReservationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ReservationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Reservation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ReservationsEdge = {
  __typename?: 'ReservationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Reservation;
};

export type ReservedQuantity = {
  __typename?: 'ReservedQuantity';
  locationInventory: LocationInventory;
  locationInventoryId: Scalars['ID'];
  quantity: Scalars['NonNegativeInt'];
};

export type ReserveInventoryError =
  | InvalidReservationExpirationError
  | InvalidReservationQuantityError
  | InventoryItemNotFoundError
  | OutOfStockError;

export type ReserveInventoryInput = {
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  inventoryItemId: Scalars['ID'];
  reason: ReservationReason;
  reserveQuantities: Array<ReserveQuantityInput>;
};

export type ReserveInventoryPayload = {
  __typename?: 'ReserveInventoryPayload';
  errors?: Maybe<Array<ReserveInventoryError>>;
  reservation?: Maybe<Reservation>;
};

export type ReserveQuantityInput = {
  locationInventoryId: Scalars['ID'];
  quantity: Scalars['NonNegativeInt'];
};

export type ResponseMessage = {
  __typename?: 'ResponseMessage';
  code?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type SearchCategoriesConnection = {
  __typename?: 'SearchCategoriesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SearchCategoriesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Category>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SearchCategoriesEdge = {
  __typename?: 'SearchCategoriesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Category;
};

/** A connection to a list of items. */
export type SearchOrdersConnection = {
  __typename?: 'SearchOrdersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SearchOrdersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Order>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SearchOrdersEdge = {
  __typename?: 'SearchOrdersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Order;
};

/** A connection to a list of items. */
export type SearchProductsByShopConnection = {
  __typename?: 'SearchProductsByShopConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SearchProductsByShopEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Product>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SearchProductsByShopEdge = {
  __typename?: 'SearchProductsByShopEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Product;
};

/** A connection to a list of items. */
export type SearchProductsConnection = {
  __typename?: 'SearchProductsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SearchProductsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Product>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SearchProductsEdge = {
  __typename?: 'SearchProductsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Product;
};

/** A connection to a list of items. */
export type SearchShopOrdersByShopConnection = {
  __typename?: 'SearchShopOrdersByShopConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SearchShopOrdersByShopEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShopOrder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SearchShopOrdersByShopEdge = {
  __typename?: 'SearchShopOrdersByShopEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShopOrder;
};

/** A connection to a list of items. */
export type SearchShopsByUsersConnection = {
  __typename?: 'SearchShopsByUsersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SearchShopsByUsersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Shop>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SearchShopsByUsersEdge = {
  __typename?: 'SearchShopsByUsersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Shop;
};

/** A connection to a list of items. */
export type SearchShopsConnection = {
  __typename?: 'SearchShopsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SearchShopsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Shop>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SearchShopsEdge = {
  __typename?: 'SearchShopsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Shop;
};

/** A connection to a list of items. */
export type SearchShowsByShopConnection = {
  __typename?: 'SearchShowsByShopConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SearchShowsByShopEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Show>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SearchShowsByShopEdge = {
  __typename?: 'SearchShowsByShopEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Show;
};

/** A connection to a list of items. */
export type SearchShowsConnection = {
  __typename?: 'SearchShowsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SearchShowsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Show>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SearchShowsEdge = {
  __typename?: 'SearchShowsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Show;
};

/** A connection to a list of items. */
export type SearchTaxCategoriesConnection = {
  __typename?: 'SearchTaxCategoriesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SearchTaxCategoriesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<TaxCategory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SearchTaxCategoriesEdge = {
  __typename?: 'SearchTaxCategoriesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TaxCategory;
};

export type SelectCheckoutShipmentShippingRateError =
  | CheckoutNotFoundError
  | CheckoutShipmentNotFoundError
  | CheckoutShippingRateNotFoundError;

export type SelectCheckoutShipmentShippingRateInput = {
  checkoutId: Scalars['ID'];
  checkoutShipmentId: Scalars['ID'];
  checkoutShippingRateId: Scalars['ID'];
};

export type SelectCheckoutShipmentShippingRatePayload = {
  __typename?: 'SelectCheckoutShipmentShippingRatePayload';
  checkout?: Maybe<Checkout>;
  errors?: Maybe<Array<SelectCheckoutShipmentShippingRateError>>;
};

export type SelectOption = Node &
  Option & {
    __typename?: 'SelectOption';
    id: Scalars['ID'];
    name: Scalars['String'];
    optionValues?: Maybe<OptionValuesConnection>;
    order: Scalars['Int'];
    product: Product;
    productId: Scalars['ID'];
    required: Scalars['Boolean'];
  };

export type SelectOptionOptionValuesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type SelectOptionInput = {
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  required: Scalars['Boolean'];
  values?: InputMaybe<Array<OptionValueInput>>;
};

/** A connection to a list of items. */
export type SellerCategoriesConnection = {
  __typename?: 'SellerCategoriesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SellerCategoriesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Category>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SellerCategoriesEdge = {
  __typename?: 'SellerCategoriesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Category;
};

export type SendConfirmationEmailToCustomerPosError = CustomerEmailRequiredError;

export type SendConfirmationEmailToCustomerPosInput = {
  customerEmail: Scalars['String'];
  customerId: Scalars['ID'];
  orderId: Scalars['ID'];
};

export type SendConfirmationEmailToCustomerPosPayload = {
  __typename?: 'SendConfirmationEmailToCustomerPOSPayload';
  errors?: Maybe<Array<SendConfirmationEmailToCustomerPosError>>;
  order?: Maybe<Order>;
};

export type SendVerificationCodeInput = {
  phoneNumber: Scalars['String'];
};

export type SendVerificationCodePayload = {
  __typename?: 'SendVerificationCodePayload';
  phoneNumberVerificationResponse?: Maybe<PhoneNumberVerificationResponse>;
};

export type ServiceLevelWithParent = {
  __typename?: 'ServiceLevelWithParent';
  extendedToken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentServicelevel?: Maybe<ParentServicelevel>;
  terms?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type SetupShopError = InvalidAddressError;

export type SetupShopInput = {
  billingAddress?: InputMaybe<AddressInput>;
  contactAddress?: InputMaybe<AddressInput>;
  contactEmail?: InputMaybe<Scalars['EmailAddress']>;
  isCarierTnCAccepted?: InputMaybe<Scalars['Boolean']>;
  isLocalPickupEnabled?: InputMaybe<Scalars['Boolean']>;
  isSellerGuidelinesAgreed?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['PhoneNumber']>;
  shippingAddress?: InputMaybe<AddressInput>;
  shippingSameAsDefault?: InputMaybe<Scalars['Boolean']>;
  shopId: Scalars['ID'];
  shopType?: InputMaybe<ShopSellerType>;
};

export type SetupShopPayload = {
  __typename?: 'SetupShopPayload';
  errors?: Maybe<Array<SetupShopError>>;
  shop?: Maybe<Shop>;
};

export type ShipmentRatesError = CannotFulfillQuantityError;

export type ShipmentRatesInput = {
  orderPackageId: Scalars['ID'];
  shopOrderId: Scalars['ID'];
};

export type ShipmentRatesPayload = {
  __typename?: 'ShipmentRatesPayload';
  errors?: Maybe<Array<ShipmentRatesError>>;
  rate?: Maybe<Array<Rate>>;
};

export enum ShippingCarrier {
  ACCESS_WORLDWIDE = 'ACCESS_WORLDWIDE',
  APC = 'APC',
  ASENDIA = 'ASENDIA',
  AUSTRALIA_POST = 'AUSTRALIA_POST',
  CANPAR = 'CANPAR',
  DHL_ECOMMERCE = 'DHL_ECOMMERCE',
  DHL_EXPRESS = 'DHL_EXPRESS',
  DHL_EXPRESS_AU = 'DHL_EXPRESS_AU',
  DHL_EXPRESS_CA = 'DHL_EXPRESS_CA',
  DHL_EXPRESS_UK = 'DHL_EXPRESS_UK',
  DPD = 'DPD',
  DPD_LOCAL = 'DPD_LOCAL',
  ENDICIA = 'ENDICIA',
  FASTWAY_AU = 'FASTWAY_AU',
  FEDEX = 'FEDEX',
  FEDEX_UK = 'FEDEX_UK',
  FIRST_MILE = 'FIRST_MILE',
  GLOBEGISTICS = 'GLOBEGISTICS',
  HERMES = 'HERMES',
  LANDMARK_GLOBAL = 'LANDMARK_GLOBAL',
  NEWGISTICS = 'NEWGISTICS',
  ON_TRAC = 'ON_TRAC',
  PARCELFORCE = 'PARCELFORCE',
  PUROLATOR_CANADA = 'PUROLATOR_CANADA',
  ROYAL_MAIL = 'ROYAL_MAIL',
  RR_DONNELLEY = 'RR_DONNELLEY',
  SEKO = 'SEKO',
  SENDLE = 'SENDLE',
  STAMPS_COM = 'STAMPS_COM',
  STAR_TRACK = 'STAR_TRACK',
  UPS = 'UPS',
  USPS = 'USPS'
}

export type ShippingCarrierAccount = Node & {
  __typename?: 'ShippingCarrierAccount';
  accountId?: Maybe<Scalars['String']>;
  carrierAccountExternalIds?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  hiddenPackages?: Maybe<Array<Scalars['UUID']>>;
  id: Scalars['ID'];
  shop?: Maybe<Shop>;
  shopId: Scalars['ID'];
};

export type ShippingCarrierAccountFilterInput = {
  accountId?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<ShippingCarrierAccountFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ShippingCarrierAccountFilterInput>>;
};

/** A connection to a list of items. */
export type ShippingCarrierAccountsByShopConnection = {
  __typename?: 'ShippingCarrierAccountsByShopConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShippingCarrierAccountsByShopEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShippingCarrierAccount>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShippingCarrierAccountsByShopEdge = {
  __typename?: 'ShippingCarrierAccountsByShopEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShippingCarrierAccount;
};

export type ShippingCarrierAccountSortInput = {
  accountId?: InputMaybe<OrderDirection>;
  description?: InputMaybe<OrderDirection>;
};

export type ShippingCarrierPackage = Node & {
  __typename?: 'ShippingCarrierPackage';
  description?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Dimensions>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  maxDimensions?: Maybe<Dimensions>;
  maxGirthAndLength?: Maybe<Length>;
  maxWeight?: Maybe<Weight>;
  minDimensions?: Maybe<Dimensions>;
  minWeight?: Maybe<Weight>;
  name: Scalars['String'];
  packageType: ShippingPackageType;
  shippingCarrierPackageServices?: Maybe<ShippingCarrierPackageServicesConnection>;
  shippingCarrierServices?: Maybe<ShippingCarrierServicesConnection>;
  tag?: Maybe<Scalars['String']>;
};

export type ShippingCarrierPackageShippingCarrierPackageServicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShippingCarrierPackageServiceFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShippingCarrierPackageServiceSortInput>>;
};

export type ShippingCarrierPackageShippingCarrierServicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShippingCarrierServiceFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShippingCarrierServiceSortInput>>;
};

export type ShippingCarrierPackageFilterInput = {
  and?: InputMaybe<Array<ShippingCarrierPackageFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  dimensions?: InputMaybe<DimensionsFilterInput>;
  maxDimensions?: InputMaybe<DimensionsFilterInput>;
  maxGirthAndLength?: InputMaybe<LengthFilterInput>;
  maxWeight?: InputMaybe<WeightFilterInput>;
  minDimensions?: InputMaybe<DimensionsFilterInput>;
  minWeight?: InputMaybe<WeightFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ShippingCarrierPackageFilterInput>>;
};

/** A connection to a list of items. */
export type ShippingCarrierPackagesByShippingCarrierConnection = {
  __typename?: 'ShippingCarrierPackagesByShippingCarrierConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShippingCarrierPackagesByShippingCarrierEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShippingCarrierPackage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShippingCarrierPackagesByShippingCarrierEdge = {
  __typename?: 'ShippingCarrierPackagesByShippingCarrierEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShippingCarrierPackage;
};

/** A connection to a list of items. */
export type ShippingCarrierPackagesConnection = {
  __typename?: 'ShippingCarrierPackagesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShippingCarrierPackagesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShippingCarrierPackage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShippingCarrierPackagesEdge = {
  __typename?: 'ShippingCarrierPackagesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShippingCarrierPackage;
};

export type ShippingCarrierPackageService = {
  __typename?: 'ShippingCarrierPackageService';
  maxWeight?: Maybe<Weight>;
  shippingCarrierPackage?: Maybe<ShippingCarrierPackage>;
  shippingCarrierPackageId: Scalars['ID'];
  shippingCarrierService?: Maybe<ShippingCarrierService>;
  shippingCarrierServiceId: Scalars['ID'];
};

export type ShippingCarrierPackageServiceFilterInput = {
  and?: InputMaybe<Array<ShippingCarrierPackageServiceFilterInput>>;
  maxWeight?: InputMaybe<WeightFilterInput>;
  or?: InputMaybe<Array<ShippingCarrierPackageServiceFilterInput>>;
};

/** A connection to a list of items. */
export type ShippingCarrierPackageServicesConnection = {
  __typename?: 'ShippingCarrierPackageServicesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShippingCarrierPackageServicesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShippingCarrierPackageService>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShippingCarrierPackageServicesEdge = {
  __typename?: 'ShippingCarrierPackageServicesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShippingCarrierPackageService;
};

export type ShippingCarrierPackageServiceSortInput = {
  maxWeight?: InputMaybe<WeightSortInput>;
};

export type ShippingCarrierPackageSortInput = {
  description?: InputMaybe<OrderDirection>;
  dimensions?: InputMaybe<DimensionsSortInput>;
  maxDimensions?: InputMaybe<DimensionsSortInput>;
  maxGirthAndLength?: InputMaybe<LengthSortInput>;
  maxWeight?: InputMaybe<WeightSortInput>;
  minDimensions?: InputMaybe<DimensionsSortInput>;
  minWeight?: InputMaybe<WeightSortInput>;
  name?: InputMaybe<OrderDirection>;
};

export type ShippingCarrierService = Node & {
  __typename?: 'ShippingCarrierService';
  description?: Maybe<Scalars['String']>;
  domestic: Scalars['Boolean'];
  id: Scalars['ID'];
  international: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['URL']>;
  maxGirthAndLength?: Maybe<Length>;
  maxLength?: Maybe<Length>;
  maxWeight?: Maybe<Weight>;
  name: Scalars['String'];
  shippingCarrierPackages?: Maybe<ShippingCarrierPackagesConnection>;
  shippingCarrierPackageServices?: Maybe<ShippingCarrierPackageServicesConnection>;
};

export type ShippingCarrierServiceShippingCarrierPackagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShippingCarrierPackageFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShippingCarrierPackageSortInput>>;
};

export type ShippingCarrierServiceShippingCarrierPackageServicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShippingCarrierPackageServiceFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShippingCarrierPackageServiceSortInput>>;
};

export type ShippingCarrierServiceFilterInput = {
  and?: InputMaybe<Array<ShippingCarrierServiceFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  domestic?: InputMaybe<BooleanOperationFilterInput>;
  international?: InputMaybe<BooleanOperationFilterInput>;
  logoUrl?: InputMaybe<UriFilterInput>;
  maxGirthAndLength?: InputMaybe<LengthFilterInput>;
  maxLength?: InputMaybe<LengthFilterInput>;
  maxWeight?: InputMaybe<WeightFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ShippingCarrierServiceFilterInput>>;
};

/** A connection to a list of items. */
export type ShippingCarrierServicesByShippingCarrierConnection = {
  __typename?: 'ShippingCarrierServicesByShippingCarrierConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShippingCarrierServicesByShippingCarrierEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShippingCarrierService>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShippingCarrierServicesByShippingCarrierEdge = {
  __typename?: 'ShippingCarrierServicesByShippingCarrierEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShippingCarrierService;
};

/** A connection to a list of items. */
export type ShippingCarrierServicesConnection = {
  __typename?: 'ShippingCarrierServicesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShippingCarrierServicesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShippingCarrierService>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShippingCarrierServicesEdge = {
  __typename?: 'ShippingCarrierServicesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShippingCarrierService;
};

export type ShippingCarrierServiceSortInput = {
  description?: InputMaybe<OrderDirection>;
  domestic?: InputMaybe<OrderDirection>;
  international?: InputMaybe<OrderDirection>;
  logoUrl?: InputMaybe<UriSortInput>;
  maxGirthAndLength?: InputMaybe<LengthSortInput>;
  maxLength?: InputMaybe<LengthSortInput>;
  maxWeight?: InputMaybe<WeightSortInput>;
  name?: InputMaybe<OrderDirection>;
};

export type ShippingCustomerAddressNotFoundError = Error & {
  __typename?: 'ShippingCustomerAddressNotFoundError';
  message: Scalars['String'];
};

export type ShippingMethod = Node & {
  __typename?: 'ShippingMethod';
  default: Scalars['Boolean'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  shippingRate?: Maybe<ShippingRate>;
  shippingRateId: Scalars['ID'];
  shippingZone?: Maybe<ShippingZone>;
  shippingZoneId: Scalars['ID'];
};

export type ShippingMethodFilterInput = {
  and?: InputMaybe<Array<ShippingMethodFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ShippingMethodFilterInput>>;
};

/** A connection to a list of items. */
export type ShippingMethodsByShippingProfileConnection = {
  __typename?: 'ShippingMethodsByShippingProfileConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShippingMethodsByShippingProfileEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShippingMethod>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShippingMethodsByShippingProfileEdge = {
  __typename?: 'ShippingMethodsByShippingProfileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShippingMethod;
};

/** A connection to a list of items. */
export type ShippingMethodsByShippingProfileGroupConnection = {
  __typename?: 'ShippingMethodsByShippingProfileGroupConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShippingMethodsByShippingProfileGroupEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShippingMethod>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShippingMethodsByShippingProfileGroupEdge = {
  __typename?: 'ShippingMethodsByShippingProfileGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShippingMethod;
};

/** A connection to a list of items. */
export type ShippingMethodsByShippingZoneConnection = {
  __typename?: 'ShippingMethodsByShippingZoneConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShippingMethodsByShippingZoneEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShippingMethod>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShippingMethodsByShippingZoneEdge = {
  __typename?: 'ShippingMethodsByShippingZoneEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShippingMethod;
};

/** A connection to a list of items. */
export type ShippingMethodsConnection = {
  __typename?: 'ShippingMethodsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShippingMethodsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShippingMethod>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShippingMethodsEdge = {
  __typename?: 'ShippingMethodsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShippingMethod;
};

export type ShippingMethodSortInput = {
  description?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
};

export type ShippingPackageResponse = {
  __typename?: 'ShippingPackageResponse';
  carrierPackages: Array<ShippingCarrierPackage>;
  userPackages?: Maybe<Array<ShippingUserPackage>>;
};

export enum ShippingPackageType {
  BOX = 'BOX',
  ENVELOPE = 'ENVELOPE',
  POLY_BAG = 'POLY_BAG'
}

export type ShippingPackageTypeOperationFilterInput = {
  eq?: InputMaybe<ShippingPackageType>;
  in?: InputMaybe<Array<ShippingPackageType>>;
  neq?: InputMaybe<ShippingPackageType>;
  nin?: InputMaybe<Array<ShippingPackageType>>;
};

export type ShippingProfile = Node & {
  __typename?: 'ShippingProfile';
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  shippingProfileGroups?: Maybe<ShippingProfileGroupsConnection>;
  shop?: Maybe<Shop>;
  shopId: Scalars['ID'];
  shopLocations?: Maybe<ShopLocationsConnection>;
  variants?: Maybe<VariantsConnection>;
};

export type ShippingProfileShippingProfileGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ShippingProfileShopLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShopLocationFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShopLocationSortInput>>;
};

export type ShippingProfileVariantsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<VariantFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<VariantSortInput>>;
};

export type ShippingProfileFilterInput = {
  and?: InputMaybe<Array<ShippingProfileFilterInput>>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ShippingProfileFilterInput>>;
};

export type ShippingProfileGroup = Node & {
  __typename?: 'ShippingProfileGroup';
  id: Scalars['ID'];
  shippingProfileId: Scalars['ID'];
  shippingZones?: Maybe<ShippingZonesConnection>;
  shopLocations?: Maybe<ShopLocationsConnection>;
};

export type ShippingProfileGroupShippingZonesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShippingZoneFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShippingZoneSortInput>>;
};

export type ShippingProfileGroupShopLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShopLocationFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShopLocationSortInput>>;
};

/** A connection to a list of items. */
export type ShippingProfileGroupsByProfileConnection = {
  __typename?: 'ShippingProfileGroupsByProfileConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShippingProfileGroupsByProfileEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShippingProfileGroup>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShippingProfileGroupsByProfileEdge = {
  __typename?: 'ShippingProfileGroupsByProfileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShippingProfileGroup;
};

/** A connection to a list of items. */
export type ShippingProfileGroupsConnection = {
  __typename?: 'ShippingProfileGroupsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShippingProfileGroupsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShippingProfileGroup>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShippingProfileGroupsEdge = {
  __typename?: 'ShippingProfileGroupsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShippingProfileGroup;
};

export type ShippingProfileGroupSortInput = {
  id?: InputMaybe<OrderDirection>;
  shippingProfile?: InputMaybe<ShippingProfileSortInput>;
  shippingProfileId?: InputMaybe<OrderDirection>;
};

export type ShippingProfileNotFoundError = Error & {
  __typename?: 'ShippingProfileNotFoundError';
  message: Scalars['String'];
};

/** A connection to a list of items. */
export type ShippingProfilesByShopConnection = {
  __typename?: 'ShippingProfilesByShopConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShippingProfilesByShopEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShippingProfile>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShippingProfilesByShopEdge = {
  __typename?: 'ShippingProfilesByShopEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShippingProfile;
};

/** A connection to a list of items. */
export type ShippingProfilesConnection = {
  __typename?: 'ShippingProfilesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShippingProfilesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShippingProfile>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShippingProfilesEdge = {
  __typename?: 'ShippingProfilesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShippingProfile;
};

export type ShippingProfileSortInput = {
  name?: InputMaybe<OrderDirection>;
};

export enum ShippingProvider {
  SHIPENGINE = 'SHIPENGINE',
  SHIPPO = 'SHIPPO'
}

export type ShippingProviderOperationFilterInput = {
  eq?: InputMaybe<ShippingProvider>;
  in?: InputMaybe<Array<ShippingProvider>>;
  neq?: InputMaybe<ShippingProvider>;
  nin?: InputMaybe<Array<ShippingProvider>>;
};

export type ShippingRate = {
  id: Scalars['ID'];
  shopId: Scalars['ID'];
};

export type ShippingRegion = {
  __typename?: 'ShippingRegion';
  country?: Maybe<CountryCode>;
  restOfWorld: Scalars['Boolean'];
  shippingZone?: Maybe<ShippingZone>;
  shippingZoneId: Scalars['ID'];
  stateProvinces: Array<StateProvinceCode>;
};

export type ShippingRegionFilterInput = {
  and?: InputMaybe<Array<ShippingRegionFilterInput>>;
  country?: InputMaybe<NullableOfCountryCodeOperationFilterInput>;
  or?: InputMaybe<Array<ShippingRegionFilterInput>>;
};

/** A connection to a list of items. */
export type ShippingRegionsConnection = {
  __typename?: 'ShippingRegionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShippingRegionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShippingRegion>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShippingRegionsEdge = {
  __typename?: 'ShippingRegionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShippingRegion;
};

export type ShippingRegionSortInput = {
  country?: InputMaybe<OrderDirection>;
};

export type ShippingUserPackage = Node & {
  __typename?: 'ShippingUserPackage';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Dimensions>;
  id: Scalars['ID'];
  name: Scalars['String'];
  packageType: ShippingPackageType;
  shippingProvider: ShippingProvider;
  shippingProviderObjectId?: Maybe<Scalars['String']>;
  shop: Shop;
  shopId: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Weight>;
};

export type ShippingUserPackageFilterInput = {
  and?: InputMaybe<Array<ShippingUserPackageFilterInput>>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  dimensions?: InputMaybe<DimensionsFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ShippingUserPackageFilterInput>>;
  packageType?: InputMaybe<ShippingPackageTypeOperationFilterInput>;
  shippingProvider?: InputMaybe<ShippingProviderOperationFilterInput>;
  shippingProviderObjectId?: InputMaybe<StringOperationFilterInput>;
  shop?: InputMaybe<ShopFilterInput>;
  shopId?: InputMaybe<ComparableGuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  weight?: InputMaybe<WeightFilterInput>;
};

export type ShippingUserPackageSortInput = {
  createdAt?: InputMaybe<OrderDirection>;
  description?: InputMaybe<OrderDirection>;
  dimensions?: InputMaybe<DimensionsSortInput>;
  id?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
  packageType?: InputMaybe<OrderDirection>;
  shippingProvider?: InputMaybe<OrderDirection>;
  shippingProviderObjectId?: InputMaybe<OrderDirection>;
  shop?: InputMaybe<ShopSortInput>;
  shopId?: InputMaybe<OrderDirection>;
  updatedAt?: InputMaybe<OrderDirection>;
  weight?: InputMaybe<WeightSortInput>;
};

export type ShippingZone = Node & {
  __typename?: 'ShippingZone';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  shippingMethods?: Maybe<ShippingMethodsConnection>;
  shippingProfileGroup?: Maybe<ShippingProfileGroup>;
  shippingProfileGroupId: Scalars['ID'];
  shippingRegions?: Maybe<ShippingRegionsConnection>;
};

export type ShippingZoneShippingMethodsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShippingMethodFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShippingMethodSortInput>>;
};

export type ShippingZoneShippingRegionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShippingRegionFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShippingRegionSortInput>>;
};

export type ShippingZoneFilterInput = {
  and?: InputMaybe<Array<ShippingZoneFilterInput>>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ShippingZoneFilterInput>>;
};

/** A connection to a list of items. */
export type ShippingZonesByGroupConnection = {
  __typename?: 'ShippingZonesByGroupConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShippingZonesByGroupEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShippingZone>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShippingZonesByGroupEdge = {
  __typename?: 'ShippingZonesByGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShippingZone;
};

/** A connection to a list of items. */
export type ShippingZonesConnection = {
  __typename?: 'ShippingZonesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShippingZonesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShippingZone>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShippingZonesEdge = {
  __typename?: 'ShippingZonesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShippingZone;
};

export type ShippingZoneSortInput = {
  name?: InputMaybe<OrderDirection>;
};

export type Shop = Node & {
  __typename?: 'Shop';
  address: Address;
  affiliateShows: Array<Show>;
  archived: Scalars['Boolean'];
  categories: Array<Category>;
  contactEmail?: Maybe<Scalars['EmailAddress']>;
  country: CountryCode;
  createdAt: Scalars['DateTime'];
  defaultCommission?: Maybe<Scalars['Decimal']>;
  defaultShippingProfile?: Maybe<ShippingProfile>;
  defaultShippingProfileId?: Maybe<Scalars['ID']>;
  defaultShopLocation?: Maybe<ShopLocation>;
  defaultShopLocationId?: Maybe<Scalars['ID']>;
  description: Scalars['String'];
  fbBusinessAccount?: Maybe<FbBusinessAccount>;
  fbPages?: Maybe<FbPagesConnection>;
  featuredProducts?: Maybe<Array<Product>>;
  id: Scalars['ID'];
  inShopQRCodeDownloadUrl?: Maybe<Scalars['String']>;
  integrations: Array<Integration>;
  inventoryItems?: Maybe<InventoryItemsConnection>;
  isActive: Scalars['Boolean'];
  isCarierTnCAccepted: Scalars['Boolean'];
  isSameAsSeller?: Maybe<Scalars['Boolean']>;
  isSellerGuideLinesAccepted: Scalars['Boolean'];
  legalBusinessName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paymentAccounts?: Maybe<PaymentAccountsConnection>;
  phone?: Maybe<Scalars['PhoneNumber']>;
  plaidLinks: Array<PlaidLink>;
  products?: Maybe<ProductsConnection>;
  shippingCarrierAccounts: Array<ShippingCarrierAccount>;
  shippingProfiles?: Maybe<ShippingProfilesConnection>;
  shopCategories?: Maybe<ShopCategoriesConnection>;
  shopCustomizations: Array<ShopCustomization>;
  shopFbPages?: Maybe<Scalars['String']>;
  shopImages?: Maybe<ShopImagesConnection>;
  shopLicenceUrl?: Maybe<Scalars['String']>;
  shopLocations?: Maybe<ShopLocationsConnection>;
  shopOrders?: Maybe<ShopOrdersConnection>;
  shopSetupLastCompleted?: Maybe<ShopSetupStatus>;
  shopShoppingCategories?: Maybe<ShopShoppingCategoriesConnection>;
  shopType: ShopSellerType;
  shopUsers?: Maybe<ShopUsersConnection>;
  shows?: Maybe<ShowsConnection>;
  slug: Scalars['String'];
  totalShopSales: Money;
  updatedAt: Scalars['DateTime'];
};

export type ShopFbPagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type ShopInventoryItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type ShopPaymentAccountsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ShopProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ProductSortInput>>;
};

export type ShopShippingProfilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShippingProfileFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShippingProfileSortInput>>;
};

export type ShopShopCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ShopShopImagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShopImageFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShopImageSortInput>>;
};

export type ShopShopLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShopLocationFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShopLocationSortInput>>;
};

export type ShopShopOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShopOrderFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShopOrderSortInput>>;
};

export type ShopShopShoppingCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ShopShopUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ShopShowsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShowFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShowSortInput>>;
};

/** A connection to a list of items. */
export type ShopCategoriesConnection = {
  __typename?: 'ShopCategoriesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShopCategoriesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShopCategory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShopCategoriesEdge = {
  __typename?: 'ShopCategoriesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShopCategory;
};

export type ShopCategory = {
  __typename?: 'ShopCategory';
  category?: Maybe<Category>;
  categoryId: Scalars['ID'];
  shop?: Maybe<Shop>;
  shopId: Scalars['ID'];
};

export type ShopConnectionRequest = {
  __typename?: 'ShopConnectionRequest';
  connectionStatus: ShopConnectionStatus;
  createdAt: Scalars['DateTime'];
  shop?: Maybe<Shop>;
  shopId: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId: Scalars['UUID'];
};

export enum ShopConnectionStatus {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  INVITED = 'INVITED',
  REQUESTED = 'REQUESTED'
}

export type ShopCustomization = {
  __typename?: 'ShopCustomization';
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  shop: Shop;
  shopCoverPhoto?: Maybe<Image>;
  shopCoverPhotoId?: Maybe<Scalars['UUID']>;
  shopDescription?: Maybe<Scalars['String']>;
  shopId: Scalars['UUID'];
  shopProfileImage?: Maybe<Image>;
  shopProfileImageId?: Maybe<Scalars['UUID']>;
  updatedAt: Scalars['DateTime'];
};

export type ShopFilterInput = {
  and?: InputMaybe<Array<ShopFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ShopFilterInput>>;
  shopType?: InputMaybe<ShopSellerTypeOperationFilterInput>;
  slug?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

export type ShopImage = {
  __typename?: 'ShopImage';
  id: Scalars['UUID'];
  image?: Maybe<Image>;
  imageId: Scalars['UUID'];
  isCoverPhoto: Scalars['Boolean'];
  isProfileImage: Scalars['Boolean'];
  shop?: Maybe<Shop>;
  shopId: Scalars['UUID'];
};

export type ShopImageFilterInput = {
  and?: InputMaybe<Array<ShopImageFilterInput>>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isCoverPhoto?: InputMaybe<BooleanOperationFilterInput>;
  isProfileImage?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ShopImageFilterInput>>;
  shop?: InputMaybe<ShopFilterInput>;
  shopId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

/** A connection to a list of items. */
export type ShopImagesConnection = {
  __typename?: 'ShopImagesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShopImagesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShopImage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShopImagesEdge = {
  __typename?: 'ShopImagesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShopImage;
};

export type ShopImageSortInput = {
  id?: InputMaybe<OrderDirection>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<OrderDirection>;
  isCoverPhoto?: InputMaybe<OrderDirection>;
  isProfileImage?: InputMaybe<OrderDirection>;
  shop?: InputMaybe<ShopSortInput>;
  shopId?: InputMaybe<OrderDirection>;
};

export type ShopLocation = Node & {
  __typename?: 'ShopLocation';
  address: Address;
  archived: Scalars['Boolean'];
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  isBillingAddress: Scalars['Boolean'];
  isContactAddress: Scalars['Boolean'];
  isShippingAddress: Scalars['Boolean'];
  locationType: ShopLocationAddressType;
  name: Scalars['String'];
  shop?: Maybe<Shop>;
  shopId: Scalars['ID'];
};

export enum ShopLocationAddressType {
  BILLING = 'BILLING',
  CONTACT = 'CONTACT',
  SHIPPING = 'SHIPPING'
}

export type ShopLocationFilterInput = {
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<ShopLocationFilterInput>>;
  archived?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ShopLocationFilterInput>>;
};

export type ShopLocationNotFoundError = Error & {
  __typename?: 'ShopLocationNotFoundError';
  message: Scalars['String'];
  shopLocationId: Scalars['ID'];
};

/** A connection to a list of items. */
export type ShopLocationsByShopConnection = {
  __typename?: 'ShopLocationsByShopConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShopLocationsByShopEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShopLocation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShopLocationsByShopEdge = {
  __typename?: 'ShopLocationsByShopEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShopLocation;
};

/** A connection to a list of items. */
export type ShopLocationsConnection = {
  __typename?: 'ShopLocationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShopLocationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShopLocation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShopLocationsEdge = {
  __typename?: 'ShopLocationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShopLocation;
};

export type ShopLocationSortInput = {
  address?: InputMaybe<AddressSortInput>;
  archived?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
};

export type ShopNotFoundError = Error & {
  __typename?: 'ShopNotFoundError';
  message: Scalars['String'];
};

export type ShopOrder = Node & {
  __typename?: 'ShopOrder';
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  currency: CurrencyCode;
  currentSalesTaxTotal: Money;
  currentSubtotal: Money;
  currentTotal: Money;
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['ID']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  fulfilledAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  labelCreatedAt?: Maybe<Scalars['DateTime']>;
  order: Order;
  orderFulfilmentlineItems?: Maybe<OrderFulfilmentlineItemsConnection>;
  orderId: Scalars['ID'];
  orderLineItems?: Maybe<OrderLineItemsConnection>;
  orderPackages?: Maybe<OrderPackagesConnection>;
  orderShipments?: Maybe<OrderShipmentsConnection>;
  packingSlipUrl?: Maybe<Scalars['String']>;
  packingSlipUrlExpiry?: Maybe<Scalars['DateTime']>;
  phone?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['Long'];
  refundedTotal: Money;
  salesTaxTotal: Money;
  shippedAt?: Maybe<Scalars['DateTime']>;
  shippingTotal: Money;
  shippoOrderId?: Maybe<Scalars['String']>;
  shop?: Maybe<Shop>;
  shopId?: Maybe<Scalars['ID']>;
  shopPayments?: Maybe<ShopPaymentsConnection>;
  show?: Maybe<Show>;
  showId?: Maybe<Scalars['ID']>;
  status: ShopOrderStatus;
  subtotal: Money;
  total: Money;
  updatedAt: Scalars['DateTime'];
};

export type ShopOrderOrderFulfilmentlineItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ShopOrderOrderLineItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ShopOrderOrderPackagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<OrderPackageSortInput>>;
};

export type ShopOrderOrderShipmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ShopOrderShopPaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ShopOrderFilterInput = {
  and?: InputMaybe<Array<ShopOrderFilterInput>>;
  canceledAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  currency?: InputMaybe<CurrencyCodeOperationFilterInput>;
  fulfilledAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ShopOrderFilterInput>>;
  status?: InputMaybe<ShopOrderStatusOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

/** A connection to a list of items. */
export type ShopOrdersByOrderConnection = {
  __typename?: 'ShopOrdersByOrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShopOrdersByOrderEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShopOrder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShopOrdersByOrderEdge = {
  __typename?: 'ShopOrdersByOrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShopOrder;
};

/** A connection to a list of items. */
export type ShopOrdersByShopConnection = {
  __typename?: 'ShopOrdersByShopConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShopOrdersByShopEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShopOrder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShopOrdersByShopEdge = {
  __typename?: 'ShopOrdersByShopEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShopOrder;
};

/** A connection to a list of items. */
export type ShopOrdersConnection = {
  __typename?: 'ShopOrdersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShopOrdersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<ShopOrder>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShopOrdersEdge = {
  __typename?: 'ShopOrdersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ShopOrder>;
};

export type ShopOrderShopPaymentFilterInput = {
  and?: InputMaybe<Array<ShopOrderShopPaymentFilterInput>>;
  or?: InputMaybe<Array<ShopOrderShopPaymentFilterInput>>;
  shopOrder?: InputMaybe<ShopOrderFilterInput>;
  shopOrderId?: InputMaybe<ComparableGuidOperationFilterInput>;
  shopPayment?: InputMaybe<ShopPaymentFilterInput>;
  shopPaymentId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type ShopOrderSortInput = {
  createdAt?: InputMaybe<OrderDirection>;
  currency?: InputMaybe<OrderDirection>;
  customerId?: InputMaybe<OrderDirection>;
  fulfilledAt?: InputMaybe<OrderDirection>;
  showId?: InputMaybe<OrderDirection>;
  status?: InputMaybe<OrderDirection>;
  updatedAt?: InputMaybe<OrderDirection>;
};

export enum ShopOrderStatus {
  CANCELED = 'CANCELED',
  DELIVERED = 'DELIVERED',
  DELIVERY_FAILED = 'DELIVERY_FAILED',
  FULFILLED = 'FULFILLED',
  FULFILLED_BY_SELLER = 'FULFILLED_BY_SELLER',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_TRANSIT = 'IN_TRANSIT',
  LABEL_CREATED = 'LABEL_CREATED',
  OPEN = 'OPEN',
  PACKED = 'PACKED',
  PARTIALLY_PICKED = 'PARTIALLY_PICKED',
  PARTIALLY_SHIPPED = 'PARTIALLY_SHIPPED',
  PICKED = 'PICKED',
  SHIPPED = 'SHIPPED',
  UNKNOWN = 'UNKNOWN'
}

export type ShopOrderStatusOperationFilterInput = {
  eq?: InputMaybe<ShopOrderStatus>;
  in?: InputMaybe<Array<ShopOrderStatus>>;
  neq?: InputMaybe<ShopOrderStatus>;
  nin?: InputMaybe<Array<ShopOrderStatus>>;
};

export type ShopPayment = {
  affiliateShop?: Maybe<Shop>;
  affiliateShopId?: Maybe<Scalars['ID']>;
  amount: Money;
  applicationFeeMoney: Money;
  commissionPercent?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  currency: CurrencyCode;
  errorCode?: Maybe<PaymentErrorCode>;
  id: Scalars['ID'];
  netAffiliateAmount?: Maybe<Scalars['Int']>;
  netAmountMoney?: Maybe<Money>;
  payment?: Maybe<Payment>;
  paymentId?: Maybe<Scalars['ID']>;
  paymentProviderFee: Money;
  paymentProviderFeeMoney?: Maybe<Money>;
  shippingAmountMoney: Money;
  shop: Shop;
  shopId: Scalars['ID'];
  shopOrders?: Maybe<ShopOrdersConnection>;
  shopRefunds?: Maybe<ShopRefundsConnection>;
  shopTransactions: Array<ShopTransaction>;
  show?: Maybe<Show>;
  showId?: Maybe<Scalars['UUID']>;
  status: ShopPaymentStatus;
  type: ShopPaymentType;
};

export type ShopPaymentShopOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ShopPaymentShopRefundsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ShopPaymentFilterInput = {
  affiliateShop?: InputMaybe<ShopFilterInput>;
  affiliateShopId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  amount?: InputMaybe<ComparableInt32OperationFilterInput>;
  amountMoney?: InputMaybe<MoneyFilterInput>;
  and?: InputMaybe<Array<ShopPaymentFilterInput>>;
  applicationFee?: InputMaybe<ComparableInt32OperationFilterInput>;
  applicationFeeMoney?: InputMaybe<MoneyFilterInput>;
  commissionPercent?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  currency?: InputMaybe<CurrencyCodeOperationFilterInput>;
  errorCode?: InputMaybe<NullableOfPaymentErrorCodeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  netAffiliateAmount?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  netAmount?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  netAmountMoney?: InputMaybe<MoneyFilterInput>;
  or?: InputMaybe<Array<ShopPaymentFilterInput>>;
  payment?: InputMaybe<PaymentFilterInput>;
  paymentCaptureDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  paymentId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  paymentProviderFee?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  paymentProviderFeeMoney?: InputMaybe<MoneyFilterInput>;
  salesTaxAmount?: InputMaybe<ComparableInt32OperationFilterInput>;
  salesTaxAmountMoney?: InputMaybe<MoneyFilterInput>;
  shippingAmount?: InputMaybe<ComparableInt32OperationFilterInput>;
  shippingAmountMoney?: InputMaybe<MoneyFilterInput>;
  shop?: InputMaybe<ShopFilterInput>;
  shopId?: InputMaybe<ComparableGuidOperationFilterInput>;
  shopOrders?: InputMaybe<ListFilterInputTypeOfShopOrderFilterInput>;
  shopOrderShopPayments?: InputMaybe<ListFilterInputTypeOfShopOrderShopPaymentFilterInput>;
  shopRefunds?: InputMaybe<ListFilterInputTypeOfShopRefundFilterInput>;
  shopTransactions?: InputMaybe<ListFilterInputTypeOfShopTransactionFilterInput>;
  show?: InputMaybe<ShowFilterInput>;
  showId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  status?: InputMaybe<ShopPaymentStatusOperationFilterInput>;
  type?: InputMaybe<ShopPaymentTypeOperationFilterInput>;
};

/** A connection to a list of items. */
export type ShopPaymentsConnection = {
  __typename?: 'ShopPaymentsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShopPaymentsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<ShopPayment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShopPaymentsEdge = {
  __typename?: 'ShopPaymentsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ShopPayment>;
};

export enum ShopPaymentStatus {
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED'
}

export type ShopPaymentStatusOperationFilterInput = {
  eq?: InputMaybe<ShopPaymentStatus>;
  in?: InputMaybe<Array<ShopPaymentStatus>>;
  neq?: InputMaybe<ShopPaymentStatus>;
  nin?: InputMaybe<Array<ShopPaymentStatus>>;
};

export enum ShopPaymentType {
  REFUND = 'REFUND',
  REVERSE_TRANSFER = 'REVERSE_TRANSFER',
  SALE = 'SALE',
  TRANSFER = 'TRANSFER'
}

export type ShopPaymentTypeOperationFilterInput = {
  eq?: InputMaybe<ShopPaymentType>;
  in?: InputMaybe<Array<ShopPaymentType>>;
  neq?: InputMaybe<ShopPaymentType>;
  nin?: InputMaybe<Array<ShopPaymentType>>;
};

export type ShoppingCategory = Node & {
  __typename?: 'ShoppingCategory';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ShoppingCategoryFilterInput = {
  and?: InputMaybe<Array<ShoppingCategoryFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  imageUrl?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ShoppingCategoryFilterInput>>;
};

export type ShoppingSubcategory = Node & {
  __typename?: 'ShoppingSubcategory';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  shoppingCategory: ShoppingCategory;
};

export type ShoppingSubcategoryFilterInput = {
  and?: InputMaybe<Array<ShoppingSubcategoryFilterInput>>;
  categoryId?: InputMaybe<ComparableGuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ShoppingSubcategoryFilterInput>>;
  shoppingCategory?: InputMaybe<ShoppingCategoryFilterInput>;
};

export type ShopRefund = {
  amount: Money;
  createdAt: Scalars['DateTime'];
  currency: CurrencyCode;
  id: Scalars['ID'];
  refund: Refund;
  refundId: Scalars['ID'];
  shopPayment?: Maybe<ShopPayment>;
  shopPaymentId: Scalars['ID'];
  status: RefundStatus;
};

export type ShopRefundFilterInput = {
  amount?: InputMaybe<ComparableInt32OperationFilterInput>;
  amountMoney?: InputMaybe<MoneyFilterInput>;
  and?: InputMaybe<Array<ShopRefundFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  currency?: InputMaybe<CurrencyCodeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<ShopRefundFilterInput>>;
  refund?: InputMaybe<RefundFilterInput>;
  refundId?: InputMaybe<ComparableGuidOperationFilterInput>;
  shopPayment?: InputMaybe<ShopPaymentFilterInput>;
  shopPaymentId?: InputMaybe<ComparableGuidOperationFilterInput>;
  status?: InputMaybe<RefundStatusOperationFilterInput>;
};

/** A connection to a list of items. */
export type ShopRefundsConnection = {
  __typename?: 'ShopRefundsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShopRefundsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<ShopRefund>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShopRefundsEdge = {
  __typename?: 'ShopRefundsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ShopRefund>;
};

export enum ShopRevenueType {
  ABOVE50_K = 'ABOVE50_K',
  BELOW1_K = 'BELOW1_K',
  BETWEEN1_K_TO5_K = 'BETWEEN1_K_TO5_K',
  BETWEEN20_K_TO50_K = 'BETWEEN20_K_TO50_K',
  BETWEEN5_K_TO20_K = 'BETWEEN5_K_TO20_K',
  NEW_SELLER = 'NEW_SELLER'
}

export enum ShopRole {
  ADMIN = 'ADMIN',
  AFFILIATE = 'AFFILIATE',
  MEMBER = 'MEMBER',
  OWNER = 'OWNER'
}

export type ShopRoleOperationFilterInput = {
  eq?: InputMaybe<ShopRole>;
  in?: InputMaybe<Array<ShopRole>>;
  neq?: InputMaybe<ShopRole>;
  nin?: InputMaybe<Array<ShopRole>>;
};

/** A connection to a list of items. */
export type ShopsConnection = {
  __typename?: 'ShopsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShopsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Shop>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShopsEdge = {
  __typename?: 'ShopsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Shop;
};

export enum ShopSellerType {
  AFFILIATE = 'AFFILIATE',
  SELLER = 'SELLER',
  STANDARD = 'STANDARD'
}

export type ShopSellerTypeOperationFilterInput = {
  eq?: InputMaybe<ShopSellerType>;
  in?: InputMaybe<Array<ShopSellerType>>;
  neq?: InputMaybe<ShopSellerType>;
  nin?: InputMaybe<Array<ShopSellerType>>;
};

export enum ShopSetupStatus {
  AVERAGE_REVENUE = 'AVERAGE_REVENUE',
  CARRIER_TERMS = 'CARRIER_TERMS',
  COMPLETED = 'COMPLETED',
  CONTACT_INFO = 'CONTACT_INFO',
  CREATE_SHOP = 'CREATE_SHOP',
  FINANCIAL_PERSONAL_INFO = 'FINANCIAL_PERSONAL_INFO',
  PLAID_LINK = 'PLAID_LINK',
  SELLER_GUIDELINES = 'SELLER_GUIDELINES',
  SELLER_TYPE = 'SELLER_TYPE',
  SHIP_FROM_ADDRESS = 'SHIP_FROM_ADDRESS',
  SHOP_CATEGORIES = 'SHOP_CATEGORIES'
}

/** A connection to a list of items. */
export type ShopShoppingCategoriesConnection = {
  __typename?: 'ShopShoppingCategoriesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShopShoppingCategoriesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShopShoppingCategory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShopShoppingCategoriesEdge = {
  __typename?: 'ShopShoppingCategoriesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShopShoppingCategory;
};

export type ShopShoppingCategory = {
  __typename?: 'ShopShoppingCategory';
  shop?: Maybe<Shop>;
  shopId: Scalars['UUID'];
  shoppingCategory?: Maybe<ShoppingCategory>;
  shoppingCategoryId: Scalars['UUID'];
};

export type ShopShoppingSubcategory = {
  __typename?: 'ShopShoppingSubcategory';
  shop?: Maybe<Shop>;
  shopId: Scalars['UUID'];
  shoppingSubcategory?: Maybe<ShoppingSubcategory>;
  shoppingSubcategoryId: Scalars['UUID'];
};

export type ShopSlugAlreadyExistsError = Error & {
  __typename?: 'ShopSlugAlreadyExistsError';
  message: Scalars['String'];
};

export type ShopSortInput = {
  createdAt?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
  slug?: InputMaybe<OrderDirection>;
  updatedAt?: InputMaybe<OrderDirection>;
};

export type ShopTransaction = {
  amount: Money;
  createdAt: Scalars['DateTime'];
  currency: CurrencyCode;
  id: Scalars['ID'];
  shopPayment?: Maybe<ShopPayment>;
  shopPaymentId: Scalars['ID'];
};

export type ShopTransactionFilterInput = {
  amount?: InputMaybe<ComparableInt32OperationFilterInput>;
  amountMoney?: InputMaybe<MoneyFilterInput>;
  and?: InputMaybe<Array<ShopTransactionFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  currency?: InputMaybe<CurrencyCodeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<ShopTransactionFilterInput>>;
  shopPayment?: InputMaybe<ShopPaymentFilterInput>;
  shopPaymentId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type ShopUser = {
  __typename?: 'ShopUser';
  role: ShopRole;
  shop?: Maybe<Shop>;
  shopId: Scalars['ID'];
  user?: Maybe<User>;
  userId: Scalars['ID'];
};

export type ShopUserFilterInput = {
  and?: InputMaybe<Array<ShopUserFilterInput>>;
  or?: InputMaybe<Array<ShopUserFilterInput>>;
  role?: InputMaybe<ShopRoleOperationFilterInput>;
  shop?: InputMaybe<ShopFilterInput>;
  shopId?: InputMaybe<ComparableGuidOperationFilterInput>;
  user?: InputMaybe<UserFilterInput>;
  userId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

/** A connection to a list of items. */
export type ShopUsersConnection = {
  __typename?: 'ShopUsersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShopUsersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShopUser>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShopUsersEdge = {
  __typename?: 'ShopUsersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShopUser;
};

export type Show = Node & {
  __typename?: 'Show';
  affiliateEarnings: Money;
  affiliateShop?: Maybe<Shop>;
  affiliateShopId?: Maybe<Scalars['ID']>;
  commission?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  featuredShowCard?: Maybe<ShowCard>;
  id: Scalars['ID'];
  images: Array<Image>;
  inviteAccepted: Scalars['Boolean'];
  jatangoFeesTotal: Money;
  name: Scalars['String'];
  processingFeesTotal: Money;
  salesTaxTotal: Money;
  shippingTotal: Money;
  shop: Shop;
  shopId: Scalars['ID'];
  shopOrders?: Maybe<ShopOrdersConnection>;
  showCards?: Maybe<ShowCardsConnection>;
  showFbPage?: Maybe<Scalars['String']>;
  showImages: Array<ShowImage>;
  slug: Scalars['String'];
  start?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  streams: Array<Stream>;
  totalSales: Money;
  updatedAt: Scalars['DateTime'];
};

export type ShowShopOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShopOrderFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShopOrderSortInput>>;
};

export type ShowShowCardsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShowCardFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShowCardSortInput>>;
};

export type ShowCard = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  showId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type ShowCardFilterInput = {
  and?: InputMaybe<Array<ShowCardFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ShowCardFilterInput>>;
  updatedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

/** A connection to a list of items. */
export type ShowCardsByShowConnection = {
  __typename?: 'ShowCardsByShowConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShowCardsByShowEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<ShowCard>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShowCardsByShowEdge = {
  __typename?: 'ShowCardsByShowEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ShowCard>;
};

/** A connection to a list of items. */
export type ShowCardsByShowSlugConnection = {
  __typename?: 'ShowCardsByShowSlugConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShowCardsByShowSlugEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<ShowCard>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShowCardsByShowSlugEdge = {
  __typename?: 'ShowCardsByShowSlugEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ShowCard>;
};

/** A connection to a list of items. */
export type ShowCardsConnection = {
  __typename?: 'ShowCardsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShowCardsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<ShowCard>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShowCardsEdge = {
  __typename?: 'ShowCardsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ShowCard>;
};

export type ShowCardSortInput = {
  createdAt?: InputMaybe<OrderDirection>;
  updatedAt?: InputMaybe<OrderDirection>;
};

export type ShowFilterInput = {
  and?: InputMaybe<Array<ShowFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  end?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  endedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ShowFilterInput>>;
  shop?: InputMaybe<ShopFilterInput>;
  slug?: InputMaybe<StringOperationFilterInput>;
  start?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  startedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

export type ShowImage = {
  __typename?: 'ShowImage';
  id: Scalars['UUID'];
  image?: Maybe<Image>;
  imageId: Scalars['UUID'];
  show?: Maybe<Show>;
  showId: Scalars['UUID'];
};

export type ShowImageFilterInput = {
  and?: InputMaybe<Array<ShowImageFilterInput>>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<ShowImageFilterInput>>;
  show?: InputMaybe<ShowFilterInput>;
  showId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type ShowNotFoundError = Error & {
  __typename?: 'ShowNotFoundError';
  message: Scalars['String'];
  showId: Scalars['ID'];
};

/** A connection to a list of items. */
export type ShowsByShopConnection = {
  __typename?: 'ShowsByShopConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShowsByShopEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Show>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShowsByShopEdge = {
  __typename?: 'ShowsByShopEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Show;
};

/** A connection to a list of items. */
export type ShowsByShopSlugConnection = {
  __typename?: 'ShowsByShopSlugConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShowsByShopSlugEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Show>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShowsByShopSlugEdge = {
  __typename?: 'ShowsByShopSlugEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Show;
};

/** A connection to a list of items. */
export type ShowsConnection = {
  __typename?: 'ShowsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ShowsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Show>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShowsEdge = {
  __typename?: 'ShowsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Show;
};

export type ShowSlugAlreadyExistsError = Error & {
  __typename?: 'ShowSlugAlreadyExistsError';
  message: Scalars['String'];
};

export type ShowSortInput = {
  affiliateShop?: InputMaybe<ShopSortInput>;
  affiliateShopId?: InputMaybe<OrderDirection>;
  commission?: InputMaybe<OrderDirection>;
  createdAt?: InputMaybe<OrderDirection>;
  description?: InputMaybe<OrderDirection>;
  end?: InputMaybe<OrderDirection>;
  endedAt?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  inviteAccepted?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
  shop?: InputMaybe<ShopSortInput>;
  showFbPage?: InputMaybe<OrderDirection>;
  slug?: InputMaybe<OrderDirection>;
  start?: InputMaybe<OrderDirection>;
  startedAt?: InputMaybe<OrderDirection>;
  updatedAt?: InputMaybe<OrderDirection>;
};

export enum Source {
  JATANGO = 'JATANGO',
  SHOPIFY = 'SHOPIFY'
}

export type SourceOperationFilterInput = {
  eq?: InputMaybe<Source>;
  in?: InputMaybe<Array<Source>>;
  neq?: InputMaybe<Source>;
  nin?: InputMaybe<Array<Source>>;
};

export enum StateProvinceCode {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AS = 'AS',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY'
}

export type StockLocation = {
  __typename?: 'StockLocation';
  storageLocation1?: Maybe<Scalars['String']>;
  storageLocation2?: Maybe<Scalars['String']>;
  storageLocationType1?: Maybe<StorageLocationType>;
  storageLocationType2?: Maybe<StorageLocationType>;
  zone?: Maybe<Scalars['String']>;
};

export type StockLocationInput = {
  storageLocation1?: InputMaybe<Scalars['String']>;
  storageLocation2?: InputMaybe<Scalars['String']>;
  storageLocationType1?: InputMaybe<StorageLocationType>;
  storageLocationType2?: InputMaybe<StorageLocationType>;
  zone?: InputMaybe<Scalars['String']>;
};

export enum StorageLocationType {
  AISLE = 'AISLE',
  BIN = 'BIN',
  FLOOR = 'FLOOR',
  OTHER = 'OTHER',
  RACK = 'RACK',
  ROOM = 'ROOM',
  SECTION = 'SECTION',
  SHELF = 'SHELF',
  TABLE = 'TABLE',
  WALL = 'WALL'
}

export type Stream = {
  __typename?: 'Stream';
  channelCreatedAt?: Maybe<Scalars['DateTime']>;
  channelDestroyedAt?: Maybe<Scalars['DateTime']>;
  chatHistory?: Maybe<Scalars['String']>;
  chatRoomId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  sessionId?: Maybe<Scalars['String']>;
  show?: Maybe<Show>;
  showId?: Maybe<Scalars['UUID']>;
  streamReplayUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type StreamShowArgs = {
  filter?: InputMaybe<ShowFilterInput>;
};

export type StreamFilterInput = {
  and?: InputMaybe<Array<StreamFilterInput>>;
  channelCreatedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  channelDestroyedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  chatHistory?: InputMaybe<StringOperationFilterInput>;
  chatRoomId?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<StreamFilterInput>>;
  sessionId?: InputMaybe<StringOperationFilterInput>;
  show?: InputMaybe<ShowFilterInput>;
  showId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  streamReplayUrl?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

/** A connection to a list of items. */
export type StreamsByShopSlugConnection = {
  __typename?: 'StreamsByShopSlugConnection';
  /** A list of edges. */
  edges?: Maybe<Array<StreamsByShopSlugEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Stream>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type StreamsByShopSlugEdge = {
  __typename?: 'StreamsByShopSlugEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Stream;
};

/** A connection to a list of items. */
export type StreamsConnection = {
  __typename?: 'StreamsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<StreamsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Stream>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type StreamsEdge = {
  __typename?: 'StreamsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Stream;
};

export type StreamSortInput = {
  channelCreatedAt?: InputMaybe<OrderDirection>;
  channelDestroyedAt?: InputMaybe<OrderDirection>;
  chatHistory?: InputMaybe<OrderDirection>;
  chatRoomId?: InputMaybe<OrderDirection>;
  createdAt?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  sessionId?: InputMaybe<OrderDirection>;
  show?: InputMaybe<ShowSortInput>;
  showId?: InputMaybe<OrderDirection>;
  streamReplayUrl?: InputMaybe<OrderDirection>;
  updatedAt?: InputMaybe<OrderDirection>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StripePayment = Node &
  Payment & {
    __typename?: 'StripePayment';
    amount: Money;
    applicationFee: Money;
    cardDeclineCode?: Maybe<CardDeclineCode>;
    clientSecret?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    currency: CurrencyCode;
    customer?: Maybe<Customer>;
    customerId?: Maybe<Scalars['ID']>;
    customerPaymentMethod?: Maybe<CustomerPaymentMethod>;
    customerPaymentMethodId?: Maybe<Scalars['ID']>;
    errorCode?: Maybe<PaymentErrorCode>;
    id: Scalars['ID'];
    orders?: Maybe<OrdersConnection>;
    paymentProviderFee?: Maybe<Money>;
    refunds?: Maybe<RefundsConnection>;
    salesTaxAmount: Money;
    shippingAmount: Money;
    shopPayments?: Maybe<ShopPaymentsConnection>;
    status: PaymentStatus;
    stripePaymentIntentId: Scalars['String'];
  };

export type StripePaymentOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type StripePaymentRefundsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type StripePaymentShopPaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type StripeRefund = Node &
  Refund & {
    __typename?: 'StripeRefund';
    amount: Money;
    createdAt: Scalars['DateTime'];
    currency: CurrencyCode;
    id: Scalars['ID'];
    payment: Payment;
    paymentId: Scalars['ID'];
    reason?: Maybe<RefundReason>;
    shopRefunds?: Maybe<ShopRefundsConnection>;
    status: RefundStatus;
    stripeRefundId: Scalars['String'];
  };

export type StripeRefundShopRefundsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type StripeShopPayment = Node &
  ShopPayment & {
    __typename?: 'StripeShopPayment';
    affiliateShop?: Maybe<Shop>;
    affiliateShopId?: Maybe<Scalars['ID']>;
    amount: Money;
    applicationFeeMoney: Money;
    commissionPercent?: Maybe<Scalars['Decimal']>;
    createdAt: Scalars['DateTime'];
    currency: CurrencyCode;
    errorCode?: Maybe<PaymentErrorCode>;
    id: Scalars['ID'];
    netAffiliateAmount?: Maybe<Scalars['Int']>;
    netAmountMoney?: Maybe<Money>;
    payment?: Maybe<Payment>;
    paymentCaptureDate?: Maybe<Scalars['DateTime']>;
    paymentId?: Maybe<Scalars['ID']>;
    paymentProviderFee: Money;
    paymentProviderFeeMoney?: Maybe<Money>;
    shippingAmountMoney: Money;
    shop: Shop;
    shopId: Scalars['ID'];
    shopOrders?: Maybe<ShopOrdersConnection>;
    shopRefunds?: Maybe<ShopRefundsConnection>;
    shopTransactions: Array<ShopTransaction>;
    show?: Maybe<Show>;
    showId?: Maybe<Scalars['UUID']>;
    status: ShopPaymentStatus;
    stripeAffiliateTransferId?: Maybe<Scalars['String']>;
    stripePaymentIntentId: Scalars['String'];
    stripeTransferId?: Maybe<Scalars['String']>;
    type: ShopPaymentType;
  };

export type StripeShopPaymentShopOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type StripeShopPaymentShopRefundsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type StripeShopRefund = Node &
  ShopRefund & {
    __typename?: 'StripeShopRefund';
    amount: Money;
    createdAt: Scalars['DateTime'];
    currency: CurrencyCode;
    id: Scalars['ID'];
    refund: Refund;
    refundId: Scalars['ID'];
    shopPayment: ShopPayment;
    shopPaymentId: Scalars['ID'];
    status: RefundStatus;
    stripeTransferId: Scalars['String'];
  };

export type StripeShopTransaction = Node &
  ShopTransaction & {
    __typename?: 'StripeShopTransaction';
    amount: Money;
    createdAt: Scalars['DateTime'];
    currency: CurrencyCode;
    id: Scalars['ID'];
    shopPayment?: Maybe<ShopPayment>;
    shopPaymentId: Scalars['ID'];
    stripeTransferId: Scalars['String'];
  };

export type Subscription = {
  __typename?: 'Subscription';
  cartItemUpdated: CartItem;
  cartItemUpdatedByCart: CartItem;
  fbCommentsSessionStatus: FbCommentsSessionStatusEvent;
  fbCommentsSessionStatusByLive: FbCommentsSessionStatusEvent;
  fbCommentsSessionStatusByShow: FbCommentsSessionStatusEvent;
  inventoryItemQuantityChanged: InventoryItemQuantityChangedEvent;
  inventoryItemsQuantityChanged: InventoryItemQuantityChangedEvent;
  itemAddedToCart: CartItem;
  itemAddedToCartByUserAndShop: CartItem;
  itemRemovedFromCart: CartItem;
  productInventoryQuantityChanged: ProductInventoryQuantityChangedEvent;
  productsInventoryQuantityChanged: ProductInventoryQuantityChangedEvent;
  shopOrderPlaced: ShopOrder;
  variantAddedToShow: VariantShowCard;
};

export type SubscriptionCartItemUpdatedArgs = {
  cartItemId: Scalars['ID'];
};

export type SubscriptionCartItemUpdatedByCartArgs = {
  cartId: Scalars['ID'];
};

export type SubscriptionFbCommentsSessionStatusArgs = {
  id: Scalars['ID'];
};

export type SubscriptionFbCommentsSessionStatusByLiveArgs = {
  liveId: Scalars['ID'];
};

export type SubscriptionFbCommentsSessionStatusByShowArgs = {
  showId: Scalars['ID'];
};

export type SubscriptionInventoryItemQuantityChangedArgs = {
  inventoryItemId: Scalars['ID'];
};

export type SubscriptionInventoryItemsQuantityChangedArgs = {
  inventoryItemIds: Array<Scalars['ID']>;
};

export type SubscriptionItemAddedToCartArgs = {
  cartId: Scalars['ID'];
};

export type SubscriptionItemAddedToCartByUserAndShopArgs = {
  shopId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type SubscriptionItemRemovedFromCartArgs = {
  cartId: Scalars['ID'];
};

export type SubscriptionProductInventoryQuantityChangedArgs = {
  productId: Scalars['ID'];
};

export type SubscriptionProductsInventoryQuantityChangedArgs = {
  productIds: Array<Scalars['ID']>;
};

export type SubscriptionShopOrderPlacedArgs = {
  shopId: Scalars['ID'];
};

export type SubscriptionVariantAddedToShowArgs = {
  showId: Scalars['ID'];
};

/** A connection to a list of items. */
export type TaxCategoriesByCategoryConnection = {
  __typename?: 'TaxCategoriesByCategoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<TaxCategoriesByCategoryEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<TaxCategory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TaxCategoriesByCategoryEdge = {
  __typename?: 'TaxCategoriesByCategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TaxCategory;
};

/** A connection to a list of items. */
export type TaxCategoriesConnection = {
  __typename?: 'TaxCategoriesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<TaxCategoriesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<TaxCategory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TaxCategoriesEdge = {
  __typename?: 'TaxCategoriesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TaxCategory;
};

export type TaxCategory = Node & {
  __typename?: 'TaxCategory';
  categories?: Maybe<CategoriesConnection>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TaxCategoryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CategoryFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<CategorySortInput>>;
};

export type TaxCategoryFilterInput = {
  and?: InputMaybe<Array<TaxCategoryFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TaxCategoryFilterInput>>;
};

export type TaxCategoryNotFoundError = Error & {
  __typename?: 'TaxCategoryNotFoundError';
  message: Scalars['String'];
};

export type TaxCategorySortInput = {
  description?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
};

export type TextOptionValue = Node &
  OptionValue & {
    __typename?: 'TextOptionValue';
    id: Scalars['ID'];
    label: Scalars['String'];
    option: Option;
    optionId: Scalars['ID'];
    order: Scalars['Int'];
    variants?: Maybe<VariantsConnection>;
  };

export type TextOptionValueVariantsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type TextOptionValueInput = {
  id?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
};

export type UnableToUpdateQuantityError = Error & {
  __typename?: 'UnableToUpdateQuantityError';
  inventoryItemId: Scalars['ID'];
  locationInventoryId: Scalars['ID'];
  message: Scalars['String'];
};

export type UnarchiveProductError = ProductNotArchivedError;

export type UnarchiveProductInput = {
  productId: Scalars['ID'];
};

export type UnarchiveProductPayload = {
  __typename?: 'UnarchiveProductPayload';
  errors?: Maybe<Array<UnarchiveProductError>>;
  product?: Maybe<Product>;
};

export type UnauthorizedError = Error & {
  __typename?: 'UnauthorizedError';
  message: Scalars['String'];
};

export type UnfulfillOrderShipmentLineItemError = CannotUnfulfillQuantityError;

export type UnfulfillOrderShipmentLineItemInput = {
  orderShipmentLineItemId: Scalars['ID'];
  unfulfillQuantity: Scalars['Int'];
};

export type UnfulfillOrderShipmentLineItemPayload = {
  __typename?: 'UnfulfillOrderShipmentLineItemPayload';
  errors?: Maybe<Array<UnfulfillOrderShipmentLineItemError>>;
  orderShipmentLineItem?: Maybe<OrderShipmentLineItem>;
};

export type UnHidePackageTemplateError = InvalidAddressError | InvalidSlugError | ShopSlugAlreadyExistsError;

export type UnHidePackageTemplateInput = {
  carrierPackageIds: Array<Scalars['ID']>;
  shopId: Scalars['ID'];
};

export type UnHidePackageTemplatePayload = {
  __typename?: 'UnHidePackageTemplatePayload';
  errors?: Maybe<Array<UnHidePackageTemplateError>>;
  shippingCarrierPackage?: Maybe<Array<ShippingCarrierPackage>>;
};

export type UnpublishProductError = ProductNotPublishedError;

export type UnpublishProductInput = {
  productId: Scalars['ID'];
};

export type UnpublishProductPayload = {
  __typename?: 'UnpublishProductPayload';
  errors?: Maybe<Array<UnpublishProductError>>;
  product?: Maybe<Product>;
};

export type UpdateAddressInput = {
  cityLocality?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  countryCode: CountryCode;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  line3?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['PhoneNumber']>;
  postalCode?: InputMaybe<Scalars['PostalCode']>;
  stateProvince?: InputMaybe<StateProvinceCode>;
};

export type UpdateCartItemCustomizationInput = {
  cartItemId: Scalars['ID'];
  customizationOptionId: Scalars['ID'];
  index?: Scalars['Int'];
  value: Scalars['String'];
};

export type UpdateCartItemCustomizationPayload = {
  __typename?: 'UpdateCartItemCustomizationPayload';
  cartItemCustomization?: Maybe<CartItemCustomization>;
};

export type UpdateCartItemError = InvalidQuantityError | OutOfStockError;

export type UpdateCartItemInput = {
  cartItemId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type UpdateCartItemPayload = {
  __typename?: 'UpdateCartItemPayload';
  cartItem?: Maybe<CartItem>;
  errors?: Maybe<Array<UpdateCartItemError>>;
};

export type UpdateCartItemPosError = InvalidQuantityError | OutOfStockError;

export type UpdateCartItemPosInput = {
  cartItemId: Scalars['ID'];
  customerId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type UpdateCartItemPosPayload = {
  __typename?: 'UpdateCartItemPosPayload';
  cartItem?: Maybe<CartItem>;
  errors?: Maybe<Array<UpdateCartItemPosError>>;
};

export type UpdateCheckoutError =
  | BillingCustomerAddressNotFoundError
  | CheckoutNotFoundError
  | CustomerPaymentMethodNotFoundError
  | OutOfStockError
  | ShippingCustomerAddressNotFoundError;

export type UpdateCheckoutInput = {
  billingCustomerAddressId?: InputMaybe<Scalars['ID']>;
  checkoutId: Scalars['ID'];
  customerPaymentMethodId?: InputMaybe<Scalars['ID']>;
  shippingCustomerAddressId?: InputMaybe<Scalars['ID']>;
};

export type UpdateCheckoutPayload = {
  __typename?: 'UpdateCheckoutPayload';
  checkout?: Maybe<Checkout>;
  errors?: Maybe<Array<UpdateCheckoutError>>;
};

export type UpdateCheckoutPosError =
  | BillingCustomerAddressNotFoundError
  | CheckoutNotFoundError
  | CustomerPaymentMethodNotFoundError
  | OutOfStockError
  | ShippingCustomerAddressNotFoundError;

export type UpdateCheckoutPosInput = {
  billingCustomerAddressId?: InputMaybe<Scalars['ID']>;
  checkoutId: Scalars['ID'];
  customerId: Scalars['ID'];
  customerPaymentMethodId?: InputMaybe<Scalars['ID']>;
  shippingCustomerAddressId?: InputMaybe<Scalars['ID']>;
  shopId?: InputMaybe<Scalars['ID']>;
};

export type UpdateCheckoutPosPayload = {
  __typename?: 'UpdateCheckoutPosPayload';
  checkout?: Maybe<Checkout>;
  errors?: Maybe<Array<UpdateCheckoutPosError>>;
};

export type UpdateCustomerAddressError = CustomerNotFoundError | InvalidAddressError;

export type UpdateCustomerAddressInput = {
  address: UpdateAddressInput;
  customerAddressId: Scalars['ID'];
};

export type UpdateCustomerAddressPayload = {
  __typename?: 'UpdateCustomerAddressPayload';
  customerAddress?: Maybe<CustomerAddress>;
  errors?: Maybe<Array<UpdateCustomerAddressError>>;
};

export type UpdateCustomerInput = {
  customerId: Scalars['ID'];
  email?: InputMaybe<Scalars['EmailAddress']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['PhoneNumber']>;
};

export type UpdateCustomerPayload = {
  __typename?: 'UpdateCustomerPayload';
  customer?: Maybe<Customer>;
};

export type UpdateEmailAddressInput = {
  newEmail: Scalars['String'];
};

export type UpdateEmailAddressPayload = {
  __typename?: 'UpdateEmailAddressPayload';
  updateEmailAddressResponse?: Maybe<UpdateEmailAddressResponse>;
};

export type UpdateEmailAddressResponse = {
  __typename?: 'UpdateEmailAddressResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type UpdateExternalAccountInput = {
  defaultForCurrency?: InputMaybe<Scalars['Boolean']>;
  externalAccountId: Scalars['ID'];
};

export type UpdateExternalAccountPayload = {
  __typename?: 'UpdateExternalAccountPayload';
  externalAccount?: Maybe<ExternalAccount>;
};

export type UpdateImageInput = {
  altText?: InputMaybe<Scalars['String']>;
  focusRegion?: InputMaybe<ImageRegionInput>;
  imageId: Scalars['ID'];
};

export type UpdateImagePayload = {
  __typename?: 'UpdateImagePayload';
  image?: Maybe<Image>;
};

export type UpdateInventoryItemError = InvalidMaxReservableQuantityError | InventoryItemNotFoundError;

export type UpdateInventoryItemInput = {
  barcode?: InputMaybe<Scalars['Barcode']>;
  cost?: InputMaybe<MoneyInput>;
  dimensions?: InputMaybe<DimensionsInput>;
  inventoryItemId: Scalars['ID'];
  maxReservable?: InputMaybe<Scalars['NonNegativeInt']>;
  sku?: InputMaybe<Scalars['Sku']>;
  weight?: InputMaybe<WeightInput>;
};

export type UpdateInventoryItemPayload = {
  __typename?: 'UpdateInventoryItemPayload';
  errors?: Maybe<Array<UpdateInventoryItemError>>;
  inventoryItem?: Maybe<InventoryItem>;
};

export type UpdateLocationInventoryError = LocationInventoryNotFoundError | UnableToUpdateQuantityError;

export type UpdateLocationInventoryInput = {
  incoming?: InputMaybe<Scalars['NonNegativeInt']>;
  locationInventoryId: Scalars['ID'];
  quantity?: InputMaybe<Scalars['NonNegativeInt']>;
  stockLocation?: InputMaybe<StockLocationInput>;
};

export type UpdateLocationInventoryPayload = {
  __typename?: 'UpdateLocationInventoryPayload';
  errors?: Maybe<Array<UpdateLocationInventoryError>>;
  locationInventory?: Maybe<LocationInventory>;
};

export type UpdateOptionInput = {
  enableEmojis?: InputMaybe<Scalars['Boolean']>;
  enableLetters?: InputMaybe<Scalars['Boolean']>;
  enableNumbers?: InputMaybe<Scalars['Boolean']>;
  maxLength?: InputMaybe<Scalars['Int']>;
  minLength?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  optionId: Scalars['ID'];
  productId: Scalars['ID'];
  required?: InputMaybe<Scalars['Boolean']>;
  uppercaseOnly?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateOptionPayload = {
  __typename?: 'UpdateOptionPayload';
  option?: Maybe<Option>;
};

export type UpdateOrderAddressError =
  | CheckoutNotFoundError
  | CustomerEmailRequiredError
  | LineItemsCountError
  | NoItemsError
  | OutOfStockError
  | PaymentError;

export type UpdateOrderAddressInput = {
  checkoutId: Scalars['ID'];
  customerId: Scalars['ID'];
  ipAddress?: InputMaybe<Scalars['IPv4']>;
  orderId: Scalars['ID'];
  shippingCustomerAddressId?: InputMaybe<Scalars['ID']>;
  shopId: Scalars['ID'];
};

export type UpdateOrderAddressPayload = {
  __typename?: 'UpdateOrderAddressPayload';
  errors?: Maybe<Array<UpdateOrderAddressError>>;
  order?: Maybe<Order>;
};

export type UpdateOrderPaymentMethodError =
  | CheckoutNotFoundError
  | CustomerEmailRequiredError
  | LineItemsCountError
  | NoItemsError
  | OutOfStockError
  | PaymentError;

export type UpdateOrderPaymentMethodInput = {
  customerId: Scalars['ID'];
  isCash: Scalars['Boolean'];
  orderId: Scalars['ID'];
};

export type UpdateOrderPaymentMethodPayload = {
  __typename?: 'UpdateOrderPaymentMethodPayload';
  errors?: Maybe<Array<UpdateOrderPaymentMethodError>>;
  order?: Maybe<Order>;
};

export type UpdatePackageError = CannotFulfillQuantityError;

export type UpdatePackageInput = {
  carrierPackageId?: InputMaybe<Scalars['ID']>;
  isCustomPackage?: Scalars['Boolean'];
  length?: InputMaybe<Scalars['Float']>;
  lengthUnit?: InputMaybe<LengthUnit>;
  orderPackageId: Scalars['ID'];
  primaryWeight?: InputMaybe<Scalars['Float']>;
  primaryWeightUnit?: InputMaybe<WeightUnit>;
  secondaryWeight?: InputMaybe<Scalars['Float']>;
  secondaryWeightUnit?: InputMaybe<WeightUnit>;
  shopOrderId: Scalars['ID'];
  width?: InputMaybe<Scalars['Float']>;
};

export type UpdatePackagePayload = {
  __typename?: 'UpdatePackagePayload';
  errors?: Maybe<Array<UpdatePackageError>>;
  orderPackage?: Maybe<OrderPackage>;
};

export type UpdatePasswordInput = {
  currentPw: Scalars['String'];
  newPw: Scalars['String'];
};

export type UpdatePasswordPayload = {
  __typename?: 'UpdatePasswordPayload';
  updatePasswordResponse?: Maybe<UpdatePasswordResponse>;
};

export type UpdatePasswordResponse = {
  __typename?: 'UpdatePasswordResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type UpdatePaymentError = CustomerPaymentMethodNotFoundError;

export type UpdatePaymentInput = {
  customerPaymentMethodId: Scalars['ID'];
  paymentId: Scalars['ID'];
};

export type UpdatePaymentPayload = {
  __typename?: 'UpdatePaymentPayload';
  errors?: Maybe<Array<UpdatePaymentError>>;
  payment?: Maybe<Payment>;
};

export type UpdatePhoneInput = {
  phoneNumber: Scalars['String'];
};

export type UpdatePhonePayload = {
  __typename?: 'UpdatePhonePayload';
  phoneNumberVerificationResponse?: Maybe<PhoneNumberVerificationResponse>;
};

export type UpdatePricesInput = {
  basePrice?: InputMaybe<Scalars['Amount']>;
  currency?: InputMaybe<CurrencyCode>;
  retailPrice?: InputMaybe<Scalars['Amount']>;
  salePrice?: InputMaybe<Scalars['Amount']>;
};

export type UpdateProductCollectionInput = {
  affiliateUserId?: InputMaybe<Scalars['ID']>;
  collectionId: Scalars['ID'];
  commission?: InputMaybe<Scalars['Decimal']>;
  description?: InputMaybe<Scalars['String']>;
  imageIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateProductCollectionPayload = {
  __typename?: 'UpdateProductCollectionPayload';
  productCollection?: Maybe<ProductCollection>;
};

export type UpdateProductError = CategoryNotFoundError | InvalidSlugError | ProductSlugAlreadyExistsError;

export type UpdateProductInput = {
  barcode?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  productId: Scalars['ID'];
  slug?: InputMaybe<Scalars['String']>;
};

export type UpdateProductPayload = {
  __typename?: 'UpdateProductPayload';
  errors?: Maybe<Array<UpdateProductError>>;
  product?: Maybe<Product>;
};

export type UpdateReservationError = InvalidReservationExpirationError | ReservationNotFoundError;

export type UpdateReservationInput = {
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  reason?: InputMaybe<ReservationReason>;
  reservationId: Scalars['ID'];
};

export type UpdateReservationPayload = {
  __typename?: 'UpdateReservationPayload';
  errors?: Maybe<Array<UpdateReservationError>>;
  reservation?: Maybe<Reservation>;
};

export type UpdateReservationQuantityError =
  | InvalidReservationQuantityError
  | OutOfStockError
  | ReservationNotFoundError;

export type UpdateReservationQuantityInput = {
  reservationId: Scalars['ID'];
  reserveQuantities: Array<ReserveQuantityInput>;
};

export type UpdateReservationQuantityPayload = {
  __typename?: 'UpdateReservationQuantityPayload';
  errors?: Maybe<Array<UpdateReservationQuantityError>>;
  reservation?: Maybe<Reservation>;
};

export type UpdateShopCategoriesInput = {
  categoryIds: Array<Scalars['ID']>;
  shopId: Scalars['ID'];
};

export type UpdateShopCategoriesPayload = {
  __typename?: 'UpdateShopCategoriesPayload';
  shopShoppingCategory?: Maybe<Array<ShopShoppingCategory>>;
};

export type UpdateShopError = InvalidAddressError | InvalidSlugError | ShopSlugAlreadyExistsError;

export type UpdateShopInput = {
  address?: InputMaybe<AddressInput>;
  contactEmail?: InputMaybe<Scalars['EmailAddress']>;
  country?: InputMaybe<CountryCode>;
  description?: InputMaybe<Scalars['String']>;
  legalBusinessName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['PhoneNumber']>;
  revenueType?: InputMaybe<ShopRevenueType>;
  shopFbPages?: InputMaybe<Scalars['String']>;
  shopId: Scalars['ID'];
  slug?: InputMaybe<Scalars['String']>;
};

export type UpdateShopPayload = {
  __typename?: 'UpdateShopPayload';
  errors?: Maybe<Array<UpdateShopError>>;
  shop?: Maybe<Shop>;
};

export type UpdateShopSubcategoriesInput = {
  shopId: Scalars['ID'];
  subcategoryIds: Array<Scalars['ID']>;
};

export type UpdateShopSubcategoriesPayload = {
  __typename?: 'UpdateShopSubcategoriesPayload';
  shopShoppingSubcategory?: Maybe<Array<ShopShoppingSubcategory>>;
};

export type UpdateStreamError = ShowNotFoundError;

export type UpdateStreamInput = {
  chatRoomId?: InputMaybe<Scalars['String']>;
  sessionId: Scalars['String'];
};

export type UpdateStreamPayload = {
  __typename?: 'UpdateStreamPayload';
  errors?: Maybe<Array<UpdateStreamError>>;
  stream?: Maybe<Stream>;
};

export type UpdateUserLiveExperienceInput = {
  liveExperience: LiveExperienceType;
};

export type UpdateUserLiveExperiencePayload = {
  __typename?: 'UpdateUserLiveExperiencePayload';
  user?: Maybe<User>;
};

export type UpdateUserNamesInput = {
  bio?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  userName: Scalars['String'];
};

export type UpdateUserNamesPayload = {
  __typename?: 'UpdateUserNamesPayload';
  user?: Maybe<User>;
};

export type UpdateUserRoleInput = {
  userId: Scalars['ID'];
  userType: UserRole;
};

export type UpdateUserRolePayload = {
  __typename?: 'UpdateUserRolePayload';
  user?: Maybe<User>;
};

export type UpdateVariantError = ImageNotFoundError | ShippingProfileNotFoundError | TaxCategoryNotFoundError;

export type UpdateVariantInput = {
  defaultImageId?: InputMaybe<Scalars['ID']>;
  prices?: InputMaybe<UpdatePricesInput>;
  shippingProfileId?: InputMaybe<Scalars['ID']>;
  taxCategoryId?: InputMaybe<Scalars['ID']>;
  variantId: Scalars['ID'];
};

export type UpdateVariantPayload = {
  __typename?: 'UpdateVariantPayload';
  errors?: Maybe<Array<UpdateVariantError>>;
  variant?: Maybe<Variant>;
};

export type UriFilterInput = {
  absolutePath?: InputMaybe<StringOperationFilterInput>;
  absoluteUri?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<UriFilterInput>>;
  authority?: InputMaybe<StringOperationFilterInput>;
  dnsSafeHost?: InputMaybe<StringOperationFilterInput>;
  fragment?: InputMaybe<StringOperationFilterInput>;
  host?: InputMaybe<StringOperationFilterInput>;
  hostNameType?: InputMaybe<UriHostNameTypeOperationFilterInput>;
  idnHost?: InputMaybe<StringOperationFilterInput>;
  isAbsoluteUri?: InputMaybe<BooleanOperationFilterInput>;
  isDefaultPort?: InputMaybe<BooleanOperationFilterInput>;
  isFile?: InputMaybe<BooleanOperationFilterInput>;
  isLoopback?: InputMaybe<BooleanOperationFilterInput>;
  isUnc?: InputMaybe<BooleanOperationFilterInput>;
  localPath?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UriFilterInput>>;
  originalString?: InputMaybe<StringOperationFilterInput>;
  pathAndQuery?: InputMaybe<StringOperationFilterInput>;
  port?: InputMaybe<ComparableInt32OperationFilterInput>;
  query?: InputMaybe<StringOperationFilterInput>;
  scheme?: InputMaybe<StringOperationFilterInput>;
  segments?: InputMaybe<ListStringOperationFilterInput>;
  userEscaped?: InputMaybe<BooleanOperationFilterInput>;
  userInfo?: InputMaybe<StringOperationFilterInput>;
};

export enum UriHostNameType {
  BASIC = 'BASIC',
  DNS = 'DNS',
  I_PV4 = 'I_PV4',
  I_PV6 = 'I_PV6',
  UNKNOWN = 'UNKNOWN'
}

export type UriHostNameTypeOperationFilterInput = {
  eq?: InputMaybe<UriHostNameType>;
  in?: InputMaybe<Array<UriHostNameType>>;
  neq?: InputMaybe<UriHostNameType>;
  nin?: InputMaybe<Array<UriHostNameType>>;
};

export type UriSortInput = {
  absolutePath?: InputMaybe<OrderDirection>;
  absoluteUri?: InputMaybe<OrderDirection>;
  authority?: InputMaybe<OrderDirection>;
  dnsSafeHost?: InputMaybe<OrderDirection>;
  fragment?: InputMaybe<OrderDirection>;
  host?: InputMaybe<OrderDirection>;
  hostNameType?: InputMaybe<OrderDirection>;
  idnHost?: InputMaybe<OrderDirection>;
  isAbsoluteUri?: InputMaybe<OrderDirection>;
  isDefaultPort?: InputMaybe<OrderDirection>;
  isFile?: InputMaybe<OrderDirection>;
  isLoopback?: InputMaybe<OrderDirection>;
  isUnc?: InputMaybe<OrderDirection>;
  localPath?: InputMaybe<OrderDirection>;
  originalString?: InputMaybe<OrderDirection>;
  pathAndQuery?: InputMaybe<OrderDirection>;
  port?: InputMaybe<OrderDirection>;
  query?: InputMaybe<OrderDirection>;
  scheme?: InputMaybe<OrderDirection>;
  userEscaped?: InputMaybe<OrderDirection>;
  userInfo?: InputMaybe<OrderDirection>;
};

export type User = Node & {
  __typename?: 'User';
  bio?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['EmailAddress']>;
  id: Scalars['ID'];
  identities: Array<Identity>;
  liveExperience?: Maybe<LiveExperienceType>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['PhoneNumber']>;
  photoUrl?: Maybe<Scalars['URL']>;
  plaidLinks: Array<PlaidLink>;
  shops?: Maybe<ShopsConnection>;
  updatedAt: Scalars['DateTime'];
  userDemographics?: Maybe<Array<Maybe<UserDemographic>>>;
  userImages?: Maybe<UserImagesConnection>;
  username?: Maybe<Scalars['String']>;
  userShoppingCategories: Array<UserShoppingCategory>;
  userShoppingSubcategories: Array<UserShoppingSubcategory>;
};

export type UserShopsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ShopFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ShopSortInput>>;
};

export type UserUserImagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserImageFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<UserImageSortInput>>;
};

export type UserDemographic = {
  __typename?: 'UserDemographic';
  createdAt: Scalars['DateTime'];
  dateOfBirth?: Maybe<Scalars['Date']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['UUID'];
  zipCode?: Maybe<Scalars['String']>;
};

export type UserDemographicFilterInput = {
  and?: InputMaybe<Array<UserDemographicFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  dateOfBirth?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  gender?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<UserDemographicFilterInput>>;
  updatedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  user?: InputMaybe<UserFilterInput>;
  userId?: InputMaybe<ComparableGuidOperationFilterInput>;
  zipCode?: InputMaybe<StringOperationFilterInput>;
};

export type UserFilterInput = {
  and?: InputMaybe<Array<UserFilterInput>>;
  bio?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customer?: InputMaybe<CustomerFilterInput>;
  customerId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  liveExperience?: InputMaybe<NullableOfLiveExperienceTypeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  phone?: InputMaybe<StringOperationFilterInput>;
  photoUrl?: InputMaybe<UriFilterInput>;
  plaidLinks?: InputMaybe<ListFilterInputTypeOfPlaidLinkFilterInput>;
  shops?: InputMaybe<ListFilterInputTypeOfShopFilterInput>;
  shopUsers?: InputMaybe<ListFilterInputTypeOfShopUserFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  userDemographics?: InputMaybe<ListFilterInputTypeOfUserDemographicFilterInput>;
  userImages?: InputMaybe<ListFilterInputTypeOfUserImageFilterInput>;
  username?: InputMaybe<StringOperationFilterInput>;
  userShoppingCategories?: InputMaybe<ListFilterInputTypeOfUserShoppingCategoryFilterInput>;
  userShoppingSubcategories?: InputMaybe<ListFilterInputTypeOfUserShoppingSubcategoryFilterInput>;
  userType?: InputMaybe<UserRoleOperationFilterInput>;
};

export type UserImage = {
  __typename?: 'UserImage';
  id: Scalars['UUID'];
  imageType?: Maybe<UserImageType>;
  profileImage?: Maybe<ProfileImage>;
  profileImageId: Scalars['UUID'];
  user?: Maybe<User>;
  userId: Scalars['UUID'];
};

export type UserImageFilterInput = {
  and?: InputMaybe<Array<UserImageFilterInput>>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  imageType?: InputMaybe<NullableOfUserImageTypeOperationFilterInput>;
  or?: InputMaybe<Array<UserImageFilterInput>>;
  profileImage?: InputMaybe<ProfileImageFilterInput>;
  profileImageId?: InputMaybe<ComparableGuidOperationFilterInput>;
  user?: InputMaybe<UserFilterInput>;
  userId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

/** A connection to a list of items. */
export type UserImagesConnection = {
  __typename?: 'UserImagesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<UserImagesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<UserImage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserImagesEdge = {
  __typename?: 'UserImagesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: UserImage;
};

export type UserImageSortInput = {
  id?: InputMaybe<OrderDirection>;
  imageType?: InputMaybe<OrderDirection>;
  profileImage?: InputMaybe<ProfileImageSortInput>;
  profileImageId?: InputMaybe<OrderDirection>;
  user?: InputMaybe<UserSortInput>;
  userId?: InputMaybe<OrderDirection>;
};

export enum UserImageType {
  BANNER = 'BANNER',
  PROFILE = 'PROFILE'
}

export type UserNotFoundError = Error & {
  __typename?: 'UserNotFoundError';
  message: Scalars['String'];
  userId: Scalars['ID'];
};

export type UserPackageCreateRequestInput = {
  height: Scalars['Float'];
  length: Scalars['Float'];
  lengthUnit: LengthUnit;
  name: Scalars['String'];
  width: Scalars['Float'];
};

export enum UserRole {
  BASIC = 'BASIC',
  PREMIER = 'PREMIER'
}

export type UserRoleOperationFilterInput = {
  eq?: InputMaybe<UserRole>;
  in?: InputMaybe<Array<UserRole>>;
  neq?: InputMaybe<UserRole>;
  nin?: InputMaybe<Array<UserRole>>;
};

/** A connection to a list of items. */
export type UsersByEmailConnection = {
  __typename?: 'UsersByEmailConnection';
  /** A list of edges. */
  edges?: Maybe<Array<UsersByEmailEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UsersByEmailEdge = {
  __typename?: 'UsersByEmailEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: User;
};

/** A connection to a list of items. */
export type UserShippingPackagesForShopConnection = {
  __typename?: 'UserShippingPackagesForShopConnection';
  /** A list of edges. */
  edges?: Maybe<Array<UserShippingPackagesForShopEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ShippingUserPackage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserShippingPackagesForShopEdge = {
  __typename?: 'UserShippingPackagesForShopEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShippingUserPackage;
};

export type UserShoppingCategory = {
  __typename?: 'UserShoppingCategory';
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  isSeller: Scalars['Boolean'];
  shoppingCategory: ShoppingCategory;
  shoppingCategoryId: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['UUID'];
};

export type UserShoppingCategoryFilterInput = {
  and?: InputMaybe<Array<UserShoppingCategoryFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isSeller?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<UserShoppingCategoryFilterInput>>;
  shoppingCategory?: InputMaybe<ShoppingCategoryFilterInput>;
  shoppingCategoryId?: InputMaybe<ComparableGuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  user?: InputMaybe<UserFilterInput>;
  userId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type UserShoppingSubcategory = {
  __typename?: 'UserShoppingSubcategory';
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  shoppingSubcategory: ShoppingSubcategory;
  shoppingSubcategoryId: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['UUID'];
};

export type UserShoppingSubcategoryFilterInput = {
  and?: InputMaybe<Array<UserShoppingSubcategoryFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<UserShoppingSubcategoryFilterInput>>;
  shoppingSubcategory?: InputMaybe<ShoppingSubcategoryFilterInput>;
  shoppingSubcategoryId?: InputMaybe<ComparableGuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  user?: InputMaybe<UserFilterInput>;
  userId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type UserSortInput = {
  bio?: InputMaybe<OrderDirection>;
  createdAt?: InputMaybe<OrderDirection>;
  customer?: InputMaybe<CustomerSortInput>;
  customerId?: InputMaybe<OrderDirection>;
  email?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  liveExperience?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
  phone?: InputMaybe<OrderDirection>;
  photoUrl?: InputMaybe<UriSortInput>;
  updatedAt?: InputMaybe<OrderDirection>;
  username?: InputMaybe<OrderDirection>;
  userType?: InputMaybe<OrderDirection>;
};

export type Variant = Node & {
  __typename?: 'Variant';
  archived?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  default: Scalars['Boolean'];
  defaultImage?: Maybe<Image>;
  defaultImageId?: Maybe<Scalars['ID']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inventoryItem?: Maybe<InventoryItem>;
  optionValues?: Maybe<OptionValuesConnection>;
  prices: Prices;
  product: Product;
  productId: Scalars['ID'];
  quantity: Quantity;
  shippingProfile?: Maybe<ShippingProfile>;
  shippingProfileId?: Maybe<Scalars['ID']>;
  source: Source;
  taxCategory?: Maybe<TaxCategory>;
  taxCategoryId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
  variantImages?: Maybe<VariantImagesConnection>;
};

export type VariantOptionValuesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type VariantVariantImagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<VariantImageSortInput>>;
};

export type VariantFilterInput = {
  and?: InputMaybe<Array<VariantFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<VariantFilterInput>>;
  updatedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

export type VariantImage = Node & {
  __typename?: 'VariantImage';
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  image: Image;
  imageId: Scalars['ID'];
  order: Scalars['Int'];
  variant: Variant;
  variantId: Scalars['ID'];
};

export type VariantImageFilterInput = {
  and?: InputMaybe<Array<VariantImageFilterInput>>;
  default?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<VariantImageFilterInput>>;
  order?: InputMaybe<ComparableInt32OperationFilterInput>;
  variant?: InputMaybe<VariantFilterInput>;
  variantId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type VariantImageInput = {
  imageId: Scalars['ID'];
  order?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type VariantImagesConnection = {
  __typename?: 'VariantImagesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<VariantImagesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<VariantImage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type VariantImagesEdge = {
  __typename?: 'VariantImagesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: VariantImage;
};

export type VariantImageSortInput = {
  default?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<OrderDirection>;
  order?: InputMaybe<OrderDirection>;
  variant?: InputMaybe<VariantSortInput>;
  variantId?: InputMaybe<OrderDirection>;
};

export type VariantNotFoundError = Error & {
  __typename?: 'VariantNotFoundError';
  message: Scalars['String'];
};

export type VariantOptionValue = {
  __typename?: 'VariantOptionValue';
  optionValue?: Maybe<OptionValue>;
  optionValueId: Scalars['UUID'];
  variant?: Maybe<Variant>;
  variantId: Scalars['UUID'];
};

export type VariantOptionValueFilterInput = {
  and?: InputMaybe<Array<VariantOptionValueFilterInput>>;
  optionValue?: InputMaybe<OptionValueFilterInput>;
  optionValueId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<VariantOptionValueFilterInput>>;
  variant?: InputMaybe<VariantFilterInput>;
  variantId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

/** A connection to a list of items. */
export type VariantsByProductConnection = {
  __typename?: 'VariantsByProductConnection';
  /** A list of edges. */
  edges?: Maybe<Array<VariantsByProductEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Variant>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type VariantsByProductEdge = {
  __typename?: 'VariantsByProductEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Variant;
};

/** A connection to a list of items. */
export type VariantsConnection = {
  __typename?: 'VariantsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<VariantsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Variant>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type VariantsEdge = {
  __typename?: 'VariantsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Variant>;
};

export type VariantShowCard = Node &
  ShowCard & {
    __typename?: 'VariantShowCard';
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    number: Scalars['Int'];
    show: Show;
    showId: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
    variant: Variant;
    variantId: Scalars['ID'];
  };

export type VariantSortInput = {
  createdAt?: InputMaybe<OrderDirection>;
  updatedAt?: InputMaybe<OrderDirection>;
};

export type VerifyCodeInput = {
  phoneNumber: Scalars['String'];
  verificationCode: Scalars['String'];
};

export type VerifyCodePayload = {
  __typename?: 'VerifyCodePayload';
  phoneNumberVerificationResponse?: Maybe<PhoneNumberVerificationResponse>;
};

export type VerifyLoginResponse = {
  __typename?: 'VerifyLoginResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type VerifyUserLoginInput = {
  currentPw: Scalars['String'];
};

export type VerifyUserLoginPayload = {
  __typename?: 'VerifyUserLoginPayload';
  verifyLoginResponse?: Maybe<VerifyLoginResponse>;
};

/** A connection to a list of items. */
export type ViewerCartItemsByShopConnection = {
  __typename?: 'ViewerCartItemsByShopConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ViewerCartItemsByShopEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CartItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ViewerCartItemsByShopEdge = {
  __typename?: 'ViewerCartItemsByShopEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CartItem;
};

/** A connection to a list of items. */
export type ViewerCartItemsByShopSlugConnection = {
  __typename?: 'ViewerCartItemsByShopSlugConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ViewerCartItemsByShopSlugEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CartItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ViewerCartItemsByShopSlugEdge = {
  __typename?: 'ViewerCartItemsByShopSlugEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CartItem;
};

/** A connection to a list of items. */
export type ViewerCartItemsConnection = {
  __typename?: 'ViewerCartItemsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ViewerCartItemsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CartItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ViewerCartItemsEdge = {
  __typename?: 'ViewerCartItemsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CartItem;
};

/** A connection to a list of items. */
export type ViewerCheckoutsByShopConnection = {
  __typename?: 'ViewerCheckoutsByShopConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ViewerCheckoutsByShopEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Checkout>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ViewerCheckoutsByShopEdge = {
  __typename?: 'ViewerCheckoutsByShopEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Checkout;
};

/** A connection to a list of items. */
export type ViewerCheckoutsByShopSlugConnection = {
  __typename?: 'ViewerCheckoutsByShopSlugConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ViewerCheckoutsByShopSlugEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Checkout>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ViewerCheckoutsByShopSlugEdge = {
  __typename?: 'ViewerCheckoutsByShopSlugEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Checkout;
};

/** A connection to a list of items. */
export type ViewerConnectedShopsConnection = {
  __typename?: 'ViewerConnectedShopsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ViewerConnectedShopsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Shop>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ViewerConnectedShopsEdge = {
  __typename?: 'ViewerConnectedShopsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Shop>;
};

/** A connection to a list of items. */
export type ViewerShopsUsersConnection = {
  __typename?: 'ViewerShopsUsersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ViewerShopsUsersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Shop>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ViewerShopsUsersEdge = {
  __typename?: 'ViewerShopsUsersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Shop>;
};

export type Weight = {
  __typename?: 'Weight';
  unit: WeightUnit;
  value: Scalars['Float'];
};

export type WeightFilterInput = {
  and?: InputMaybe<Array<WeightFilterInput>>;
  or?: InputMaybe<Array<WeightFilterInput>>;
  unit?: InputMaybe<WeightUnitOperationFilterInput>;
  value?: InputMaybe<ComparableDoubleOperationFilterInput>;
  valueKg?: InputMaybe<ComparableDoubleOperationFilterInput>;
};

export type WeightInput = {
  unit: WeightUnit;
  value: Scalars['Float'];
};

export type WeightSortInput = {
  unit?: InputMaybe<OrderDirection>;
  value?: InputMaybe<OrderDirection>;
  valueKg?: InputMaybe<OrderDirection>;
};

export enum WeightUnit {
  GRAMS = 'GRAMS',
  KILOGRAMS = 'KILOGRAMS',
  OUNCES = 'OUNCES',
  POUNDS = 'POUNDS'
}

export type WeightUnitOperationFilterInput = {
  eq?: InputMaybe<WeightUnit>;
  in?: InputMaybe<Array<WeightUnit>>;
  neq?: InputMaybe<WeightUnit>;
  nin?: InputMaybe<Array<WeightUnit>>;
};
