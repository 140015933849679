import React, { FC, useEffect, useMemo, useState } from 'react';
import { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { DesignProvider } from '@jatango/design';
import Head from 'next/head';
import Script from 'next/script';
import { css, Global } from '@emotion/react';
import { Page, useApollo } from '@/lib';
import { ToastContainer } from 'react-toastify';
import { appWithTranslation } from 'next-i18next';
import { UserProvider } from '@auth0/nextjs-auth0';
import nextI18NextConfig from '../../next-i18next.config.js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { datadogRum } from '@datadog/browser-rum';
import packageJson from '../../package.json';
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter';
import { Insights } from '../components/insights';
import '@/global.css';

const MONITORING_ENABLED = Boolean(
  typeof window !== 'undefined' &&
  process.env.NEXT_PUBLIC_DATADOG_APP_ID &&
  process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN &&
  process.env.NEXT_PUBLIC_DATADOG_SERVICE
);

if (MONITORING_ENABLED) {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID!,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN!,
    site: 'datadoghq.com',
    service: process.env.NEXT_PUBLIC_DATADOG_SERVICE!,
    env: process.env.NEXT_PUBLIC_VERCEL_ENV ?? process.env.NODE_ENV ?? 'development',
    version: packageJson.version,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'allow'
  });

  datadogRum.startSessionReplayRecording();
}

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_API_KEY!);

import 'react-toastify/dist/ReactToastify.css';
import '@stripe/react-stripe-js/';
import { ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { useRouter } from 'next/router.js';
import theme from '../ui/theme/theme';

type InfinityAppProps = Omit<AppProps, 'Component'> & {
  Component: Page;
};

const InfinityApp: FC<InfinityAppProps> = ({ Component, pageProps }) => {
  const router = useRouter();
  const [replayRecording, setReplayRecording] = useState<boolean>(false);
  const apolloClient = useApollo(pageProps);

  const Layout: FC = Component.layout || (({ children }) => <>{children}</>);

  useEffect(() => {
    const originalConsoleError = console.error;
    console.error = (...args) => {
      if (args[0]?.message?.includes?.('Abort fetching component')) return;
      originalConsoleError(...args);
    };

    return () => {
      // Cleanup: Restore original push & console.error when component unmounts
      console.error = originalConsoleError;
    };
  }, [router]);

  useEffect(() => {
    if (pageProps.user && MONITORING_ENABLED) {
      datadogRum.setUser({
        id: pageProps.user['https://jatango.com/id'],
        email: pageProps.user.email
      });

      if (!replayRecording) {
        setReplayRecording(true);
        console.info('Session replay enabled.');
        datadogRum.startSessionReplayRecording();
      }
    } else {
      datadogRum.setUser({});
      if (replayRecording) {
        console.info('Session replay disabled.');
        setReplayRecording(false);
        datadogRum.stopSessionReplayRecording();
      }
    }
  }, [pageProps]);


  // noinspection CssInvalidPropertyValue
  return (
    <React.StrictMode>
      <AppCacheProvider>
        <ThemeProvider theme={theme}>

          <DesignProvider>
            <Head>
              <title>Jatango</title>
              <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
              />
              <link rel="preconnect" href="https://fonts.gstatic.com" />
              <link
                href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@500;600;700&display=swap"
                rel="stylesheet"
              />
              <meta name="application-name" content="Jatango" />
              <meta name="theme-color" content="#FFFFFF" />
              <meta name="description" content="Jatango Live Shopping" />
              <meta name="format-detection" content="telephone=no" />
              <meta name="mobile-web-app-capable" content="yes" />

              <meta name="apple-mobile-web-app-capable" content="yes" />
              <meta name="apple-mobile-web-app-status-bar-style" content="default" />
              <meta name="apple-mobile-web-app-title" content="Jatango" />

              <meta name="msapplication-square70x70logo" content="/icons/microsoft/tile70x70.png" />
              <meta name="msapplication-square150x150logo" content="/icons/microsoft/tile150x150.png" />
              <meta name="msapplication-square310x310logo" content="/icons/microsoft/tile310x310.png" />
              <meta name="msapplication-wide310x150logo" content="/icons/microsoft/tile310x150.png" />
              <meta name="msapplication-config" content="/browserconfig.xml" />
              <meta name="msapplication-TileColor" content="#5A37BC" />
              <meta name="msapplication-tap-highlight" content="no" />

              <link rel="apple-touch-icon" href="/icons/apple/apple-touch-icon.png" />
              <link rel="apple-touch-icon" sizes="152x152" href="/icons/apple/apple-touch-icon-152x152.png" />
              <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple/apple-touch-icon-180x180.png" />

              <link rel="icon" type="image/png" href="/icons/favicon-16x16.png" sizes="16x16" />
              <link rel="icon" type="image/png" href="/icons/favicon-32x32.png" sizes="32x32" />
              <link rel="icon" type="image/png" href="/icons/android/android-chrome-192x192.png" sizes="192x192" />
              <link rel="manifest" href="/manifest.json" />
              <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500" />

              <meta name="twitter:card" content="summary" />
              <meta name="twitter:url" content="https://jatango.com" />
              <meta name="twitter:title" content="Jatango" />
              <meta name="twitter:description" content="Jatango Live Commerce" />
              <meta name="twitter:image" content="https://jatango.com/icons/android/android-192x192.png" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content="Jatango" />
              <meta property="og:description" content="Jatango Live Commerce" />
              <meta property="og:site_name" content="Jatango" />
              <meta property="og:url" content="https://jatango.com" />
              <meta property="og:image" content="https://jatango.com/icons/apple/apple-touch-icon-180x180.png" />

              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-2048-2732.png"
                media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-2732-2048.png"
                media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-1668-2388.png"
                media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-2388-1668.png"
                media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-1536-2048.png"
                media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-2048-1536.png"
                media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-1668-2224.png"
                media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-2224-1668.png"
                media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-1620-2160.png"
                media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-2160-1620.png"
                media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-1284-2778.png"
                media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-2778-1284.png"
                media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-1170-2532.png"
                media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-2532-1170.png"
                media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-1125-2436.png"
                media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-2436-1125.png"
                media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-1242-2688.png"
                media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-2688-1242.png"
                media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-828-1792.png"
                media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-1792-828.png"
                media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-1242-2208.png"
                media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-2208-1242.png"
                media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-750-1334.png"
                media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-1334-750.png"
                media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-640-1136.png"
                media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
              />
              <link
                rel="apple-touch-startup-image"
                href="icons/apple-splash-1136-640.png"
                media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
              />
            </Head>
            <Global
              styles={css`
                html {
                  margin: 0;
                  height: -webkit-fill-available;
                }
                body {
                  margin: 0;
                  min-height: 100vh;
                  min-height: -webkit-fill-available;
                }
              `}
            />
            <Script
              strategy="lazyOnload"
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
            />
            <Script strategy="lazyOnload">
              {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
        `}
            </Script>
            <Elements stripe={stripePromise} options={{ appearance: { theme: 'stripe' } }}>
              <UserProvider loginUrl="/api/auth/login" user={pageProps.user}>
                <ApolloProvider client={apolloClient}>
                  <Provider store={store}>
                    <Layout>
                      <ToastContainer style={{ zIndex: 5000 }} />
                      <Component {...(pageProps as any)} />
                      <Insights />
                    </Layout>
                  </Provider>
                </ApolloProvider>
              </UserProvider>
            </Elements>

          </DesignProvider>

        </ThemeProvider>
      </AppCacheProvider>
    </React.StrictMode>
  );
};

// noinspection JSUnusedGlobalSymbols
export default appWithTranslation(InfinityApp, nextI18NextConfig);
