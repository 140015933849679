import { Palette } from "@mui/material/styles/createPalette";
import { TypographyOptions } from "@mui/material/styles/createTypography";


// Extend the TypographyVariants interface
declare module '@mui/material/styles' {
    interface TypographyVariants {
        heading2XL: React.CSSProperties;
        headingXL: React.CSSProperties;
        headingLG: React.CSSProperties;
        headingMD: React.CSSProperties;
        headingSM: React.CSSProperties;
        headingXS: React.CSSProperties;
        heading2XS: React.CSSProperties;

        text2XL: React.CSSProperties;
        textXL: React.CSSProperties;
        textLG: React.CSSProperties;
        textMD: React.CSSProperties;
        textSM: React.CSSProperties;
        textXS: React.CSSProperties;
        textXXS: React.CSSProperties;

        overlineMD: React.CSSProperties;
        overlineSM: React.CSSProperties;
        overlineXS: React.CSSProperties;
        overlineXXS: React.CSSProperties;
    }


    // Allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        heading2XL?: React.CSSProperties;
        headingXL?: React.CSSProperties;
        headingLG?: React.CSSProperties;
        headingMD?: React.CSSProperties;
        headingSM?: React.CSSProperties;
        headingXS?: React.CSSProperties;
        heading2XS?: React.CSSProperties;

        text2XL?: React.CSSProperties;
        textXL?: React.CSSProperties;
        textLG?: React.CSSProperties;
        textMD?: React.CSSProperties;
        textSM?: React.CSSProperties;
        textXS?: React.CSSProperties;
        textXXS?: React.CSSProperties;

        overlineMD?: React.CSSProperties;
        overlineSM?: React.CSSProperties;
        overlineXS?: React.CSSProperties;
        overlineXXS?: React.CSSProperties;
    }

}

// Extend the Typography component's variant prop
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        heading2XL: true;
        headingXL: true;
        headingLG: true;
        headingMD: true;
        headingSM: true;
        headingXS: true;
        heading2XS: true;

        text2XL: true;
        textXL: true;
        textLG: true;
        textMD: true;
        textSM: true;
        textXS: true;
        textXXS: true;

        overlineMD: true;
        overlineSM: true;
        overlineXS: true;
        overlineXXS: true;
    }

}

// Define typography variants
export const typographyVariants: TypographyOptions | ((palette: Palette) => TypographyOptions) | undefined = {
  allVariants: {
    fontFamily: 'Inter',
  },
  heading2XL: {
    fontFamily: 'Poppins',
    fontSize: '72px',
    fontWeight: 400,
    lineHeight: '90px',
  },
  headingXL: {
    fontFamily: 'Poppins',
    fontSize: '60px',
    fontWeight: 400,
    lineHeight: '75px',
  },
  headingLG: {
    fontFamily: 'Poppins',
    fontSize: '48px',
    fontWeight: 500,
    lineHeight: '60px',
  },
  headingMD: {
    fontFamily: 'Poppins',
    fontSize: '36px',
    fontWeight: 500,
    lineHeight: '45px',
  },
  headingSM: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '30px',
  },
  headingXS: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '25px',
  },
  heading2XS: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  text2XL: {
    fontFamily: 'Inter',
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: '48px',
  },
  textXL: {
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '36px',
  },
  textLG: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '30px',
  },
  textMD: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  textSM: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
  },
  textXS: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  textXXS: {
    fontFamily: 'Inter',
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '15px',
  },
  overlineMD: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.8px',
    textTransform: 'uppercase',
  },
  overlineSM: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: '0.7px',
    textTransform: 'uppercase',
  },
  overlineXS: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '18px',
    letterSpacing: '0.6px',
    textTransform: 'uppercase',
  },
  overlineXXS: {
    fontFamily: 'Inter',
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '15px',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
  },
};

// Define variant mappings for MuiTypography
export const typographyVariantMappings = {
  heading2XL: 'h1',
  headingXL: 'h2',
  headingLG: 'h3',
  headingSM: 'h4',
  headingXS: 'h5',
  heading2XS: 'h6',
  text2XL: 'p',
  textXL: 'p',
  textLG: 'p',
  textMD: 'p',
  textSM: 'p',
  textXS: 'p',
  textXXS: 'p',
  overlineMD: 'span',
  overlineSM: 'span',
  overlineXS: 'span',
  overlineXXS: 'span',
};