import { useRelayMutation } from '@/lib';
import {
  CreateShopDocument,
  CreateShopMutation,
  CreateShopMutationVariables,
  useViewerShopsQuery,
  useCheckShopSlugQuery,
  useShopBySlugQuery,
  useShopsQuery,
  useSearchShopsQuery,
  ShopsQuery,
  ShopsQueryVariables,
  ShopFragment,
  SearchShopsQuery,
  SearchShopsQueryVariables,
  SetupShopMutation,
  SetupShopMutationVariables,
  SetupShopDocument,
  useAnyShopBySlugQuery,
  CreateLinkTokenMutation,
  CreateLinkTokenMutationVariables,
  CreateLinkTokenDocument,
  ExchangePublicTokenMutation,
  ExchangePublicTokenMutationVariables,
  ExchangePublicTokenDocument,
  useSellerCategoriesQuery,
  useSellerSubCategoriesQuery,
  UpdateShopCategoriesMutation,
  UpdateShopCategoriesMutationVariables,
  UpdateShopCategoriesDocument,
  UpdateShopMutation,
  UpdateShopMutationVariables,
  UpdateShopDocument,
  CreateShopCustomizationMutation,
  CreateShopCustomizationMutationVariables,
  CreateShopCustomizationDocument,
  AddShopImagesMutation,
  AddShopImagesDocument,
  AddShopImagesMutationVariables,
  UpdateShopSubcategoriesMutation,
  UpdateShopSubcategoriesMutationVariables,
  UpdateShopSubcategoriesDocument,
  useTotalShopSalesQuery,
  ShopFilterInput,
  ShopByIdQuery,
  useShopByIdQuery,
  Exact,
  Scalars,
  ShopBySlugQuery,
  useShopsWithFeaturedProductsQuery,
  ShopsWithFeaturedProductsQuery,
  ShopsWithFeaturedProductsQueryVariables,
  useViewerConnectedShopsQuery,
  ViewerConnectedShopsQuery,
  ViewerConnectedShopsQueryVariables,
  InputMaybe,
  ViewerShopsQuery,
  ViewerShopsQueryVariables
} from '@/api';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { useDebounce } from 'react-use';
import { QueryHookOptions, QueryResult } from '@apollo/client';

export function useCreateShop() {
  return useRelayMutation<CreateShopMutation, CreateShopMutationVariables, 'createShop'>(
    CreateShopDocument,
    'createShop'
  );
}

export function useShopById(shopId: string) {
  const { data, ...rest } = useShopByIdQuery({
    variables: {
      shopId
    }
  });
  return { shop: data?.shop, ...rest };
};

export function useShopBySlug(slug: string) {
  const { data, ...rest } = useShopBySlugQuery({
    variables: {
      slug
    }, skip: !slug
  });
  return { shop: data?.shopBySlug, ...rest };
}


type FbPageData = {
  id: string;
  name: string;
  access_token: string;
  profile_picture: string;
};

export function useShopFbPagesBySlug(slug: string) {
  const { data, loading } = useShopBySlugQuery({
    variables: {
      slug
    }
  });

  return { shopFbPages: data?.shopBySlug?.shopFbPages ? JSON.parse(data.shopBySlug.shopFbPages) as FbPageData[] : [], loading };
}

export function useSellerCategories() {
  const { data, ...rest } = useSellerCategoriesQuery();
  return { sellercategories: data?.sellerCategories?.nodes || [], ...rest };
}

export function useSellerSubCategories(categoryIds: string[]) {
  const { data, ...rest } = useSellerSubCategoriesQuery({
    variables: {
      input: categoryIds
    }
  });
  return { sellersubcategories: data?.sellerSubCategories, ...rest };
}

export function useAnyShopBySlug(slug: string) {
  const { data, ...rest } = useAnyShopBySlugQuery({
    variables: {
      slug
    }
  });
  return { shop: data?.anyShopBySlug, ...rest };
}

export function useGetTotalShopRevenue(shopId: string) {
  const { data, ...rest } = useTotalShopSalesQuery({
    variables: {
      shopId: shopId
    }
  });
  return { revenue: data?.shop?.totalShopSales, ...rest };
}

export function useShopSlugExists(slug: string) {
  const { data, refetch, ...rest } = useCheckShopSlugQuery({
    variables: {
      slug
    },
    fetchPolicy: 'no-cache',
    skip: !Boolean(slug)
  });

  const checkExists = async (slug: string) => {
    const result = await refetch({
      slug
    });
    return Boolean(result?.data?.shopBySlug?.id);
  };

  return { slugExists: Boolean(data?.shopBySlug?.id), checkExists, ...rest };
}

export function useViewerShops(options?: QueryHookOptions<ViewerShopsQuery, ViewerShopsQueryVariables>) {
  const { data, ...rest } = useViewerShopsQuery(options);

  const isAffiliate = data?.viewer?.shops?.nodes?.some(shop => shop.shopType === "AFFILIATE");

  const shops = data?.viewer?.shops?.nodes?.filter(shop => {
    return shop.shopUsers?.nodes?.every(user => user.role !== "AFFILIATE")
  });

  const connectedShops = data?.viewer?.shops?.nodes?.filter(shop => {
    return shop.shopUsers?.nodes?.some(user => user.role === "AFFILIATE")
  });

  return { shops: shops ?? [], connectedShops: connectedShops ?? null, isAffiliate, viewer: data?.viewer ?? null, ...rest };
}

export function useViewerConnectedShops(options?: QueryHookOptions<ViewerConnectedShopsQuery, Exact<{ filter?: InputMaybe<ShopFilterInput> | undefined; }>>) {
  const { data, ...rest } = useViewerConnectedShopsQuery(options);


  return { shops: data?.viewerConnectedShops, ...rest };
}

export function useShops(options?: QueryHookOptions<ShopsQuery, ShopsQueryVariables>) {
  const { data, fetchMore: fetchMoreFn, ...rest } = useShopsQuery(options);
  const [fetchingMoreShops, setFetchingMoreShops] = useState<boolean>(false);

  const fetchMoreShops = useCallback(async () => {
    setFetchingMoreShops(true);
    try {
      await fetchMoreFn({
        variables: {
          cursor: data?.shops?.pageInfo.endCursor
        }
      });
    } finally {
      setFetchingMoreShops(false);
    }
  }, [data, fetchMoreFn, setFetchingMoreShops]);

  return {
    shops: (data?.shops?.edges?.map((edge) => edge.node) ?? []) as ShopFragment[],
    hasMoreShops: data?.shops?.pageInfo.hasNextPage ?? false,
    fetchingMoreShops,
    fetchMoreShops,
    ...rest
  };
}

export function useShopsWithFeaturedProducts(options?: QueryHookOptions<ShopsWithFeaturedProductsQuery, ShopsWithFeaturedProductsQueryVariables>) {
  const { data, fetchMore: fetchMoreFn, ...rest } = useShopsWithFeaturedProductsQuery(options);
  const [fetchingMoreShops, setFetchingMoreShops] = useState<boolean>(false);

  const fetchMoreShops = useCallback(async () => {
    setFetchingMoreShops(true);
    try {
      await fetchMoreFn({
        variables: {
          cursor: data?.shops?.pageInfo.endCursor
        }
      });
    } finally {
      setFetchingMoreShops(false);
    }
  }, [data, fetchMoreFn, setFetchingMoreShops]);

  return {
    shops: (data?.shops?.edges?.map((edge) => edge.node) ?? []),
    hasMoreShops: data?.shops?.pageInfo.hasNextPage ?? false,
    fetchingMoreShops,
    fetchMoreShops,
    ...rest
  };
}

export function useSearchShops(query: string, options?: QueryHookOptions<SearchShopsQuery, SearchShopsQueryVariables>) {
  const {
    data,
    fetchMore: fetchMoreFn,
    ...rest
  } = useSearchShopsQuery({
    fetchPolicy: 'network-only',
    variables: {
      query
    },
    ...options
  });
  const [fetchingMoreShopResults, setFetchingMoreShopResults] = useState<boolean>(false);

  const fetchMoreShopResults = useCallback(async () => {
    setFetchingMoreShopResults(true);
    try {
      await fetchMoreFn({
        variables: {
          cursor: data?.searchShops?.pageInfo.endCursor
        }
      });
    } finally {
      setFetchingMoreShopResults(false);
    }
  }, [data, fetchMoreFn, setFetchingMoreShopResults]);

  return {
    shopResults: (data?.searchShops?.edges?.map((edge) => edge.node) ?? []) as ShopFragment[],
    hasMoreShopResults: data?.searchShops?.pageInfo.hasNextPage ?? false,
    fetchingMoreShopResults,
    fetchMoreShopResults,
    ...rest
  };
}

type UseFilterShopOptions = {
  skip?: boolean;
  queryParam?: string;
  debounceDuration?: number;
  disableQueryParam?: boolean;
  first?: number;
  filter?: ShopFilterInput;

};

export function useSetUpShop() {
  return useRelayMutation<SetupShopMutation, SetupShopMutationVariables, 'setupShop'>(SetupShopDocument, 'setupShop');
}

export function useUpdateShopCategories() {
  return useRelayMutation<UpdateShopCategoriesMutation, UpdateShopCategoriesMutationVariables, 'updateShopCategories'>(UpdateShopCategoriesDocument, 'updateShopCategories');
}
export function useUpdateShopSubCategories() {
  return useRelayMutation<UpdateShopSubcategoriesMutation, UpdateShopSubcategoriesMutationVariables, 'updateShopSubcategories'>(UpdateShopSubcategoriesDocument, 'updateShopSubcategories');
}
export function useUpdateShop() {
  return useRelayMutation<UpdateShopMutation, UpdateShopMutationVariables, 'updateShop'>(UpdateShopDocument, 'updateShop');
}

export function useCreateLinkToken() {
  return useRelayMutation<CreateLinkTokenMutation, CreateLinkTokenMutationVariables, 'createLinkToken'>(CreateLinkTokenDocument, 'createLinkToken');
}

export function useExchangePublicToken() {
  return useRelayMutation<ExchangePublicTokenMutation, ExchangePublicTokenMutationVariables, 'exchangePublicToken'>(ExchangePublicTokenDocument, 'exchangePublicToken');
}

export function useFilterShops(options?: UseFilterShopOptions) {
  const { query, replace, pathname } = useRouter();

  const queryParam = options?.queryParam ?? 'q';
  const disableQueryParam = options?.disableQueryParam ?? false;
  const debounceDuration = options?.debounceDuration ?? 300;
  const filter = options?.filter;

  const [localQuery, setLocalQuery] = useState<string>('');
  const currentQuery = disableQueryParam ? localQuery : (query[queryParam] as string) ?? '';
  const [searchQuery, setSearchQuery] = useState<string>(currentQuery);


  const {
    loading: loadingShops,
    shops: baseShops,
    hasMoreShops: haseMoreBaseShops,
    fetchingMoreShops: fetchingMoreBaseShops,
    fetchMoreShops: fetchMoreBaseShops
  } = useShops({
    skip: options?.skip || Boolean(currentQuery),
    "variables": {
      "first": options?.first || 25,
      filter: filter
    },
  });

  const {
    loading: loadingShopResults,
    shopResults,
    hasMoreShopResults,
    fetchingMoreShopResults,
    fetchMoreShopResults
  } = useSearchShops(currentQuery, {
    skip: options?.skip || !Boolean(currentQuery),
  });

  const updateQueryParam = useCallback(
    async (searchQuery: string) => {
      if (searchQuery) {
        await replace({
          pathname,
          query: { shopSlug: query.shopSlug, [queryParam]: searchQuery }
        });
      } else {
        await replace({
          pathname,
          query: { shopSlug: query.shopSlug }
        });
      }
    },
    [pathname, query, replace]
  );

  const search = useCallback(
    async (searchQuery: string) => {
      if (disableQueryParam) {
        setLocalQuery(searchQuery);
      } else {
        await updateQueryParam(searchQuery);
      }
    },
    [disableQueryParam, updateQueryParam]
  );

  useDebounce(
    () => {
      if (searchQuery !== currentQuery) search(searchQuery);
    },
    debounceDuration,
    [searchQuery]
  );

  let shops = baseShops;
  let hasMoreShops = haseMoreBaseShops;
  let fetchingMoreShops = fetchingMoreBaseShops;
  let fetchMoreShops = fetchMoreBaseShops;

  if (Boolean(currentQuery)) {
    shops = shopResults;
    hasMoreShops = hasMoreShopResults;
    fetchingMoreShops = fetchingMoreShopResults;
    fetchMoreShops = fetchMoreShopResults;
  }

  return {
    loading: loadingShops,
    searching: loadingShopResults,
    shops,
    hasMoreShops,
    fetchingMoreShops,
    fetchMoreShops,
    searchQuery,
    setSearchQuery,
    search
  };
}
export function useCreateShopCustomization() {
  return useRelayMutation<
    CreateShopCustomizationMutation,
    CreateShopCustomizationMutationVariables,
    'createShopCustomization'
  >(CreateShopCustomizationDocument, 'createShopCustomization');
}

export function useAddShopImages() {
  return useRelayMutation<AddShopImagesMutation, AddShopImagesMutationVariables, 'addShopImages'>(
    AddShopImagesDocument,
    'addShopImages'
  );
}


export function getStoreDetails(slug: string) {
  const data = useShopBySlugQuery({
    variables: {
      slug
    }
  });

  const getShopDetails = (shopData: QueryResult<ShopBySlugQuery, Exact<{ slug: Scalars["String"]; }>>) => {
    if (!shopData) return { loading: true, shopName: '', coverPhoto: '', profileImage: '', shopDescription: '', shoppingCategories: [] };
    const shopInfoLoading = shopData?.loading ?? true;
    const shop = shopData?.data?.shopBySlug;
    const shopCustomizations = shop?.shopCustomizations || [];
    const firstCustomization = shopCustomizations[0] || {};
    const shopImages = shop?.shopImages?.nodes || [];
    return {
      shopInfoLoading: shopInfoLoading,
      shopId: shop?.id || '',
      shopName: shop?.name || '',
      shopDescription: shop?.description || '',
      coverPhoto: shopImages?.find(image => image.isCoverPhoto)?.image?.originalSrc || '',
      profileImage: shopImages?.find(image => image.isProfileImage)?.image?.originalSrc || '',
      shoppingCategories: shop?.shopShoppingCategories?.nodes?.map((category) => ({
        id: category?.shoppingCategory?.id || '',
        name: category?.shoppingCategory?.name || ''
      })) || [],
      shopUsers: shop?.shopUsers?.nodes?.map((user) => ({
        id: user?.user?.id || '',
        name: user?.user?.name || '',
        role: user?.role || ''
      })) || []
    };
  };

  return getShopDetails(data);
}